<template>
    <div class="fixed-content-width"
         v-if="submitting">
        <loading-container />
    </div>
    <div class="announcement-list"
         v-else>
        <vue-title :title="'Announcements'" :brand="true"></vue-title>
        <div class='activity-container'>

            <div class="header-actions">
                <div class="title">
                    Announcements
                </div>
                <div class="header-buttons">
                </div>
            </div>


            <activity-list :activities="notifications"
                           :attendee="attendee"
                           class="activities">
            </activity-list>

        </div>
    </div>
</template>
<script>

import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'

export default {
    props: ['attendee', 'code'],
    mixins: [
        Token,
        Common
    ],   

    methods: {
        onAnnouncementDeleted(announcementId){
            var index = this.notifications.findIndex(x => x.Code == announcementId);
            this.notifications.splice(index,1);
        },
        async reloadNotifications(){
            this.submitting = true;

            try {

                let r = await this.tryGet(`/api/announcements`)

                if(r.Errors.length > 0) {
                    this.submitting = false;
                    return;
                } 
                
                this.notifications = r.Result;
                this.submitting = false;

                window.$bus.$emit('notifymessageread', {
                    TypeCode: 'Announcement'
                });

            } catch {
                this.errorMessage = "We could not find your network info. Please try again later"; 
                this.submitting = false;
            }                    
        },
    },

    data() {
        return {
            notifications: [],
        }
    },

    beforeUnmount() {
        window.$bus.$off('On-New-Announcements', this.reloadNotifications);
        window.$bus.$off('deleted-announcement', this.onAnnouncementDeleted);
    },

    created: function() {
        window.$bus.$on('On-New-Announcements', this.reloadNotifications);
        window.$bus.$on('deleted-announcement', this.onAnnouncementDeleted);
        this.reloadNotifications();
        this.postAnalytics(AppState.attendeeCode,'Notifications',null,null,AppState.showCode);
    }
}
</script>