<template>
    <div v-show="isVisible"
         :class="[mainTemplateCssClass]">
        <h1 v-bind:class="textPromptClass" 
            class="m-0 p-0 pb-1"
            v-if="question.QuestionType == 'TextPrompt' && question.LabelType == 'Header1'">
            {{ question.Name }} 
        </h1>
        <h2 v-bind:class="textPromptClass" 
        class="m-0 p-0 pb-1"
            v-else-if="question.QuestionType == 'TextPrompt' && question.LabelType == 'Header2'">
            {{ question.Name }}
        </h2>
        <div v-else-if="question.QuestionType == 'GroupInsertButton' || question.QuestionType == 'GroupDeleteButton'">

        </div>
        <div v-bind:class="textPromptClass" 
             v-else 
             class="fs-5">

            <label>
                {{ question.Name }}
            </label>
            <span v-if="question.IsMandatory">*</span>
        </div>

        <div v-if="question.Notes" 
             class="fs-6">
            {{question.Notes}}
        </div>

        <div v-if="question.QuestionType == 'MultipleChoice'">
            <div class="form-check"
                v-for="option in question.Answers"
                :key="option.Response">
                <label class="form-check-label p-2">
                    <input type="radio"
                        :name="question.Code"
                        :value="option.Response"
                        v-model="question.Response"
                        class="form-check-input">
                    {{option.DisplayText}}
                </label>
            </div>
        </div>

        <div v-if="question.QuestionType == 'MultipleChoiceMultiSelect'">
            <div class="form-check"
                v-for="option in question.Answers"
                :key="option.Response">
                <label class="form-check-label p-2">
                    <input type="checkbox"
                        :name="question.Code"
                        :value="option.Response"
                        v-model="question._ResponseArray"
                        class="form-check-input">
                    {{option.DisplayText}}
                </label>
            </div>
        </div>

        <select :disabled="submitting || disabled" 
                class="form-select" 
                v-if="question.QuestionType == 'MultipleChoiceDropdown'" 
                v-model="question.Response">
            <option selected disabled hidden :value="null">(Please Select...)</option>
            <option v-for="answer in question.Answers" 
                    v-bind:key="answer.Code"
                    v-bind:value="answer.Response">{{ answer.DisplayText }}</option>
        </select>

        <div v-else-if="question.QuestionType == 'RankedChoice'"
             class="text-center">
            <div class="button-group">
                <button v-for="i in question.RankLimit"
                        type="button"
                        class="btn btn-lg"
                        @click="question.Response = i.toString()"
                        :class="[question.Response == i.toString() ? 'active' : '']"
                        :key="i">
                    {{i}}
                </button>
            </div>
        </div>

        <div v-if="question.QuestionType == 'Ranking'"
             class="star-ranking fs-2 text-center">

            <button v-for="i in question.RankLimit"
                    type="button"
                    @click="question.Response = i.toString()"
                    :class="{'checked': question.Response >= i.toString()}"
                    class="text-primary icomoon"
                    :key="i">
                <span>
                    
                </span>
                <span>
                    
                </span>
            </button>
        </div>

        <div v-if="question.MappedTo 
                && (question.MappedTo.includes('Website')
                || question.MappedTo.includes('Download'))">
            <input class="form-control form-control-lg"
                :disabled="submitting || disabled" 
                type="text"
                v-model="question.Response2"
                :placeholder="question.Response2Placeholder" /> 

            <input class="form-control mt-1 mb-1"
                :disabled="submitting || disabled" 
                type="text"
                v-model="question.Response3"
                :placeholder="question.Response3Placeholder" /> 
        </div>

        <input v-if="question.QuestionType == 'SingleLineText'" 
               :disabled="submitting || disabled" 
               :placeholder="question.Name"
               class="form-control"
               v-model="question.Response" />

        <textarea :disabled="submitting || disabled" 
                  :placeholder="question.Name" 
                  class="form-control" 
                  v-if="question.QuestionType == 'FreeformText'" 
                  rows="4"
                  v-model="question.Response">

        </textarea>


        <div v-if="question.QuestionType == 'ImageUpload'" class="form-group img-form-group">
            <img :src="question.Response" class="img-fluid" v-if="question.Response && question.Response.indexOf('http') != -1">
            <span v-if="question.Response && question.Response.indexOf('http') != -1">
                You have uploaded a file already. Click "Choose File" to replace your submitted file.
            </span>
            <span v-if="question.Response && question.Response.indexOf('http') == -1">
                Your file will be uploaded when you save your changes.
            </span>
            <input type="file" accept="image/*" @change="fileUploadChange(question, $event)" :disabled="disabled">
        </div>

        <div v-if="question.QuestionType == 'LargeUpload'" class="form-group large-form-group">
            <span v-if="question.Response && question.Response.indexOf('http') != -1">
                You have uploaded a file already. Click "Choose File" to replace your submitted file.
            </span>
            <span v-if="question.Response && question.Response.indexOf('http') == -1">
                Your file will be uploaded when you save your changes.
            </span>
            <input type="file" @change="fileUploadChange(question, $event)" :disabled="disabled">
        </div>

        <div v-if="fileError" class="alert alert-danger">
            {{fileError}}
        </div>

        <div v-if="question.QuestionType == 'GroupDeleteButton'">
            <button @click="groupDeleteClick" 
                    type="button" 
                    class="btn btn-danger">
                <span class="icomoon"></span>Remove
            </button>
        </div>

        <div v-if="question.QuestionType == 'GroupInsertButton'">
            <button @click="groupInsertClick" 
                    type="button" 
                    :style="(this.question.RankLimit != 0 && this.rankCount >= this.question.RankLimit) ? 'display:none;' : ''"
                    class="btn btn-primary">
                <span class="icomoon"></span>{{question.Name}}
            </button>
        </div>
    </div>
</template>

<script>
import { AppState } from './appstate';

export default {
    props: ['question', 'poll', 'submitting','disabled'],

    methods: {
        onDeleteGroup(groupTag){
            if(this.question.QuestionType != "GroupInsertButton"){
                return;
            }
            if(this.question.GroupTag != groupTag){
                return;
            }
            this.rankCount -= 1;
        },
        groupDeleteClick(){
            this.$emit('groupDelete', { SubGroup:this.question.SubGroup, GroupTag:this.question.GroupTag});
        },
        groupInsertClick(){
            if(this.question.RankLimit != 0){
                if(this.rankCount >= this.question.RankLimit){
                    return;
                }
            }
            this.rankCount = this.rankCount + 1;
            this.$emit('groupInsert', this.question.GroupTag);
        },
        fileUploadChange(question, e) {
            if(!e.target.files
                || e.target.files.length == 0) {
                return;
            }

            this.fileError = null;

            if(e.target.files[0].size > 1073741824) {
                this.fileError = "This file is too large. Please choose a file that is less than 1 GB.";
                return;                
            }

            question.FileInfo = e.target.files[0];
            question.Response = e.target.files[0].name;
        },

        onUpdateVisibility: function() {
            this.isVisible = this.question.Conditions.length == 0;

            if(!this.isVisible)
            {
                for(var i = 0; i < this.question.Conditions.length; i++)
                {
                    var condition = this.question.Conditions[i];
                    var questionToCheck = this.poll.Questions.find(x => x.Code == condition.ReliesOnQuestionCode);

                    if(!questionToCheck.Response) {
                        return;
                    }

                    if(questionToCheck && questionToCheck.Response.toLowerCase().indexOf(condition.RequiredAnswer.toLowerCase()) == -1) {
                        return;
                    }
                }
            }

            this.isVisible = true;
        }
    },

    watch: {
        'question.Response': function(newResponse, oldResponse) {
            if(oldResponse){
                this.oldResponse = oldResponse;
            }

            this.$emit('responseChanged', this.question, newResponse);
        },

        'question._ResponseArray': function(newVal) {
            this.question.Response = newVal.join('|');
        }
    },

    computed: {
        mainTemplateCssClass() {

            if(this.question.QuestionType == 'TextPrompt') {
                return 'text-prompt';
            } 
            
            if(this.question.QuestionType == 'GroupInsertButton' || this.question.QuestionType == 'GroupDeleteButton') {
                return 'group-insert-container';
            }

            return 'poll-question-div';
        },
        textPromptClass: function() {
            return {
                alert: this.question.QuestionType === 'TextPrompt' 
                    && this.question.LabelType != 'Normal'
                    && this.question.LabelType,
                'alert-primary': this.question.LabelType === 'Information',
                'alert-warning': this.question.LabelType === 'Warning',
                'alert-danger': this.question.LabelType === 'Alert'
            };
        }
    },

    data() {
        return {
            fileError: null,
            isVisible: false,
            rankCount: 0,
            oldResponse: null,
        }
    },

    created: function() {
        this.onUpdateVisibility();

        if(this.question.QuestionType == "MultipleChoiceMultiSelect") {
            let questionArray = [];
            let existingAnswers = this.question.Response;

            if(existingAnswers) {
                let responses = existingAnswers.split('|');
                responses.forEach(e => {
                    questionArray.push(e);
                });
            }

            this.question._ResponseArray = questionArray;
        }

        window.$bus.$on('updateVisibility', () => {
            this.onUpdateVisibility();
        });

        window.$bus.$on('deleteGroup',(groupTag) => { this.onDeleteGroup(groupTag)});
    },

    name: 'PollAnswer'
}
</script>
