<template>
    <div v-if="submitting"
         class="fixed-content-width">
        <loading-container  />
    </div>
    <div v-else-if="notAuthenticated"
         class="page-container">
        <vue-title title="Sign In Desk" :brand="true"></vue-title>
    </div>
    <div v-else
         class="page-container">
        <vue-title title="Sign In Desk" :brand="true"></vue-title>

        <div class="list-page-full">
            <div class="list-page-header">
                <div class="search-container">
                    <div class="search-box">
                        <span class="icomoon">
                            
                        </span>
                        <input placeholder="Search" 
                            v-debounce:150="updateSearchTerm"   
                            type="text"/>
                    </div>                
                </div>
            </div>

            <div class="list-page-contents">

                <table class="registration-table">
                    <thead>
                        <tr>
                            <th>
                                First Name
                            </th>
                            <th>
                                Last Name
                            </th>
                            <th>
                                Title
                            </th>
                            <th>
                                SubTitle
                            </th>
                            <th>
                                Code
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="attendee in filteredResults"
                            @click="selectedAttendee = attendee">
                            <td>
                                {{ attendee.FirstName }}
                            </td>
                            <td>
                                {{ attendee.LastName }}
                            </td>
                            <td>
                                {{ attendee.ContactInfo ? attendee.ContactInfo.Title : '' }}
                            </td>
                            <td>
                                {{ attendee.ContactInfo ? attendee.ContactInfo.SubTitle : '' }}
                            </td>
                            <td>
                                {{ attendee.Code }}
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
        <div v-if="selectedAttendee" class="right-pane attendee-sign-in-info">
            <div class="header-actions">
                <div class="title">
                    {{ selectedAttendee.Code }}
                </div>
                <div class="header-buttons">
                    <button @click="selectedAttendee = null"
                        class="icomoon">
                                                
                    </button>
                </div>
            </div>
            <img-g :src="selectedAttendee.ContactInfo.PhotoUrl"
                            fallbackSrc="/img/person.png" />
            <div class="detail-page-bio">
                <h1 class="page-name">
                    {{selectedAttendee.DisplayName}} 
                </h1>
                <h2 v-if="selectedAttendee.ContactInfo.Title">
                    {{selectedAttendee.ContactInfo.Title}}
                </h2>
                <h3 v-if="selectedAttendee.ContactInfo.SubTitle">
                    {{selectedAttendee.ContactInfo.SubTitle}}
                </h3>

                <h4>
                    Code: {{ selectedAttendee.Code }}
                </h4>

                <h4>
                    Sign in PIN: {{ selectedAttendee.SignInPin }}
                </h4>

                <canvas ref="qrcode">

                </canvas>
            </div>
        </div>
    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'
import QrCreator from 'qr-creator';
import { nextTick } from 'vue';
import { AppState } from './appstate';

export default {
    mixins: [
        Token, Common
    ],

    computed: {
        filteredResults() {
            let targetArray = this.attendees;

            if(this.searchTerm
                && this.searchTerm.length > 0) {

                let searchTest = new RegExp(this.searchTerm, 'i');
                targetArray = targetArray.filter(s => searchTest.test(s.DisplayName) 
                    || searchTest.test(s.ContactInfo.Title)
                    || searchTest.test(s.ContactInfo.SubTitle)
                    || searchTest.test(s.Code));

            }            

            return targetArray;
        },
    },

    watch: {
        selectedAttendee() {
            if(this.selectedAttendee) {
                nextTick(() => {
                    
                    this.renderQrCode();
                });
            }
        }
    },

    data() {
        return {
            notAuthenticated: false,
            attendees: [],
            searchTerm: '',
            selectedAttendee: null,
        }
    },

    methods: {
        async build() {
            this.submitting = true;

            try {
                let result = await this.tryGet('/api/attendees/all');

                this.attendees = result.Result;
            } catch {
                this.notAuthenticated = true;
            }

            this.submitting = false;
        },

        updateSearchTerm(inputVal, eventObject){
            this.searchTerm = inputVal;
        },

        renderQrCode() {
            const qrCode = this.$refs.qrcode;
            QrCreator.render({
                text: `${window.location.protocol}//${window.location.hostname}/login?l=${btoa(`${this.selectedAttendee.Code}:${this.selectedAttendee.SignInPin}:${AppState.show.Code}`)}`,
                radius: 0.25,
                ecLevel: 'M',
                fill: 'black',
                background: 'transparent',
                size: 256 // in pixels
            }, qrCode);
        },

    },

    mounted() {
        this.build();
    }
}
</script>