import { createRouter, createWebHistory } from 'vue-router'
import Login from './components/login.vue'
import LoginShow from './components/login.show.vue'
import Home from './components/home.vue'
import AttendeeIndex from './components/attendee.index.vue'
import Agenda from './components/agenda.vue'
import Vendors from './components/companies.and.sponsors.vue'
import SessionDetails from './components/session.details.vue'
import AttendeeDetails from './components/attendee.details.vue'
import VendorDetails from './components/vendor.details.vue'
import AttendeeBadge from './components/attendee.badge.vue'
import DiscussionArea from './components/discussion.area.vue'
import Notifications from './components/notifications.vue'
import Htmlcontent from './components/html.content.vue'
import EditProfile from './components/edit.profile.vue'
import Discussionlist from './components/discussion.list.vue'
import MyNetwork from './components/my.network.vue'
import PollList from './components/poll.list.vue'
import PollAnswer from './components/poll.answer.vue'
import NotFound from './components/404.vue'
import { requireAttendeeAuth } from './components/authentication/token.js'
import TestPage from './components/test.page.vue'
import ChatRoomList from './components/chat.room.list.vue'
import AttendeeSelfServiceEmail from './components/attendee.self.service.email.vue'

import ViewAppointmentRequests from './components/view.appointment.requests.vue'
import ProfileViews from './components/page.analytics.vue';
import AttendeeProfileViews from './components/profile.page.analytics.vue';
import AttendeeList from './components/attendee.list.vue'
import FavoritesList from './components/favorites.list.vue'

import SessionScanner from './components/session.scanner.vue'
import VendorScanner from './components/vendor.scanner.vue'
import CustomScanner from './components/custom.scanner.vue'
import SignInDesk from './components/sign.in.desk.vue'
import Map from './components/map.vue'
import MyBadge from './components/my.badge.vue'
import MyLoginBadge from './components/my.login.popup.vue'

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
          path: '/badge/:showCode/:attendeeCode',
          name: "AttendeeBadgePortal",
          component: AttendeeBadge,
          props: true
        },    
        {
          path: '/login',
          name: 'Login',
          component: Login
        },
        {
          path: '/login/:showCode',
          name: 'Login',
          component: LoginShow,
          props:true
        },
        {
          path:'/selfserve/:showCode',
          name: 'SelfServe',
          component: AttendeeSelfServiceEmail,
          props:true
        },
        {
          path: '/',
          component: AttendeeIndex,
          props: true,
          beforeEnter: requireAttendeeAuth,
          children: [
            {
                path: 'mybadge',
                component: MyBadge,
                props: true,
                beforeEnter: requireAttendeeAuth
            },
            {
                name: "MyLoginBadge",
                path: 'myloginbadge',
                component: MyLoginBadge,
                props: true,
                beforeEnter: requireAttendeeAuth
            },
            {
                path: 'signindesk/:sectionCode',
                component: SignInDesk,
                name: 'SignInDesk',
                props: true,
                beforeEnter: requireAttendeeAuth,
            },
            {
                path: 'sessionscanner/:sectionCode',
                component: SessionScanner,
                name: 'SessionScanner',
                props: true,
                beforeEnter: requireAttendeeAuth,
            },
            {
                path: 'vendorscanner/:sectionCode',
                component: VendorScanner,
                name: 'VendorScanner',
                props: true,
                beforeEnter: requireAttendeeAuth,
                children: [
                    {
                        path: 'chat/:discussionCode',
                        name: 'DiscussionForVendorScanner',
                        props: true,
                        component: DiscussionArea,
                        beforeEnter: requireAttendeeAuth
                    },
                ]
            },
            {
                path: 'scanner/:sectionCode',
                component: CustomScanner,
                name: 'CustomScanner',
                props: true,
                beforeEnter: requireAttendeeAuth,
                children: [
                    {
                        path: 'chat/:discussionCode',
                        name: 'DiscussionForCustomScanner',
                        props: true,
                        component: DiscussionArea,
                        beforeEnter: requireAttendeeAuth
                    },
                ]
            },
            {
                path: 'map/:sectionCode',
                component: Map,
                name: 'Map',
                props: true,
                children: [
                    {
                        path: 'details/attendee/:code',
                        name: 'attendeeDetailsMap',
                        props: true,
                        component: AttendeeDetails,
                        beforeEnter: requireAttendeeAuth,
                    },  
                ]
            },            
            {
              path: 'favorites',
              component: FavoritesList,
              props: true
            },
            {
              path: 'notfound',
              component: NotFound
            },
            {
              path: 'testroute',
              name: 'testRoute',
              component: TestPage,
              props: true
            },
            {
              path: 'poll/:code',
              name: 'PollAnswer',
              component: PollAnswer,
              props: true,
              beforeEnter: requireAttendeeAuth
            },
            {
              path: 'pages/:sectionCode',
              name: 'vendors',
              props: true,
              component: Vendors,
              beforeEnter: requireAttendeeAuth
            },        
            {
              path: 'details/page/:code',
              name: 'vendorDetails',
              props: true,
              component: VendorDetails,
              beforeEnter: requireAttendeeAuth,
              children: [
                {
                  path: 'chat/:discussionCode',
                  name: 'DiscussionForVendor',
                  props: true,
                  component: DiscussionArea,
                  beforeEnter: requireAttendeeAuth,
                },            
                {
                    path: 'appointments/:requestcode?',
                    name: 'ViewAppointmentRequestsVendor',
                    props: true,
                    component: ViewAppointmentRequests,
                    beforeEnter: requireAttendeeAuth
                },
                {
                    path: 'analytics',
                    name: 'ProfileViewsVendor',
                    props: true,
                    component: ProfileViews,
                    beforeEnter: requireAttendeeAuth
                }
              ]
            }, 
    
            {
              path: 'mynetwork',
              name: 'mynetwork',
              props: true,
              component: MyNetwork,
              beforeEnter: requireAttendeeAuth,
              children: [
                  {
                      path: 'chat/:discussionCode',
                      name: 'DiscussionForAttendeeNetworkList',
                      props: true,
                      component: DiscussionArea,
                      beforeEnter: requireAttendeeAuth
                  },
                     {
                        path: 'details/attendee/:code',
                        name: 'attendeeDetailsNetwork',
                        props: true,
                        component: AttendeeDetails,
                        beforeEnter: requireAttendeeAuth,
                    },          
                ]
    
            },
            {
              path: 'attendees/:sectionCode',
              name: 'AttendeeList',
              props: true,
              component: AttendeeList,
              beforeEnter: requireAttendeeAuth,
              children: [
                  {
                      path: 'chat/:discussionCode',
                      name: 'DiscussionForAttendeeAttendeeList',
                      props: true,
                      component: DiscussionArea,
                      beforeEnter: requireAttendeeAuth
                  },
                     {
                        path: 'details/attendee/:code',
                        name: 'attendeeDetailsAttendeeList',
                        props: true,
                        component: AttendeeDetails,
                        beforeEnter: requireAttendeeAuth,
                    },          
                ]
    
            },
            {
              path: 'details/attendee/:code',
              name: 'attendeeDetails',
              props: true,
              component: AttendeeDetails,
              beforeEnter: requireAttendeeAuth,
              children: [
                {
                  path: 'chat/:discussionCode',
                  name: 'DiscussionForAttendee',
                  props: true,
                  component: DiscussionArea,
                  beforeEnter: requireAttendeeAuth,
                },    
                {
                    path: 'analytics',
                    name: 'ProfileViewsAttendee',
                    props: true,
                    component: AttendeeProfileViews,
                    beforeEnter: requireAttendeeAuth,  
                }        
              ]
            },
            {
                path: 'analytics',
                name: 'ProfileViewsAttendeeStandalone',
                props: true,
                component: AttendeeProfileViews,
                beforeEnter: requireAttendeeAuth,  
                children: [
                    {
                        path: 'details/attendee/:code',
                        name: 'attendeeDetailsAnalytics',
                        props: true,
                        component: AttendeeDetails,
                        beforeEnter: requireAttendeeAuth,
                    },          
                ]
            },
            {
              path: 'agenda/:sectionCode',
              name: 'agenda',
              props: true,
              component: Agenda,
              beforeEnter: requireAttendeeAuth,
            },
            {
              path: 'details/session/:code',
              name: 'agendaDetails',
              props: true,
              component: SessionDetails,
              beforeEnter: requireAttendeeAuth,
              children: [
                {
                  path: 'chat/:discussionCode',
                  name: 'DiscussionForSession',
                  props: true,
                  component: DiscussionArea,
                  beforeEnter: requireAttendeeAuth,
                },            
              ]
            },
            {
              path: 'polls',
              name: 'pollList',
              props: true,
              component: PollList,
              beforeEnter: requireAttendeeAuth
            },
            {
              path: 'page/:sectionCode',
              name: 'HtmlPage',
              props: true,
              component: Htmlcontent,
              beforeEnter: requireAttendeeAuth
            },
            {
              path: 'discussionList',
              name: 'DiscussionList',
              props: true,
              component: Discussionlist,
              beforeEnter: requireAttendeeAuth,
              children: [
                {
                  path: 'chat/:discussionCode',
                  name: 'DiscussionInternal',
                  props: true,
                  component: DiscussionArea,
                  beforeEnter: requireAttendeeAuth,
                },                    
              ]
            },
    
            {
                path: 'chatrooms',
                name: 'ChatRoomList',
                props: true,
                component: ChatRoomList,
                beforeEnter: requireAttendeeAuth,
                children: [
                  {
                    path: 'chat/:discussionCode',
                    name: 'DiscussionInternalChat',
                    props: true,
                    component: DiscussionArea,
                    beforeEnter: requireAttendeeAuth,
                  },                    
                ]
              },
              
            {
                path: 'chatroom/:discussionCode',
                name: 'DiscussionInternalDirect',
                props: true,
                component: DiscussionArea,
                beforeEnter: requireAttendeeAuth,
            },                    
            {
              path: 'myactivity',
              name: 'Notifications',
              props: true,
              component: Notifications,
              beforeEnter: requireAttendeeAuth
            },
            {
              path: '',
              name: 'home',
              component: Home,
              beforeEnter: requireAttendeeAuth
            },
    
            {
              path: 'myprofile',
              name: 'EditMyProfile',
              props: true,
              component: EditProfile,
              beforeEnter: requireAttendeeAuth
            },        
          ]
        },
        {
          path: '/:pathMatch(.*)*',
          component: Login
        }
      ]
})

export default router
