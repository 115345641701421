<template>
    <div class="lds-dual-ring" :class="[size && size == 'small' ? 'lds-dual-ring-sm' : '']"></div>
</template>
<script>
    export default {
    props: ['size']
}
</script>
<style>
.lds-dual-ring {
  display: inline-block;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 32px;
  height: 32px;
  margin: 0px;
  border-radius: 50%;
  border: 6px solid var(--primary-color);
  border-color: var(--primary-color) transparent var(--primary-color) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.lds-dual-ring.lds-dual-ring-sm::after {
  width: 12px;
  height: 12px;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


</style>