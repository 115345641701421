<template>
    <div class="page-side-panel contact-info-editor-pane"
         v-if="pageCopy">
        <div class="header-actions">
            <span class="title">Page Details</span>
            <div class="actions">            
                <button class="icomoon" @click="closePage">
                    
                </button>
            </div>
        </div>
        
        <div class="page-side-panel-content"
             v-if="pageCopy">

            <div class="form-control">
                <label>
                    Page Title
                </label>
                <input v-model="pageCopy.Name"
                       required
                       aria-label="Page Title">

            </div>

            <div class="form-control">
                <label>
                    Headline
                </label>
                <input v-model="pageCopy.Headlines"
                       required
                       aria-label="Headline">

            </div>

            <div class="form-control">
                <label>
                    Cover YouTube/Vimeo/Embed URL
                </label>

                <input v-model="coverSourceUrl"
                       required
                       aria-label="YouTube/Vimeo/Embed URL">


            </div>


            <div class="form-control">
                <label>
                    Description/Bio
                </label>

                <textarea v-model="pageCopy.ContactInfo.Bio"
                          required
                          aria-label="Description/Bio"
                          rows="12">
                </textarea>

            </div>
        </div>
    </div>
</template>
<script>
import Common from './common'
import Token from './authentication/token'
import { AppState } from './appstate';;
import moment from 'moment'

export default {

    mixins: [
        Token,
        Common
    ],   

    props: ['attendee', 'sidebarParams'],

    computed: {
        coverSourceUrl: {
            get() {
                return this.pageCopy.PrimaryMedia.Uri;
            },
            set(value) {
                let finalUri = value;
                if(finalUri) {
                    let finalUriCheck = finalUri.toLowerCase();

                    if(finalUriCheck.endsWith(".m3u8")) {
                        this.pageCopy.PrimaryMedia.MediaType = 'HlsSource';
                    } else {

                        this.pageCopy.PrimaryMedia.MediaType = 'LiveWebSource';

                        if(finalUriCheck.indexOf("https://") == -1
                            && finalUriCheck.indexOf("http://") == -1) {
                            
                            finalUri = "https://" + finalUri;
                        }

                        finalUri = finalUri.replace('http://', 'https://');

                        if(finalUri.startsWith("https://www.youtube.com/watch?")
                            || finalUri.startsWith("https://youtu.be/")) {
                            
                            finalUri = finalUri.replace('https://youtu.be/', 'https://www.youtube.com/watch?v=');

                            finalUri = finalUri.replace('https://www.youtube.com/watch?v=', 'https://www.youtube.com/embed/');
                        } else if (finalUri.startsWith("https://vimeo.com")) {

                            finalUri = finalUri.replace("https://vimeo.com/", 'https://player.vimeo.com/video/');
                        }
                    }

                } else {
                    this.pageCopy.PrimaryMedia.MediaType = null;
                }

                this.pageCopy.PrimaryMedia.Uri = finalUri;
            },
        },
    },

    watch: {
        'sidebarParams.Page': {
            immediate: true, 
            handler() {
                this.pageCopy = JSON.parse(JSON.stringify(this.sidebarParams.Page));

                if(!this.pageCopy.Headlines) {
                    this.pageCopy.Headlines = '';
                }

                if(!this.pageCopy.ContactInfo.Bio) {
                    this.pageCopy.ContactInfo.Bio = '';
                }

                if(!this.pageCopy.PrimaryMedia.Uri) {
                    this.pageCopy.PrimaryMedia.Uri = '';
                }
            }
        },

        'pageCopy': {
            deep: true,
            handler() {
                window.$bus.$emit('PageDetailsEdited', 
                    this.sidebarParams.EntityTypeCode,
                    this.sidebarParams.EntityCode,
                    this.pageCopy);
            }
        },
    },

    beforeUnmount() {
    },

    mounted() {
    },

    methods: {
        closePage() {
            window.$bus.$emit('page-editor-closed');
            this.$emit('closerequest');
        },
    },

    data() {
        return {
            pageCopy: null,
        }
    },
}
</script>