<template>
  <RouterView />
</template>
<script setup>
import { RouterLink, RouterView } from 'vue-router'

async function RegisterServiceWorker() {
    return;
    console.log("New version of the RSW");
    if(process.env.NODE_ENV == 'production'
        && navigator
        && navigator.serviceWorker) {
        try {
            await navigator.serviceWorker.register('sw.js');
            let registration = await navigator.serviceWorker.getRegistration();

            if(registration) {
                registration.addEventListener('updatefound', () => {
                    console.warn("Update found. Refresh app.");
                });
            }
        } catch(ex) {
            console.error("Unable to register ServiceWorker.", ex);
        }

    }
}

RegisterServiceWorker();
</script>
