<template>
    <div>
        <vue-title :title="title" :brand="true"></vue-title>
        <div v-if="title"
            style="text-align: center;">
            <h1 style="font-size: 24px; text-align: center; margin-bottom: 0.5em;margin-top: 0.5em">
                {{ attendeeName || '(No name provided)' }}
            </h1>
            <h2 style="font-size: 18px; text-align: center; margin-bottom: 0.5em">
                {{ code }}
            </h2>
            <div class="qr-code-container">
                <canvas ref="qrcode">

                </canvas>
            </div>
            <div style="margin-top: 0.5em">
                {{ showName }}
            </div>

            <button class="btn" @click="goBackOneRoute" style="margin: 0 auto; margin-top: 0.5em">
                Close
            </button>

        </div>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'


export default {
    mixins: [
        Token,
        Common
    ],   

    data() {
        return {
        }
    },

    computed: {
        title() {
            return "My Badge"
        },
        
        attendeeName() {
            return AppState.attendee.DisplayName;
        },

        code() {
            return AppState.attendee.Code;
        },

        showName() {
            return AppState.show.Name;
        }
    },


    methods: {
        // tractus://attendee?s=showcode&c=code
        createQrCode() {
            const qrCode = this.$refs.qrcode;
            QrCreator.render({
                text: `tractus://attendee?s=${AppState.show.Code}&c=${AppState.attendee.Code}`,
                radius: 0.25,
                ecLevel: "L",
                fill: "#000",
                background: null,
                size: 320 // in pixels
            }, qrCode);
            console.log("Render called");
        },
    },

    beforeUnmount() {
    },

    async mounted() {
        this.createQrCode()
    }

}
</script>