<template>
    <div class="fixed-content-width" v-if="submitting">
        <loading-container />
    </div>
    <div class="page-container" v-else>
        <div class="activity-container list-page-full">
            <div class="header-actions">
                <div class="title">Schedule - {{ sidebarParams.Name }}</div>
                <div class="header-buttons">
                    <button
                        class="icomoon"
                        v-if="canEditSessions"
                        @click="beginCreateSession()"
                    >
                        
                    </button>
                    <button class="icomoon" @click="requestClose()"></button>
                </div>
            </div>

            <div class="list-page-contents" v-if="!selectedSession">
                <div class="category-item">
                    <div class="category-items attendee-list-items">
                        <template v-if="sessions.length > 0">
                            <div
                                v-for="session in sessions"
                                :key="session.Code"
                                @click="selectSession(session)"
                                class="list-item session-editor-item">
                                <span class="title">
                                    {{ session.Name }}
                                </span>
                                <span class="subtitle">
                                    <span class="icomoon"></span>
                                    {{ $filters.moment(session.SessionStartDateTime, "LL, LT")}} -
                                    {{ $filters.moment(session.SessionEndDateTime, "LT")}}
                                </span>
                                <span v-if="session.Location" class="location">
                                    <span class="icomoon"></span
                                    >{{ session.Location }}</span
                                >
                                <span
                                    v-if="session.ContactInfo.Bio"
                                    class="description"
                                    >{{ session.ContactInfo.Bio }}</span
                                >
                            </div>
                        </template>
                        <template v-else>
                            <div class="placeholder-item">
                                You have no items scheduled.
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            <div class="list-page-contents" v-else>
                <div class="category-item">
                    <div class="category-items attendee-list-items">
                        <div class="session-editor">
                            <h1 class="title">Edit Session</h1>

                            <div class="input-control">
                                <label>Title</label>

                                <input
                                    v-model="selectedSession.Name"
                                    placeholder="Title"
                                />
                            </div>

                            <div class="input-control">
                                <label>Date</label>

                                <datetime
                                    v-model="
                                        selectedSession.SessionStartDateTime
                                    "
                                    type="datetime"
                                    class="tractus-datepicker"
                                    :minute-step="5"
                                    :use12-hour="true"
                                >
                                </datetime>
                            </div>

                            <div class="input-control">
                                <label>Location (Optional)</label>

                                <input
                                    v-model="selectedSession.Location"
                                    placeholder="Location"
                                />
                            </div>

                            <div class="input-control">
                                <label>Length (minutes)</label>

                                <input
                                    type="number"
                                    min="5"
                                    max="300"
                                    step="5"
                                    placeholder="Length (minutes)"
                                    v-model="selectedSession.DurationMinutes"
                                />
                            </div>

                            <div class="input-control">
                                <label>Description</label>

                                <textarea
                                    v-model="selectedSession.ContactInfo.Bio"
                                    placeholder="Session Description"
                                >
                                </textarea>
                            </div>

                            <div class="button-group">
                                <button @click="saveSelectedSession">
                                    Save
                                </button>
                                <button @click="cancelEditSession">
                                    Cancel
                                </button>
                            </div>

                            <a class="action-link"
                               @click="askDeleteSession(selectedSession)">
                                Delete Session
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import Vue from "vue";
import Token from "./authentication/token";
import Common from "./common";
import moment from "moment";

export default {
    props: ["attendee", "sidebarParams"],

    mixins: [Token, Common],

    computed: {
        canEditSessions() {
            return this.sidebarParams.Mode == "ReadWrite";
        },
    },

    watch: {
        sidebarParams() {
            this.build();
        },

        "selectedSession.SessionStartDateTime"() {
            if (!this.selectedSession) {
                return;
            }

            if (!this.selectedSession.DurationMinutes) {
                return;
            }

            let newDate = moment(this.selectedSession.SessionStartDateTime)
                .add("minutes", this.selectedSession.DurationMinutes)
                .utc(false)
                .toISOString();

            this.selectedSession.SessionEndDateTime = newDate;
        },

        "selectedSession.DurationMinutes"() {
            if (!this.selectedSession) {
                return;
            }

            if (!this.selectedSession.DurationMinutes) {
                return;
            }

            let newDate = moment(this.selectedSession.SessionStartDateTime)
                .add("minutes", this.selectedSession.DurationMinutes)
                .utc(false)
                .toISOString();

            this.selectedSession.SessionEndDateTime = newDate;
        },
    },

    methods: {
        async askDeleteSession(session) {
            let promise = new Promise(r => {
                this.$awn.confirm(
                    "Are you sure you wish to delete this session? This cannot be undone.",
                    () => {
                        r('Yes');
                    },
                    () => {
                        r('No');
                    },
                    {
                        labels: {
                            confirm: "Yes",
                            cancel: 'No'
                        }
                    }
                )
            });

            let result = await promise;

            let shouldGoLive = result == 'Yes';

            if(!shouldGoLive) {
                return false;
            }
            
            this.submitting = true;

            try {
                await this.tryDelete(`/api/session/${AppState.showCode}/${session.Code}`);
                
                if(this.selectedSession == session) {
                    this.selectedSession = null;
                }

                this.build();
            } catch(ex) {
                console.error(ex);
                this.$awn.alert("Could not delete session. Please try again later.");
                this.submitting = false;
            }
            
        },

        cancelEditSession() {
            this.selectedSession = null;
        },

        selectSession(session) {
            if (this.canEditSessions) {
                let candidate = JSON.parse(JSON.stringify(session));

                let startTime = moment(candidate.SessionStartDateTime);
                let endTime = moment(candidate.SessionEndDateTime);

                let duration = moment
                    .duration(endTime.diff(startTime))
                    .asMinutes();

                
                candidate["DurationMinutes"] = duration;

                this.selectedSession = candidate;
            }
        },

        beginCreateSession() {
            let scheduledDate = moment(new Date());

            scheduledDate = scheduledDate.add(
                "minutes",
                -scheduledDate.minutes()
            );
            scheduledDate = scheduledDate.add(
                "seconds",
                -scheduledDate.seconds()
            );
            scheduledDate = scheduledDate.add(
                "milliseconds",
                -scheduledDate.milliseconds()
            );
            scheduledDate = scheduledDate.add("hours", 1);
            scheduledDate = scheduledDate.add("days", 1);

            // TODO: Align to 5 minute boundary a few hours from now

            let endDateTime = scheduledDate + 60000 * 20;

            this.selectedSession = {
                DurationMinutes: 20,
                SessionStartDateTime: moment(scheduledDate)
                    .utc(false)
                    .toISOString(),
                SessionEndDateTime: moment(endDateTime)
                    .utc(false)
                    .toISOString(),
                Name: `New Session - ${this.sidebarParams.Name}`,
                Location: null,
                Category: {
                    Code: this.sidebarParams.PrimaryCategoryCode,
                },
                SubCategoryCodes: this.sidebarParams.CategoryCodes,
                Show: {
                    Code: AppState.showCode,
                },
                ContactInfo: {
                    ContactInfo: [
                        {
                            Type: "TractusLink",
                            DisplayText: `${this.sidebarParams.Name} - Click here to join session`,
                            Value: `tractus://Vendor?s=${AppState.showCode}&c=${this.sidebarParams.EntityCode}`,
                            Details: "",
                            IconUrl: null,
                        },
                    ],
                    PhotoUrl: null,
                    Bio: "",
                },
                PrimaryMedia: {},
                Code: `${
                    this.sidebarParams.EntityCode
                }_${this.getAzureCompatibleTimeStamp()}`,
            };
        },

        requestClose() {
            this.$emit("request-close");
        },

        onEntityUpdated(details) {
            if (details.EntityTypeCode != "SESSION") {
                return;
            }

            this.build();
        },

        async saveSelectedSession() {
            this.submitting = true;

            try {
                let result = await this.tryPost(
                    `/api/session`,
                    JSON.stringify(this.selectedSession),
                    "application/json"
                );

                this.selectedSession = null;

                this.build();

            } catch {
                this.$awn.alert("We could not save your session. Please try again later.");
                this.submitting = false;
            }
        },

        async build() {
            this.submitting = true;

            try {
                let result = await this.tryGet(
                    `/api/show/${AppState.showCode}/agenda/${this.sidebarParams.PrimaryCategoryCode}`
                );

                this.sessions = result.Result;
            } catch (ex) {
                console.error(ex);
            }

            this.submitting = false;
        },
    },

    data() {
        return {
            sessions: [],
            selectedSession: null,
        };
    },

    beforeUnmount() {
        window.$bus.$off("entity-updated", this.onEntityUpdated);
    },

    created: function () {
        this.build();
        window.$bus.$on("entity-updated", this.onEntityUpdated);
    },
};
</script>