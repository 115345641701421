import { reactive } from 'vue';

export const AppState = reactive({
    attendee: null,
    attendeeCode: null,
    
    show: null,
    showCode: null,

    notifications: {
        UnreadChatCodes: [],
        UnreadDiscussionCodes: [],
        UnreadAnnouncementCodes: [],
        HasUnreadAnnouncements: false,
    },

    onlineAttendeeCodes: [],
    onlineAttendeeCount: 0,
});