<template>
    <div v-if="hasValidElementToShow"
         class="root-media-container"
         :class="{'blur-enable': blurBg}">
        <div class="media-container media-video-container"
             :class="{'media-video-container-full-height': isFullHeightContent}">

            <div v-if="media.MediaType == 'LiveWebSource' && !editMode"
                class="embed-container">

                <iframe :src="iFrameSrc" allow="camera;microphone;fullscreen" allowfullscreen>

                </iframe>
            </div>

            <div v-else-if="media && media.MediaType == 'HlsSource' && !editMode"
                class="hls-container">
                <hls-video-player :src="media.Uri" 
                                  :previewImage="media.ThumbnailUri"
                                  :waitForStream="true"
                                  :showControls="true"
                                  :startAtSeconds="media.StartAtSeconds"
                                  ref="videoplayer"
                                  :inPreviewMode="false"
                                  :entityTypeCode="entityTypeCode"
                                  :entityCode="entityCode"
                                  :emitProgress="true"
                                  :lastUserPositionSec="lastUserPositionSec">
                </hls-video-player>
            </div>
            <div v-else-if="media && media.MediaType == 'MuxLive' && !editMode"
                 class="hls-container">
                <mux-player :playback-id="media.Uri"
                            :metadata-video-id="muxVideoMetadata"
                            :metadata-viewer-user-id="muxUserId"
                            :metadata-video-title="entityName"
                            default-stream-type="live"
                            :placeholder="media.ThumbnailUri"
                            ref="muxVideoPlayer"
                            autoplay
                            @canplay="onCanPlay"
                            @playing="onMuxEvent"
                            @pause="onMuxEvent"
                            @ended="onMuxEvent">
                </mux-player>
            </div>
            <div v-else-if="media && media.MediaType == 'MuxAsset' && !editMode"
                 class="hls-container">
                <mux-player :playback-id="media.Uri"
                            :metadata-video-id="muxVideoMetadata"
                            :metadata-viewer-user-id="muxUserId"
                            :metadata-video-title="entityName"
                            ref="muxVideoPlayer"
                            @playing="onMuxEvent"
                            @pause="onMuxEvent"
                            @ended="onMuxEvent">
                </mux-player>
            </div>            
            <div v-else
                class="cover-container"
                @click="onMediaPhotoClick">
                <img-g v-if="media.ThumbnailUri"
                    :src="media.ThumbnailUri" />
                <img-g v-else :src="fallbackPhoto" />

                <slot>

                </slot>
            </div>
        </div>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Common from './common'
import Token from './authentication/token'
import "@mux/mux-player";
import loadingContainer from './loading.container.vue';

export default {
  components: { loadingContainer },
    props: [
        'entityCode',
        'entityTypeCode',
        'entityName',
        'media',
        'fallbackPhoto',
        'forceShow',
        'editMode',
        "blurBg",
        'lastUserPositionSec'
    ],

    mixins: [
        Token,
        Common,
        //ElementMixin
    ],   

    data() {
        return {
            wasWarnedAboutAutoplayBlock: false
        }
    },

    watch: {
        media(newMedia, oldMedia) {
            if(!oldMedia) {
                return;
            }  

            if(oldMedia.MediaType != 'MuxLive' && oldMedia.MediaType != "MuxAsset") {
                return;
            }

            let ev = {
                eventType: 'stopped:destroy',
                time: this.$refs.muxVideoPlayer.currentTime,
                raised: new Date(),
                src: `MUX_${oldMedia.MediaType}_${AppState.attendee.Show.Code}_${AppState.attendee.Code}_${this.entityTypeCode}_${this.entityCode}`
            };

            window.$bus.$emit('videoevent', ev);

            this.$emit('videoevent', ev);        
        },
    },

    computed: {
        isFullHeightContent() {
            return this.iFrameSrc
                && this.iFrameSrc.startsWith('/zoom.html');
        },

        hasValidElementToShow() {
            if(this.forceShow) {
                return true;
            }
            
            if(!this.media && !this.fallbackPhoto) {
                return false;
            }

            if(this.media 
                && !this.media.MediaType 
                && !this.media.ThumbnailUri 
                && !this.fallbackPhoto) {
                return false;
            }

            if(this.media
                && this.media.MediaType
                && !this.media.Uri
                && !this.media.ThumbnailUri
                && !this.fallbackPhoto)
            {
                return false;                
            }

            return true;
        },

        muxVideoMetadata() {
            return `${AppState.attendee.Show.Code}_${this.entityTypeCode}_${this.entityCode}`;
        },

        muxUserId() {
            return `${AppState.attendee.Show.Code}_${AppState.attendee.Code}`;
        },

        iFrameSrc() {

            if(this.media 
                && this.media.MediaType == 'LiveWebSource') {

                let link = this.media.Uri;

                if(link.indexOf('{{showCode}}') != -1) {
                    link = link.replace('{{showCode}}', AppState.attendee.Show.Code);
                } 

                if(link.indexOf('{{attendeeCode}}') != -1) {
                    link = link.replace('{{attendeeCode}}', AppState.attendee.Code);
                }

                if(link.indexOf('{{entityTypeCode}}') != -1) {
                    link = link.replace('{{entityTypeCode}}', this.entityTypeCode);
                }

                if(link.indexOf('{{entityCode}}') != -1) {
                    link = link.replace('{{entityCode}}', this.entityCode);
                }

                return link;
            }

        },        
    },

    methods: {
        async onCanPlay() {
            console.log('onCanPlay - mux player');
            let v = this.$refs.muxVideoPlayer;

            try {
                console.log('asking to play...');
                await v.play();
                console.log('play...');
            } catch(ex) {
                console.error("Autoplay failed.", ex);
                if(this.wasWarnedAboutAutoplayBlock) {
                    return;
                }

                this.wasWarnedAboutAutoplayBlock = true;

                let promise = new Promise(r => {
                    this.$awn.confirm(
                        "Your browser has blocked auto-play of the conference audio. Click 'Join' to listen.",
                        () => {
                            r('End');
                        },
                        false,
                        {
                            labels: {
                                confirm: "Join",
                            }
                        }
                    )
                });

                await promise;

                await v.play();
            }
        },


        onMediaPhotoClick() {
            this.$emit('cover-click');
        },

        onMuxEvent(e) {
            //console.log("onMuxEvent!", this.$refs.muxVideoPlayer, e);

            let ev = {
                eventType: e.type,
                time: this.$refs.muxVideoPlayer.currentTime,
                raised: new Date(),
                src: `MUX_${this.media.MediaType}_${AppState.attendee.Show.Code}_${AppState.attendee.Code}_${this.entityTypeCode}_${this.entityCode}`
            };

            window.$bus.$emit('videoevent', ev);

            this.$emit('videoevent', ev);
        },        
    },

    beforeUnmount() {
        //console.warn(this.media, this.$refs.muxVideoPlayer, "!!!!");
        if(!this.media) {
            return;
        }

        if(this.media.MediaType != 'MuxLive' && this.media.MediaType != "MuxAsset") {
            return;
        }

        let ev = {
            eventType: 'stopped:destroy',
            time: this.$refs.muxVideoPlayer.currentTime,
            raised: new Date(),
            src: `MUX_${this.media.MediaType}_${AppState.attendee.Show.Code}_${AppState.attendee.Code}_${this.entityTypeCode}_${this.entityCode}`
        };

        window.$bus.$emit('videoevent', ev);

        this.$emit('videoevent', ev);        
    },

    mounted() {
        console.log(this.media);
    }

}
</script>