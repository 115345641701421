<template>
    <div class="page-container">
        <vue-title :title="title" :brand="true"></vue-title>
        <div class="qr-container">
            <video ref="qrFeed">

            </video>

            <div class="qr-overlay">

            </div>

            <div class="qr-tools">
                <div class="button-group">
                    <button :class="{'active': mode == 'leads'}"
                            @click="switchToLeadMode">
                        View Leads
                    </button>
                </div>
            </div>

            <div class="qr-bottom-tools">
                <select class="form-select"
                        v-model="selectedVendorCode">
                    <option :value="null">(Select Page...)</option>
                    <option v-for="vendor in vendors" 
                            :key="vendor.Entity.Code"
                            :value="vendor.Entity.Code">
                        {{ vendor.Entity.Name }}
                    </option>
                </select>
            </div>

            <div class="qr-loading"
                v-show="submitting">
                <loading>

                </loading>
            </div>

            <div class="qr-check"
                v-if="lead">
                
                <div class="qr-check-results">
                    <div class="lead-form">
                        <div class="attendee-lead-header">
                            <img-g :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${lead.AttendeeCode}/profile`"
                                fallbackSrc="/img/person.png"></img-g>
                            <div class="detail-page-bio">
                                <h1 class="title">
                                    {{lead.Attendee.DisplayName}}
                                </h1>
                                <h2 v-if="lead.Attendee.Title"> 
                                    {{lead.Attendee.Title}}
                                </h2>
                                <h3 v-if="lead.Attendee.SubTitle">
                                    {{lead.Attendee.SubTitle}}
                                </h3>
                            </div>
                        </div>

                        <div class="form-control">
                            <label>
                                E-Mail Address
                            </label>
                            <input v-model="lead.Email"
                                    required
                                    aria-label="E-Mail Address"
                                    :disabled="submitting">
                        </div>
                        <div class="form-control">
                            <label>
                                Phone Number
                            </label>
                            <input v-model="lead.Number"
                                    required
                                    aria-label="Phone Number"
                                    :disabled="submitting">
                        </div>
                        <div class="form-control">
                            <label>
                                Notes
                            </label>
                            <textarea v-model="lead.Notes"
                                    aria-label="Notes"
                                    required
                                    :disabled="submitting"
                                    maxlength="10000">

                            </textarea>
                        </div>
                    </div>  

                    <div class="save-lead-buttons">
                        <button @click="cancelSave()">
                            Cancel
                        </button>
                        <button @click="saveLeadScan()">
                            Save
                        </button>
                    </div>
                </div>
            </div>

            <div class="qr-loading qr-error"
                v-show="error">
                <span>
                    {{ error }}
                </span>

                <button @click="error = null">
                    Dismiss
                </button>
            </div>


            <div class="search-box-overlay"
             v-if="mode == 'leads'">
                <div class="search-box">
                    <span class="icomoon">
                        
                    </span>
                    <input placeholder="Search" 
                        v-model="searchText"
                        ref="searchInput"
                        type="text"/>
                </div>
            </div>

            <div v-if="mode == 'leads'"
                class="lead-list">
                <div class="header-actions">
                    <span class="title">
                        Leads
                    </span>
                    <select class="form-select"
                            v-model="selectedVendorCode"
                            v-if="vendors.length > 1">
                        <option v-for="vendor in vendors" 
                                :key="vendor.Entity.Code"
                                :value="vendor.Entity.Code">
                            {{ vendor.Entity.Name }}
                        </option>
                    </select>                
                    <div class="actions">
                        <button class="icomoon" @click="exportToCsv">
                            
                        </button>
                        <button class="icomoon" @click="switchToScanMode">
                            
                        </button>
                    </div>
                </div>

                <div class="item-list">
                    <div v-if="leads.length == 0">
                        No leads for this page.
                    </div>
                    <div v-for="item in filteredLeads"
                        :key="item.AttendeeCode"
                        class="favorite-list-item list-item scan-list-item"
                        @click="editLead(item)">
                        <div>
                            <img-g :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${item.AttendeeCode}/profile`"
                                    class="round-photo"
                                fallbackSrc="/img/person.png" />
                            <div>
                                <span class="title">
                                    {{item.Attendee.DisplayName}}
                                </span>
                                <span class="subtitle"
                                        v-if="item.Attendee.Title">
                                    {{item.Attendee.Title}}
                                </span>
                                <span class="subtitle"
                                        v-if="item.Attendee.SubTitle">
                                    {{item.Attendee.SubTitle}}
                                </span>
                                <a class="subtitle"
                                @click.stop
                                v-if="item.Email"
                                :href="`mailto:${item.Email}`">
                                    <span class="icomoon">&nbsp;</span>{{item.Email}}
                                </a>
                                <a class="subtitle"
                                @click.stop
                                v-if="item.Number"
                                :href="`tel:${item.Number}`">
                                    <span class="icomoon">&nbsp;</span>{{item.Number}}
                                </a>
                            </div>
                            <button @click.stop="launchDMAsync(item.AttendeeCode)"
                                    class="button-outline btn icomoon round-button">
                                    
                            </button>
                        </div>
                        <p class="lead-notes"
                           v-if="item.Notes">
                            {{ item.Notes }}
                        </p>
                    </div>
                </div>
            </div>

            <div v-if="!mode"
                class="launch-page">
                <button @click="switchToScanMode">
                    Scan Badges
                </button>
                <button @click="switchToLeadMode">
                    View/Export Leads
                </button>
            </div>
        </div>
        <router-view class="right-pane">

        </router-view>
    </div>
</template>
<script>
import QrScanner from 'qr-scanner'; 
import moment from 'moment'
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'


export default {
    props: ['sectionCode'],
    mixins: [
        Token,
        Common
    ],   

    data() {
        return {
            vendors: [],
            leads: [],

            selectedVendorCode: null,

            submitting: false,
            scanner: null,
            lastScannedText: null,
            showLastScannedText: false,
            scanStatus:"",
            error: null,
            mode: '',

            lead: null,
            searchText: '',
        }
    },

    computed: {
        title() {
            return AppState.show.Sections[this.sectionCode].Title;
        },

        showCode() {
            return AppState.showCode;
        },

        filteredLeads() {
            let leads = this.leads;

            if(!this.searchText) {
                return leads;
            }

            let toReturn = Array.from(this.leads);

            if(this.searchText && this.searchText.length > 0) {
                let searchTest = new RegExp(this.searchText, 'i');

                toReturn = toReturn.filter(x => {

                    return searchTest.test(x.Attendee.DisplayName);

                });
            }

            return toReturn;
        }
    },

    watch: {
        selectedVendorCode(newValue, oldValue){
            this.lastScannedText = null;
            this.scanStatus = "";

            if(this.selectedVendorCode
                && localStorage) {
                
                localStorage.setItem("SELECTED_VENDOR", this.selectedVendorCode);
            }

            if(oldValue) {
                try {
                    window.$signalRConnection.invoke(
                        'UnregisterForPageAnalytics', 
                        `${AppState.showCode}_${oldValue}_LEADS`.toUpperCase());
                } catch {

                }
            }

            if(this.mode == 'leads') {
                this.reloadLeads();
            }
        },

    },

    methods: {
        async launchDMAsync(attendeeCode) {
            let routeToFetch = `/api/chats/${AppState.show.Code}/VENDOR/${this.selectedVendorCode}/${attendeeCode}`;

            try {
                let result = await this.tryGet(routeToFetch);
                console.log(result);

                if(!result?.Result?.Discussion) {
                    throw "Not found";
                }

                console.log(result);

                let route = {
                    name: 'DiscussionForVendorScanner', 
                    params: { discussionCode: result.Result.Discussion.Code }};
                
                this.$router.push(route);

            } catch(ex) {
                console.error(ex);
                this.$awn.alert("Could not start the chat. Please try again later.");
            }

        },

        async build(){
            this.error = null;

            this.submitting = true;

            let section = AppState.show.Sections[this.sectionCode];

            let result = await this.tryGet(`/api/favorites/my?type=VENDOR&admin=true`);

            this.vendors = result.Result;

            if(this.vendors.length == 0) {
                this.$awn.alert("You are not set up to scan for any pages. Please contact support.");
                this.$route.go(-1);
                return;
            }

            if(localStorage) {
                let lastSession = localStorage.getItem("SELECTED_VENDOR");

                if(lastSession) {
                    this.selectedVendorCode = lastSession;
                }
            }

            if(!this.selectedVendorCode) {
                this.selectedVendorCode = this.vendors[0].Entity.Code;
            }

            this.submitting = false;
        },

        editLead(lead) {
            this.lead = JSON.parse(JSON.stringify(lead));
        },

        async saveLeadScan() {
            if(!this.lead) {
                return;
            }

            this.submitting = true;

            try{
                let result = await this.tryPost(`/api/scan/vendor/${AppState.show.Sections[this.sectionCode].Code}`, JSON.stringify(this.lead), 'application/json');

                if(result && result.Result) {
                    this.$awn.info("Lead saved.");
                    this.lead = null;
                } else {
                    this.$awn.alert("Scan not saved. Please try again.");
                }
            } catch(ex){
                this.$awn.alert("Scan not saved. Please try again.");
            }

            this.lastScannedText = null;

            this.submitting = false;
        },

        cancelSave() {
            this.lead = null;
            this.lastScannedText = null;
        },

        async getLeadsAsync() {
            this.submitting = true;
            
            this.submitting = false;
        },

        async onScanSuccess(result) {

            if(this.lead) {
                return;
            }

            if(!this.selectedVendorCode){
                this.$awn.alert("No Page Selected")
                return;
            }

            if(this.lastScannedText == result.data) {
                this.scanStatus = "Successfully Scanned";
                return;
            }

            this.scanStatus = "";

            this.lastScannedText = result.data;

            if(this.lastScannedText.startsWith('tractus://')) {
                
                let url = new URL(result.data);
                let entityType = result.data
                        .replace("tractus://", "")
                        .split("?")[0]
                        .toLowerCase();

                if(entityType.toLowerCase() != "attendee") {

                    return;

                }

                this.submitting = true;
                let searchDetails = url.searchParams;

                let attendeeCode = searchDetails.get("c");
                let showCode = searchDetails.get("s");

                if(showCode != AppState.showCode) {
                    this.$awn.alert("This badge is not for this show.");
                    this.submitting = false;
                    return;
                }

                let lead = await this.tryGet(`/api/scans/${showCode}/check/vendor/${this.selectedVendorCode}/${attendeeCode}`);

                this.submitting = false;

                if(!lead || !lead.Result) {
                    this.$awn.alert("Sorry. QR code not recognized.");
                    return;
                }

                this.lead = lead.Result;

            } else {
                this.$awn.alert("Sorry. QR code not recognized.");
            }
            

        },

        async switchToScanMode() {
            this.mode = 'scanner';
            this.scanner = new QrScanner(
                this.$refs.qrFeed,
                this.onScanSuccess,
                {
                    highlightCodeOutline: true,
                    highlightScanRegion: true,
                    preferredCamera: 'environment',
                    maxScansPerSecond: 5
                });

            this.scanner.start();

            window.$signalRConnection.invoke(
                'UnregisterForPageAnalytics', 
                `${AppState.showCode}_${this.selectedVendorCode}_LEADS`.toUpperCase());
        },

        async switchToLeadMode() {
            if(this.scanner) {
                this.scanner.stop();
                this.scanner = null;
            }

            this.mode = 'leads';

            this.connectToControlChannel();

            this.reloadLeads();
        },

        async reloadLeads() {
            this.submitting = true;

            try {
                let result = await this.tryGet(`/api/scans/${this.showCode}/check/vendor/${this.selectedVendorCode}`);
                this.leads = result.Result || [];
            } catch {
                this.$awn.alert("Could not reload the lead list.");
            }

            this.submitting = false;
        },

        async connectToControlChannel(){
            if(window.$signalRConnection.notInitialized) {
                return;
            }

            if(!this.selectedVendorCode) {
                return;
            }

            if(this.mode != 'leads') {
                return;
            }

            window.$signalRConnection.invoke(
                'RegisterForPageAnalytics', 
                `${AppState.showCode}_${this.selectedVendorCode}_LEADS`.toUpperCase());
        },

        onLeadCaptured(lead) {
            if(!lead) {
                return;
            }

            if(lead.VendorCode != this.selectedVendorCode) {
                return;
            }

            let existingLead = this.leads.find(x => x.AttendeeCode == lead.AttendeeCode);

            if(!existingLead) {
                this.leads.push(lead);
            } else {
                Object.assign(existingLead, lead);
            }
        },

        exportToCsv() {
            let csvFile = 'Name,Title,SubTitle,Email,Phone,Captured Notes\n';

            this.leads.forEach(lead => {

                csvFile += `"${lead.Attendee.DisplayName?.replace('"', '') || ''}","${lead.Attendee.Title?.replace('"', '') || ''}","${lead.Attendee.SubTitle?.replace('"', '') || ''}","${lead.Email?.replace('"', '') || ''}","${lead.Number?.replace('"', '') || ''}","${lead.Notes?.replace('"', '') || ''}"\r\n`;
            });

            let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", 'export_leads.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

            this.$awn.info('Exported leads to CSV. Please check your downloads.');
        }
    },

    beforeUnmount() {
        window.$bus.$off('connected-to-signalr', this.connectToControlChannel);       
        window.$bus.$off('LeadCaptured', this.onLeadCaptured);    
        if(this.scanner) {
            this.scanner.stop();
        }

        window.$signalRConnection.invoke(
            'UnregisterForPageAnalytics', 
            `${AppState.showCode}_${this.selectedVendorCode}_LEADS`.toUpperCase());
    },

    async mounted() {
        window.$bus.$on('connected-to-signalr', this.connectToControlChannel);
        window.$bus.$on('LeadCaptured', this.onLeadCaptured);    
        await this.build();
    }

}
</script>