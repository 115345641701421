<template>
    <div v-if="link.Type == 'Section'"
         :class="[displayClasses]"
         :id="contactInfoId"
         @click="raiseClick">
        <h1 v-if="link.DisplayText">
            {{link.DisplayText}}
        </h1>
        <div v-if="link.Details" 
             v-html="markdown(link.Details)">
        </div>
        <component :is="'style'" 
                   v-if="link.Style"
                   v-html="styleTagContents">
        </component>
        <div v-if="editMode"
             class="edit-mode-drag-overlay">

        </div>
    </div>
    <div v-else-if="link.Type == 'Image'" 
         :class="[displayClasses]"
         @click="raiseClick"
         :id="contactInfoId">
        <img-g :src="link.IconUrl" class="responsive" />

        <span v-if="link.Details">
            {{link.Details}}
        </span>
    </div>
    <div v-else 
         :class="[displayClasses]"
         @click="raiseClick"
         :id="contactInfoId">
        <div class="icomoon link-icon"
             :class="{'photo-icon': link.Type == 'TractusLink' && (isAttendeeLink || isVendorLink)}">
            <div v-if="link.Type == 'Website' || link.Type == 'Downloadable'">{{ getIconForContactInfo(link) }}</div>
            <div v-else-if="link.Type == 'Email'">{{ getIconForContactInfo(link) }}</div>
            <div v-else-if="showDisplayPhoto">{{ getEntityPhoto(link.Value) }}</div>
            <img-g v-else :src="getEntityPhoto(link.Value)" fallbackSrc="/img/person.png" />
        </div>
        <div class="info-details">
            <div class="info-link-title">
                <div class="online-icon online" 
                    v-if="onlineStatus"></div>
                {{link.DisplayText}}
            </div>
            <div class="info-link-subtitle" v-if="link.Details">{{link.Details}}</div>
        </div>
        <div v-if="link && link.Type == 'Downloadable'" class="action-container">
            <a :href="link.Value" 
               target="_blank"
               class="icomoon round-button button-outline" 
               @click.stop="logClickDownloadEvent">
                
            </a>    
        </div>
    </div>
</template>
<script>
import Common from "./common";
import { AppState } from "./appstate";

export default {
    mixins: [Common],

    props: [
        "entityType", 
        "entityCode",
        "displayOnlineStatus",
        "link",
        "index",
        'editMode'
    ],

    computed: {
        showDisplayPhoto() {
            if(!this.link.Type == 'TractusLink')
                return false;
            
            if(!this.link.Value) {
                return false;
            }

            if(this.link.Value.localeCompare('attendee', 'en', {
                sensitivity: 'accent'
            }) === 0) {
                return true;
            }

            if(this.link.Value.localeCompare('vendor', 'en', {
                sensitivity: 'accent'
            }) === 0) {
                return true;
            }

            return false;
        },

        contactInfoId() {
            return `ci_${this.index}`;
        },

        styleTagContents() {
            if(!this.link.Style) {
                return null;
            }

            return this.link.Style.replaceAll("#ci_ID", `#ci_${this.index}`);
        },

        displayClasses() {
            let classAdd = "info-link";

            if(this.link.Type == 'Section') {
                classAdd = 'info-link-section'
            } else if(this.link.Type == 'Image') {
                classAdd = 'info-link-image';
            }

            if(this.link.Value){
                classAdd = `${classAdd} info-link-clickable`;
            }

            if(!this.link.Size) {
                return `${classAdd} info-link-full-width`;
            }

            return `${classAdd} info-link-${this.link.Size.toLowerCase()}-width info-link-item`;
        },

        isAttendeeLink() {
            return this.link
                && this.link.Type == 'TractusLink'
                && this.link.Value.indexOf('//Attendee') != -1;
        },

        isVendorLink() {
            return this.link
                && this.link.Type == 'TractusLink'
                && this.link.Value.indexOf('//Vendor') != -1;
        },

        onlineStatus() {
            let code = this.link.Value.split('&').find(x=>x.startsWith('c='));
            try {
                if(!this.isAttendeeLink) {
                    return false;
                }

                if(!code) {
                    return false;
                }

                code = code.replace('c=', '');

                return AppState.onlineAttendeeCodes.find(x => x == code);

            } catch {
                return false;
            }
        }
    },

    methods: {
        raiseClick() {
            if(this.editMode) {
                this.$emit('openlink', this.link);
            } else {
                if(!this.link.Value && this.link.Type == "Image"){
                    return;
                }

                this.$emit('openlink', this.link);
            }
        },

        logClickDownloadEvent() {
            if(this.editMode) {
                return;
            }
            
            this.postAnalytics(
                AppState.attendeeCode,
                "Download",
                this.entityCode,
                this.entityType,
                AppState.showCode,
                this.link.Value
            );            
        },
    }
}
</script>