<template>
    <div v-if="section.SectionTypeCode == 'HomeContent'"
         class="section-item-content"
         :class="[`section-item-${size}`, `section-non-interactive`]"
         v-html="markdown(section.Content)">

    </div>
    <div class="section-item"
         v-else-if="size.indexOf('-home-image') == -1"
         :class="[`section-item-${size}`, `section-${section.SectionTypeCode ? 'interactive' : 'non-interactive'}`]"
         @click="raiseClick()">
        <div class="img-container" :class="aspectRatioClass" v-if="section.BackgroundImage">
            <img-g :src="section.BackgroundImage"
                    class="section-item-bg-image" />
        </div>
        <div class="section-details">
            <span class="section-item-title">
                <span v-if="icon" class="icomoon section-item-icon">
                    {{ icon }}
                </span>
                <template v-if="section.SectionTypeCode == 'AppLink' && section.AppLinkTypeCode == 'DiscussionArea'">
                    <span class="notification-badge" v-if="AppState.notifications.UnreadChatCodes.length > 0"></span>
                </template>
                <template v-else-if="section.SectionTypeCode == 'AppLink' && section.AppLinkTypeCode == 'Announcements'">
                    <span class="notification-badge" v-if="AppState.notifications.UnreadAnnouncementCodes.length > 0"></span>
                </template>
                {{section.Title}}
            </span>
            <span class='section-item-description'>
                {{description}}
            </span>
        </div>
    </div>
    <div v-else
         @click="raiseClick()"
         class="section-item-image"
         :class="[`section-item-${size}`, aspectRatioClass]">
        <img-g v-if="section.BackgroundImage"
               :src="section.BackgroundImage"
               class="section-item-bg-image" />
    </div>
</template>
<script>
import { AppState } from '../appstate'
import marked from 'marked'

export default {
    props: [
        'section',
        'notifications'
    ],

    computed: {
        AppState() {
            return AppState;
        },

        size() {
            return this.section.Size
                ? this.section.Size
                : 'medium';
        },

        aspectRatioClass() {
            if(!this.section.AspectRatio
                || !this.section.BackgroundImage) {
                return '';
            }

            return `aspect-ratio-${this.section.AspectRatio}`;
        },

        description() {
            return this.section.Description
                && this.section.Description != 'Describe your section here.'
                ? this.section.Description
                : '';
        },

        icon() {
            return this.section.IconUrl
                && !this.section.IconUrl.includes('http')
                ? this.section.IconUrl
                : null;
        },
    },

    methods: {
        raiseClick() {
            if(!this.section.SectionTypeCode) {
                return;
            }
            
            this.$emit('interacted');
        },

        markdown(text) {
            if(!text) {
                return null;
            }
            
            text = text.replace("<iframe", "<div class='iframe-ratio'><iframe loading='lazy' ");
            text = text.replace("</iframe>", "</iframe></div>");
            
            const regEx = /\[embed=(.*)\]/g

            let input = text;
            
            let matchResult = input.matchAll(regEx)
           
            let returnContent = input;
            
            for(const m of matchResult) {
              returnContent = returnContent.replace(m[0], `<div class='iframe-ratio'><iframe loading='lazy' src="${m[1]}" allow="fullscreen;camera;microphone"></iframe></div>`);
            }
            
            return marked(returnContent, {
                breaks: true
            });
        },
    },
}
</script>