<template>
    <router-link v-if="loaded" 
                 class="page-list-item"
                 :to="{name: 'vendorDetails', params: {code: page.Code}}">


        <img-g :src="page.ListCoverUrl"
               v-if="page.ListCoverUrl"
               class="responsive list-cover-photo">

        </img-g>
        <img-g :src="page.ContactInfo.PhotoUrl"
            v-else-if="page.ContactInfo.PhotoUrl"
            class="responsive list-cover-photo">
        </img-g>
        <img v-else
             src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=">
        
        <div class="page-list-item-details">

            <span class="title">
                <div class="online-icon online" 
                            v-if="page.DiscussionWebinarStatus == 'Public'"></div>
                    {{page.Name}}
            </span>

            <span class="location"
                    v-if="page.Location">
                <span class="icomoon"></span>{{page.Location}}
            </span>

            <span class="subtitle"
                v-if="page.Headlines">
                {{page.Headlines}}
            </span>
        </div>

 
        <button type="button"
                class="button-outline round-button favorite-button"
                :class="[pageModel.CanEditFavorite ? '' : 'button-noninteractive']"
                @click.stop.prevent="emitFavoriteClick">
            <span class="icomoon">
                {{pageModel.IsFavorite ? '' : ''}}
            </span>
        </button>

    </router-link>
</template>
<script>
export default {
    props: ['pageModel'],

    computed: {
        loaded() {
            return this.pageModel && this.pageModel.Vendor;
        },

        page() {
            return this.pageModel
                ? this.pageModel.Vendor
                : null;
        }
    },

    watch: {
        pageModel() {
            this.checkNeedLoad();
        }
    },

    mounted() {
        this.checkNeedLoad();
    },

    methods: {
        checkNeedLoad() {
            if(!this.pageModel || !this.pageModel?.Vendor) {
                this.$emit('need-load');
            }
        },

        emitFavoriteClick() {
            let pageModel = this.pageModel;

            if(!pageModel.CanEditFavorite) {
                return;
            }

            window.$bus.$emit('request-toggle-favorite', {
                Type: "VENDOR",
                Code: pageModel.Vendor.Code,
                ReadOnly: false,
                AllowEditEntity: pageModel.IsPageAdmin,
                Active: !pageModel.IsFavorite
            });

            return false;
        }
    },
}
</script>