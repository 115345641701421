<template>
    <div v-if="submitting"
         class="fixed-content-width">
        <loading-container  />
    </div>
    <div class="attendee-detail-page detail-page-container"
         v-else-if="!submitting && attendeeInfo">
        <div class="detail-page">
            <vue-title :title="attendeeInfo.DisplayName" :brand="true"></vue-title>

            <div class="detail-page-summary">

                <div class="detail-page-summary-upper">
                    <div class="detail-page-logo">

                        <img-g :src="attendeeInfo.ContactInfo.PhotoUrl"
                            fallbackSrc="/img/person.png" />

                        <div class="detail-page-actions-under-img">
                            <router-link :to="{name: discussionDestinationRouteName, query: { attendeeCode: true }, params: { code: attendeeInfo.Code, typeCode: 'attendee', discussionCode: attendeeInfo.Code }}"
                                        v-if="!attendeeInfo.DoNotContact && attendeeInfo.Code != attendee.Code"
                                        class="round-button icomoon btn">
                                
                            </router-link>
                            <router-link :to="{name: 'EditMyProfile'}"
                                        v-if="attendeeInfo.Code == attendee.Code"
                                        class="round-button icomoon btn">
                                
                            </router-link>
                            <router-link :to="{name: 'ProfileViewsAttendee'}"
                                        v-if="attendeeInfo.Code == attendee.Code"
                                        class="round-button icomoon btn">
                                
                            </router-link>
                        </div>
                    </div>

                    <div class="detail-page-bio">
                        <h1 class="page-name">
                            <div class="online-icon" v-if="online"></div>
                            {{attendeeInfo.DisplayName}} 
                        </h1>
                        <h2 v-if="attendeeInfo.ContactInfo.Title">
                            {{attendeeInfo.ContactInfo.Title}}
                        </h2>
                        <h3 v-if="attendeeInfo.ContactInfo.SubTitle">
                            {{attendeeInfo.ContactInfo.SubTitle}}
                        </h3>
                        <div class="attendee-icons">
                            <div v-for="icon in iconCategories"
                                    :key="icon.Code">
                                <img-g :src="icon.IconUrl">
                                    
                                </img-g>
                            </div>
                        </div>

                    </div>
                </div>


                <p v-if="attendeeInfo.ContactInfo.Bio"
                    class="detail-page-bio-text">
                    {{attendeeInfo.ContactInfo.Bio}}
                </p>


                <contact-info-list :contactInfo="attendeeInfo.ContactInfo.ContactInfo"
                                :attendee="attendee"
                                entityType="Attendee"
                                :entityCode="code">
                </contact-info-list> 
            </div>


        </div>
        <router-view class="right-pane" 
                        :attendee="attendee" 
                        :typeCode="'Attendee'" 
                         
                        :code="code"></router-view>
    </div>
</template>
<script>

import Token from './authentication/token'
import Common from './common'
import { AppState } from './appstate';

export default {
    props: ['code'],
    mixins: [
        Token,
        Common
    ],   

    computed: {
        attendee() {
            return AppState.attendee;
        },

        discussionDestinationRouteName() {
            return this.$route.name == 'attendeeDetailsNetwork'
                ? 'DiscussionForAttendeeNetworkList'
                : this.$route.name == 'attendeeDetailsAttendeeList'
                    ? 'DiscussionForAttendeeAttendeeList'
                    : 'DiscussionForAttendee';
        },

        iconCategories() {
            try {
                let showCategories = AppState.attendee.Show.AttendeeCategories.filter(x => x.IconUrl);

                let selfCategories = this.attendeeInfo.Categories;

                console.log(showCategories, selfCategories)
                let result = [];

                for(let i = 0; i < selfCategories.length; i++) {
                    let selfCategory = selfCategories[i];

                    let category = showCategories.find(x => x.Code == selfCategory.Code);

                    if(!category
                        && !selfCategory.IconUrl) {
                        
                        continue;
                    }

                    if(category) {
                        result.push(category);
                    } else {
                        result.push(selfCategory);
                    }
                }

                return result;

            } catch {
                return [];
            }
        },
    },

    watch: {
        code(to, from) {
            this.build();
        },
    },

    methods: {
        async build() {
            this.submitting = true;
            
            let attendeeResult = await this.tryGet(`/api/attendees/${this.code}`, false, false);
            if(attendeeResult.Errors.length > 0){
                this.$router.replace("/404");
            }

            let result = attendeeResult.Result;

            this.attendeeInfo = result.Attendee;

            this.online = result.Online;

            this.postAnalytics(
                AppState.attendee.Code,
                'AttendeeDetails',
                this.code,
                "Attendee",
                AppState.attendee.Show.Code);
                
            this.submitting = false;
        },

        onEntityUpdated(details) {
            if(details.EntityTypeCode != "ATTENDEE" || details.EntityCode != this.code) {
                return;
            }

            this.build();
        },  
    },

    data() {
        return {
            online: false,
            attendeeInfo: null,
        }
    },

    beforeUnmount() {
        window.$bus.$off('entity-updated', this.onEntityUpdated);
    },

    created: function() {
        this.build();
        window.$bus.$on('entity-updated', this.onEntityUpdated);        
    },
}
</script>