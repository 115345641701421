<template>
    <div class="page-side-panel" v-if="!submitting && network">
        <div class="header-actions">
            <span class="title">{{onlineCodes.length}} online</span>
            <div class="header-buttons">
                <button class="icomoon" 
                        @click="requestClose()">
                    
                </button>
            </div>
        </div>
        <div class="page-side-panel-content">
            <div class="page-side-panel-list">
                <attendee-network-list-item v-for="item in filteredResults"
                                            :key="item.Code"
                                            :attendee="item.Attendee"
                                            :class="[$route.params && ($route.params.discussionCode && $route.params.discussionCode == item.Code) || ($route.params.code == item.Code) ? 'list-item-selected' : '']"
                                            class="attendee-network-list-item-right-action">
                    <template slot="actions">
                        <div class="attendee-card-actions">
                            <button type="button"
                                    class="button-outline icomoon round-button"
                                    @click.stop="onRequestChat(item.Code)">
                                
                            </button>
                        </div>
                    </template>
                </attendee-network-list-item>
            </div>
        </div>
    </div>
    <div v-else>
        <div class="header-actions">
            <span class="title">Loading...</span>
        </div>
        <div class="page-side-panel-content">
            <loading-container />
        </div>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
export default {
    props: ['code', 'onlineOnly'],

    mixins: [
        Token,
        Common
    ],   


    computed: {
        attendeeSelected() {
            return this.$route.name == 'attendeeDetailsNetwork';
        },

        chatSelected() {
            return this.$route.name == 'DiscussionForAttendeeNetworkList';
        },

        filteredResults() {
            if(!this.network) {
                return [];
            }            

            let targetArray = this.network.FullNetwork;

            if(this.searchTerm
                && this.searchTerm.length > 0) {

                let searchTest = new RegExp(this.searchTerm, 'i');
                targetArray = targetArray.filter(s => searchTest.test(s.Attendee.DisplayName) || searchTest.test(s.Attendee.ContactInfo.Title));

            }

            targetArray = targetArray.sort((a, b) => {
                let isAOnline = this.onlineCodes.indexOf(a.Attendee.Code) != -1;
                let isBOnline = this.onlineCodes.indexOf(b.Attendee.Code) != -1;

                if(!isAOnline && isBOnline) {
                    return 1;
                }

                if(isAOnline && !isBOnline) {
                    return -1;
                }

                return 0;
            });

            return targetArray;            
        }
    },
    methods: {
        async onRequestChat(code) {
            try {
                let foundArea = await this.tryPost('/api/discussion/find/members', JSON.stringify([code]), 'application/json');
                if(foundArea.Result) {
                    window.$bus.$emit('selectedchat', foundArea.Result.Discussion.Code);
                } else {
                    throw "Nope";
                }
            } catch(ex) {
                console.error(ex);
                alert("Sorry, we couldn't start a chat session. Please try again later.");
            }
        },
        
        requestClose() {
            this.$emit('request-close');
        },

        onFavoritesUpdated(){
        },

        updateSearchTerm(inputVal, eventObject){
            this.searchTerm = inputVal;
        },

        async reloadNetwork() {
            this.submitting = !this.network;

            let r = await this.tryGet(`/api/interactions/mynetwork?onlineOnly=${this.onlineOnly ? 'true' : 'false'}`);

            if(r.Errors.length > 0) {
                this.errorMessage = r.Errors[0];
            } else {
                this.network = r.Result;
                this.onlineCodes = r.Result.OnlineCodes;
            }

            window.$bus.$emit(
                'update-online-attendee-count-internal', 
                this.onlineCodes.length);

            this.submitting = false;
        },

        async reloadOnlineAttendees() {
            try {
                if(this.onlineOnly) {
                    await this.reloadNetwork();
                } else {
                    let r = await this.tryGet(`/api/interactions/allonline`);

                    if(r.Errors.length == 0) {
                        this.onlineCodes = r.Result;

                        window.$bus.$emit(
                            'update-online-attendee-count-internal', 
                            this.onlineCodes.length);
                    }
                } 
            } catch(ex) {

            }
        },
    },

    data() {
        return {
            network: null,
            displayMode: 'Everyone',
            searchTerm: '',
            onlineCodes: []
        }
    },

    created: function() {
        window.$bus.$on('entity-updated',this.reloadNetwork);
        window.$bus.$on('refresh-online-users', this.reloadOnlineAttendees);
        this.reloadNetwork();

        if(!this.onlineOnly) {
            this.postAnalytics(AppState.attendeeCode,'MyNetwork',null,null,AppState.showCode);
        }
    },

    beforeUnmount() {
        window.$bus.$off('entity-updated',this.reloadNetwork);
        window.$bus.$off('refresh-online-users', this.reloadOnlineAttendees);
    }
}
</script>