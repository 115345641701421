<template>
    <span>
        {{displayTime}}
    </span>
</template>
<script>
import moment from 'moment'

export default {
    props: ['time', 'short'],

    computed: {
        displayTime() {
            return moment(this.time).format('LLLL');
        }
    }
}
</script>