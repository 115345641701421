<template>
<div v-if="attendee && attendee.RoleCode == 'Administrator'">
    <button @click="rebootAll">
        Reboot
    </button>
    <br>
    <input v-model="rebootAttendeeCode" placeholder="Reboot Attendee">
    <button @click="rebootAttendee">
        Reboot Attendee
    </button>
</div>
<div v-else>
    You are not allowed to access this page.
</div>
</template>
<script>
import Common from './common'
import Token from './authentication/token'
import { AppState } from './appstate'
export default {

    mixins: [
        Common,
        Token
    ],
 
    computed: {
        attendee() {
            return AppState.attendee;
        }
    },

    created() {


    },

    mounted() {
    },

    props: [
    ],

    data() {
        return {
            rebootAttendeeCode: '',
        }
    },

    methods: {
        rebootAll() {
            let rebootConfirm = confirm("Are you sure you want to reboot? This will force reload the page for all.");

            if(!rebootConfirm) {
                return;
            }

            window.$signalRConnection.invoke('ControlChannelMessage', {
                ShowCode: AppState.showCode,
                FromCode: AppState.attendeeCode,
                TypeCode: 'ForceReboot',
                DID: this.getDeviceId(),
                Message: {}
            });

        },

        rebootAttendee() {
            let rebootConfirm = confirm("Are you sure you want to reboot the specified attendee? This will force reload the page for all.");

            if(!rebootConfirm) {
                return;
            }

            window.$signalRConnection.invoke('ControlChannelMessage', {
                ShowCode: AppState.showCode,
                FromCode: AppState.attendeeCode,
                ToCode: this.rebootAttendeeCode,
                TypeCode: 'ForceReboot',
                DID: this.getDeviceId(),
                Message: {}
            });

        }

    }
}
</script>