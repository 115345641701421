<template>
    <div class="page-container attendee-chat-list">
        <vue-title :title="'Messages'" v-if="!isInline" :brand="true"></vue-title>
        <div class='item-list thin-list' v-if="!submitting">
            <div class="header-actions">
                <span class="title">
                    Chats
                </span>
                <div class="header-buttons">
                    <button v-if="!isInline && canInviteAttendees" class="icomoon" @click="showChooser = true;">
                        
                    </button>
                    <button v-else-if="isInline"
                            class="icomoon" 
                            @click="requestClose()">
                        
                    </button>
                </div>

            </div>

            <div class="list-item chat-list-item"
                    @click="onSelectItem(discussion.Discussion)"
                    :class="[$route.params && $route.params.discussionCode && $route.params.discussionCode == discussion.Discussion.Code ? 'list-item-selected' : '']"
                    v-for="discussion in sortedDiscussions"
                    :key="discussion.Discussion.Code">

                <img-g :src="discussion.Discussion.PhotoUrl || getDisplayPhotosForChat(discussion.Discussion)"
                       class="chat-list-item-photo"
                       fallbackSrc="/img/person.png" />

                <div class="list-item-title-container">
                    <span class="list-item-title">
                        <span class="notification-badge" v-if="isDiscussionUnread(discussion.Discussion.Code)"></span>
                        {{discussion.OverrideName || discussion.Discussion.Name}}</span>
                </div>    
            </div>                    

            <attendee-chooser v-on:cancelled="showChooser = false;" 
                              v-on:selected="onAttendeesChosen"
                              :showChooser="showChooser"
                              ref="chooseAttendees"
                              :title="'New Chat'">

            </attendee-chooser>
        </div>
        <loading-container v-else
                           class="item-list thin-list" />

        <router-view v-if="!isInline" :attendee="attendee" ></router-view>

    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'

import { AppState } from './appstate'

export default {
    props: ['attendee', 'code', 'notifications', 'isInline'],
    
    mixins: [
        Token,
        Common
    ],   

    computed: {
        sortedDiscussions() {
            let thisTest = this.HACK_flagToggle;
            let compareObject = new Intl.Collator();

            return this.discussions.sort((a, b) => (a.LastUpdatedStamp || '637397663020000000').localeCompare((b.LastUpdatedStamp || '637397663020000000')));
        },


        canInviteAttendees() {
            if(AppState.attendee.RoleCode == 'Administrator') {
                return true;
            }

            let permissions = AppState.attendee.DiscussionPermissions;

            if(AppState.attendee.Categories.length == 0
                || permissions.length == 0) {
                return false;
            }

            for(let i = 0; i < AppState.attendee.Categories.length; i++) {
                let category = AppState.attendee.Categories[i];

                let permission = permissions.find(x => x.CategoryCode == category.Code);

                if(!permission) {
                    return false;
                }

                if(!permission.CanInviteOthers) {
                    return false;
                }
            }

            return true;
        },
    },

    methods: {
        isDiscussionUnread(code) {
            return AppState.notifications.UnreadChatCodes.indexOf(code) != -1
        },

        requestClose() {
            this.$emit('request-close');
        },

        informClick(discussion) {
            window.$bus.$emit('selectedchat', discussion ? discussion.Code : null);
        },

        onSelectItem(discussion) {
            if(this.isInline) {
                this.informClick(discussion);
            } else {
                this.$router.push({name: 'DiscussionInternal', params:{discussionCode: discussion.Code}});
            }

            this.requestClose();
        },

        async onAttendeesChosen(attendees) {
            if(!attendees || attendees.length == 0) {
                return;
            }

            this.$refs.chooseAttendees.submitting = true;

            let foundArea = await this.tryPost('/api/discussion/find/members', JSON.stringify(attendees), 'application/json');

            if(foundArea.Result) {
                if(attendees.length >= 2 && !foundArea.Result.IsNew) {
                    // Warn the user they already have a chat.

                    let promise = new Promise(r => {
                        this.$awn.confirm(
                            "You already have a chat open with these attendees. Are you sure you want to start a new one?",
                            () => {
                                r("Ok");
                            },
                            () => {
                                r("Cancel");
                            },
                            {
                                labels: {
                                    confirm: "Chat Exists",
                                    confirmOk: "OK",
                                    cancel: 'Cancel'
                                }
                            }
                        )
                    });

                    let result = await promise;
                    if(result == 'Cancel') {
                        this.$refs.chooseAttendees.submitting = false;
                        return;
                    }

                    foundArea = await this.tryPost('/api/discussion/find/members?forcenew=true', JSON.stringify(attendees), 'application/json');
                }

                this.showChooser = false;

                if(this.isInline) {
                    this.onSelectItem(foundArea.Result.Discussion);
                } else {
                    this.$router.push({ path: `/discussionList/chat/${foundArea.Result.Discussion.Code}`, params: {discussionCode: foundArea.Result.Discussion.Code} });
                } 
            }

            this.$refs.chooseAttendees.submitting = false;
            this.showChooser = false;
            this.requestClose();

            this.reloadDiscussions(false);
        },

        getDisplayPhotosForChat(discussion) {
            if(discussion.PhotoUrl) {
                return discussion.PhotoUrl;
            }

            if(discussion.RelatedEntityTypeCode == 'VENDOR') {
                    return `${this.getApiUrl()}/api/asset/${AppState.attendee.Show.Code}/vendor/${discussion.RelatedEntityCode}/profile`;
            }

            let toReturn = '';

            if(discussion.ParticipantRoles && discussion.TypeCode == "Chat") {
                let attendeeCodes = Object.keys(discussion.ParticipantRoles);
                let counter = 0;

                for(let i = 0; i < attendeeCodes.length; i++) {
                    if(attendeeCodes[i] == AppState.attendee.Code) {
                        continue;
                    }

                    return `${this.getApiUrl()}/api/asset/${AppState.attendee.Show.Code}/attendee/${attendeeCodes[i]}/profile`;
                }

            } 

            return toReturn;
        },

        async reloadDiscussions(logAnalytics) {
            this.submitting = true;
            
            try {
                let r = await this.tryGet(`/api/discussions?type=Chat`);

                if(!this.isInline){
                    window.$bus.$emit('Clear-Chats');
                }

                if(r.Errors.length > 0) {
                    this.errorMessage = r.Errors[0];
                } else {
                    this.discussions = r.Result;

                    if(!this.isInline && logAnalytics) {
                        this.postAnalytics(AppState.attendee.Code,'DiscussionList',null,"ChatList",AppState.attendee.Show.Code);
                    }
                }

                this.submitting = false;
            } catch {
                this.errorMessage = "We could not load your info. Please try again later"; 
            }

            this.submitting = false;
        },

        onChatUpdate(details) {
            if(details.TypeCode != 'Chat') {
                return;
            }

            let discussion = this.discussions.find(x => x.Discussion.Code == details.EntityCode);

            if(!discussion) {
                this.reloadDiscussions(false);
                return;
            }

            discussion.LastUpdatedStamp = this.getAzureCompatibleTimeStamp();

            this.HACK_flagToggle = !this.HACK_flagToggle;
        },

        onDiscussionUpdated(discussion) {
            let foundDiscussion = this.discussions.find(x => x.Discussion.Code == discussion.Code);

            if(!foundDiscussion) {
                return;
            }

            foundDiscussion.OverrideName = discussion.Name;
        },

        onRemovedFromDiscussion() {
            this.reloadDiscussions(false);
        }
    },

    data() {
        return {
            discussions: [],
            HACK_flagToggle: false,
            showChooser: false,
        }
    },

    created: function() {
        if(!this.isInline) {
            window.$bus.$emit('close-all-chats');
        }
        this.reloadDiscussions(true);
        window.$bus.$on("Unread-Item", this.onChatUpdate);
        window.$bus.$on('Posted-To-Chat', this.onChatUpdate);
        window.$bus.$on('Discussion-Name-Change', this.onDiscussionUpdated);
        window.$bus.$on('removed-from-discussion', this.onRemovedFromDiscussion);
        window.$bus.$on('added-to-discussion', this.onRemovedFromDiscussion);
    },

    beforeUnmount() {
        window.$bus.$off("Unread-Item", this.onChatUpdate);
        window.$bus.$off('Posted-To-Chat', this.onChatUpdate);
        window.$bus.$off('Discussion-Name-Change', this.onDiscussionUpdated);
        window.$bus.$off('removed-from-discussion', this.onRemovedFromDiscussion);
        window.$bus.$off('added-to-discussion', this.onRemovedFromDiscussion);
    }
}
</script>