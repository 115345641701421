<template>
    <div v-if="submitting"
         class="fixed-content-width">
        <loading-container  />
    </div>
    <div v-else class="page-container">
        <div class="list-page-full page-list">

            <vue-title :title="title" 
                       :brand="true" />

            <div class="list-page-header">
                <div class="search-container">

                    <div class="search-box">
                        <span class="icomoon">
                            
                        </span>
                        <input placeholder="Search" 
                            v-debounce:150="updateSearchTerm"   
                            type="text"/>
                    </div>

                    <div class="button-group filter">
                        <button :class="{'active': showFilters}"
                                @click="showFilters = !showFilters">
                            <span class="icomoon">
                                
                            </span>
                            <span>
                                Filter
                            </span>
                        </button>

                        <button :class="{'active': favoritesOnly}"
                                @click="favoritesOnly = !favoritesOnly">
                            <span class="icomoon">
                                
                            </span>
                            <span>
                                My Favorites
                            </span>
                        </button>

                        <button @click="onPrintListRequested">
                            <span class="icomoon">
                                
                            </span>
                            <span>
                                Print
                            </span>
                        </button>
                    </div>
                    <div class="list-page-filter"
                        v-show="showFilters">
                        <div class="filter-buttons">
                            <button type="button"
                                    class="button-outline"
                                    v-for="category in items"
                                    :key="category.Code"
                                    @click="selectCategory(category.Code)"
                                    :class="{'active': selectedCategories.length == 0 || selectedCategories.indexOf(category.Code) != -1}">
                                <span class="category-pill" :class="`V-${category.Code}-filter`">

                                </span>
                                {{category.Name}}
                            </button>
                        </div>
                    </div>

                </div>
            </div>

            <div class='list-page-contents' 
                 ref="list"
                 id="list_page_contents"
                 v-debounce:300ms="onScroll"
                 debounce-events="scroll">
                <template v-for="category in filteredResults">
                    <div class="category-item"
                         v-bind:key="category.Code"
                         v-if="category.Items.length > 0">
                        <div class='category-item-header'>
                            <span class="category-title">
                                {{category.Name}}
                            </span>
                        </div>
                        <div class="category-items">
                            <template v-if="lookUpDisplayMode(category.Code) == 'Normal'">
                                <page-item v-for="page in category.Items"
                                          :key="page.Vendor.Code"
                                          :pageModel="page" />
                            </template>
                            <template v-else-if="lookUpDisplayMode(category.Code) == 'Deluxe'">
                                <page-item-deluxe v-for="page in category.Items"
                                                  :key="page.Vendor.Code"
                                                  :pageModel="page" />                                
                            </template>
                            <template v-else-if="lookUpDisplayMode(category.Code) == 'CustomHtml'">
                                <template v-for="page in category.Items"
                                          :key="page.Vendor.Code">
                                    <router-link class="page-custom-list-item"
                                                 :to="{name: 'vendorDetails', params: {code: page.Vendor.Code}}"
                                                 v-html="page.Vendor.ListTemplate">
                                    </router-link>
                                </template>
                            </template>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'
import printJS from 'print-js'
 
import { AppState } from './appstate';
import { toRaw } from 'vue';

export default {
    props: ['sectionCode'],
    mixins: [
        Token,
        Common
    ],   

    computed:{
        title() {
            return AppState.attendee.Show.Sections[this.sectionCode]?.Title;
        },

        filteredResults(){
            if(!this.items) {
                return [];
            }

            let toReturn = Array.from(this.items);

            if(this.selectedCategories.length != 0) {
                toReturn = toReturn.filter(x => this.selectedCategories.indexOf(x.Code) != -1);
            }

            // TODO: Just dim the non-fav results.

            if(this.searchTerm && this.searchTerm.length > 0 || this.favoritesOnly) {

                // In order to filter the nested arrays w/o destroying
                // anything, we need to make a deep copy.
                toReturn = JSON.parse(JSON.stringify(toReturn));

                if(this.searchTerm && this.searchTerm.length > 0) {
                    let searchTest = new RegExp(this.searchTerm, 'i');

                    toReturn.forEach(e => {
                        e.Items = [...e.Items].filter(x => searchTest.test(x.Vendor.Name));
                    });
                }

                if(this.favoritesOnly) {
                    toReturn.forEach(e => {
                        e.Items = [...e.Items].filter(x => x.IsFavorite);
                    });
                }



                toReturn = toReturn.filter(x => x.Items.length > 0);
            }

            return toReturn;
        }
    },

    watch: {
        sectionCode(to, from) {
            this.loadItems();
        },
    },

    methods: {
        onPrintListRequested() {
            let fieldsToPrint = [
                {
                    'field': 'Vendor.Name',
                    'displayName': 'Name' 
                }, 
            ];

            let shouldPrintLocation = false;

            let toPrint = [];
            
            this.filteredResults.forEach(c => {
                if(c.Items.find(x => x.Vendor.Location)) {
                    shouldPrintLocation = true;
                }

                c.Items.forEach(x => x.Vendor.Location = x.Vendor.Location || '');
                c.Items.forEach(x => x.Vendor.Headlines = x.Vendor.Headlines || '');

                toPrint.push(...c.Items);
            });

            if(shouldPrintLocation) {
                fieldsToPrint.splice(1, 0, { field: 'Vendor.Location', displayName: 'Location', columnSize: '20%' });
            }

            printJS({
                printable: toPrint,
                properties: fieldsToPrint,
                type: 'json',
                header: `<h3>${this.title}</h3><h4>${AppState.attendee.Show.Name}</h4>`,
                style: 'h3{text-align:center;font-size:24px;}h4{text-align:center;}*{font-family:Tahoma,sans-serif;}'
            });

        },

        lookUpDisplayMode(categoryCode){

            let displayMode = AppState.show.Sections[this.sectionCode]?.CategoryDisplayMode[categoryCode];

            if(!displayMode) {
                return "Normal";
            }
            
            return displayMode;
        },

        onScroll() {
            if(!window.sessionStorage) {
                return;
            }
            try {
                window.sessionStorage.setItem(`scroll:${this.sectionCode}`, this.$refs.list.scrollTop);
            } catch {

            }
        },

        onFavoritesUpdated(favorites){
            if(!favorites || !favorites.forEach) {
                this.loadItems();
            } else {
                favorites.forEach(f => {
                    if(f.EntityTypeCode != 'VENDOR') {
                        return;
                    }

                    this.items.forEach(vendorGroup => {

                        let vendor = vendorGroup.Items.find(x => x.Vendor.Code == f.EntityCode);

                        if(!vendor) {
                            return;
                        }

                        vendor.IsFavorite = f.IsActive;

                    });

                });

            }
        },

        selectCategory(categoryCode) {
            if(this.selectedCategories.indexOf(categoryCode) != -1) {
                this.selectedCategories.splice(this.selectedCategories.indexOf(categoryCode), 1);
            } else {
                this.selectedCategories.push(categoryCode);
            }

            if(this.selectedCategories.length == this.items.length) {
                this.selectedCategories = [];
            }
        },

        updateSearchTerm(inputVal, eventObject){
            this.searchTerm = inputVal;
        },

        async loadItems(){
            this.submitting = true;
            this.searchTerm = "";

            // Step 1: Post your analytics here.
            var section = AppState.attendee.Show.Sections[this.sectionCode];
            this.postAnalytics(
                AppState.attendee.Code, 
                `Pages:${this.sectionCode}`,
                this.code,
                section.SectionTypeCode, 
                AppState.attendee.Show.Code);

            // Step 2: Load your list from the web API.
            // This is just an example - you would replace this with
            // a call to the web API.

            let categoryQuery = section.Categories?.map(x => x.Code)?.join(';') || '';

            let onlyShowFavorites = section.SectionTypeCode == 'VendorsFavorites';
            this.onlyShowFavorites = onlyShowFavorites;

            let favoritesParam = (onlyShowFavorites) ? "only" : "";

            let vendorList = await this.tryGet(`/api/vendors?c=${categoryQuery}&f=${favoritesParam}`);
            this.items = vendorList.Result;

            this.submitting = false;


            // Step 4: Restore the scroll position if needed.

            this.$nextTick(() => {
                if(!window.sessionStorage) {
                    return;
                }

                let scrollPosition = window.sessionStorage.getItem(`scroll:${this.sectionCode}`);

                if(!scrollPosition) {
                    return;
                }

                this.$refs.list.scrollTo(0, parseInt(scrollPosition));
            });
        },


        onEntityUpdated(details) {
            if(!details || details.EntityTypeCode != "VENDOR") {
                return;
            }

            this.loadItems();
        },
    },

    data() {
        return {
            items: [],
            selectedCategories: [],
            searchTerm: '',
            submitting: false,
            favoritesOnly: false,
            onlyShowFavorites: false,
            showFilters: true,
        }
    },
    
    beforeUnmount() {
        window.$bus.$off('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$off('entity-updated',this.onEntityUpdated);
    },

    created: function() {
        if(this.isOnExtraSmallPlatform()) {
            this.showFilters = false;
        }

        this.loadItems();
        window.$bus.$on('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$on('entity-updated',this.onEntityUpdated);
    },
}
</script>