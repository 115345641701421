<template>
    <img loading="lazy" 
         :src="source" 
         class="hide-until-create" 
         :class="{'fade-in-on-create': !noFade && (loaded || error), 'force-show': noFade }" 
         @load="loaded = true" 
         @error="error = true" />

</template>
<script>
export default {
    props: ['src', 'fallbackSrc', 'noFade'],

    computed: {
        source() {
            if(this.error
                || !this.src) {
                return this.fallbackSrc;
            }

            return this.src;
        }
    },

    data() {
        return {
            error: false,
            loaded: false
        }
    }
}
</script>