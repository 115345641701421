<template>
    <div class="badge-request-screen">
        <vue-title :title="'Tractus Passport | Get My Badge'" />
        <img-g src="/icon-192x192.png" class="responsive" />

        <h1 v-if="!submitting && !(result && result =='OK')">One more step...</h1>
        <p v-if="!submitting && !(result && result =='OK')">
            Click the button below to have your Tractus Passport 
            sent to your e-mail address.
        </p>

        <input type="text" 
                v-if="!submitting && !(result && result =='OK')"
               v-model="email"
               placeholder="Please enter your email here...">
        <button @click="askForBadge"
                v-if="!submitting && !(result && result =='OK')">
            Send My Login Details
        </button>

        <div v-if="!submitting && errorMessage">
            <h2>Oops. Something went wrong.</h2>
            <p>{{errorMessage}}</p>
        </div>
        <div v-if="submitting">
            <loading></loading>
        </div>
        <h1 v-if="result && result =='OK'">Thank You!</h1>
        <p v-if="result && result =='OK'">
            Keep an eye on your email from Tractus Events! It will have
            your login details.
        </p>
    </div>
  
</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'


export default {
    props: ['showCode'],

    mixins: [
        Token,
        Common
    ],

    methods: {
        async askForBadge() {
            this.errorMessage = null;
            await this.loadBadge(this.showCode, this.attendeeCode);
        },

        async loadBadge(showCode, attendeeCode) {
            this.submitting = true;
            try {

                let loadUrl = `/api/attendeebadge/${showCode}?email=${this.email}`;

                let r = await this.tryGetAnonymous(loadUrl);

                if(r.Errors.length > 0) {
                    this.errorMessage = r.Errors[0];
                } else {
                    this.result = r.Result;
                }
                this.submitting = false;

            } catch (e) {
                console.error(e);
                this.errorMessage = "We could not load your badge. Please try again later.";
                this.submitting = false;
            }
        },
    },

    data() {
        return {
            cautionMessage: null,
            linkMessage: null,
            result: null,
            errorMessage: null,
            email: null
        }
    },

    created: function() {
    }
}
</script>
