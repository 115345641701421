<template>
    <div v-if="submitting"
         class="fixed-content-width">
        <loading-container />
    </div>
    <div v-else class="fixed-content-width detail-page">
        <vue-title :title="'Your Profile'" :brand="true"></vue-title>

        <div v-if="editProfile" class="profile-editor">

            <!-- <div v-if="editProfile.PrimaryMedia"
                class="media-container"
                :class="{'media-video-container': editProfile.PrimaryMedia && editProfile.PrimaryMedia.Uri}">

                <div v-if="editProfile.PrimaryMedia.MediaType == 'LiveWebSource'"
                    class="embed-container">

                    <iframe :src="iFrameSrc" allow="camera;microphone;fullscreen" allowfullscreen>

                    </iframe>
                </div>
                <div v-else-if="editProfile.PrimaryMedia && editProfile.PrimaryMedia.MediaType == 'HlsSource'"
                        class="media-container media-video-container">
                    <hls-video-player :src="editProfile.PrimaryMedia.Uri" 
                                        :previewImage="editProfile.PrimaryMedia.ThumbnailUri"
                                        :waitForStream="true"
                                        :showControls="true"
                                        ref="videoplayer"
                                        
                                        :inPreviewMode="false">
                    </hls-video-player>
                </div>
                <div v-else
                    class="media-container media-video-container">
                    <div class="blur-background"
                            :style="`background-image: url('${editProfile.PrimaryMedia.ThumbnailUri }');`">
                    </div>
                    <div class="media-cover-container"
                            @click="onUploadCoverPhoto">
                        <img-g class="responsive" 
                               v-if="editProfile.PrimaryMedia.ThumbnailUri"
                                :src="editProfile.PrimaryMedia.ThumbnailUri" />
                        <div v-else class="blank-image icomoon" @click="requestAddPhoto">
                            
                        </div>
                    </div>
                </div>
            </div> -->

            <div class="profile-header">
                <div class="profile-photo-container">
                    <div class="img-changer">
                        <img :src="photo"
                            v-if="photo"
                            @click="requestAddPhoto"
                            class="responsive profile-photo">
                        <div v-else class="blank-image icomoon" @click="requestAddPhoto">
                            
                        </div>
                    </div>
                    <label for="doNotContact"

                        class="check-label">
                        <input type="checkbox"
                        :disabled="submitting"
                        class="check"
                        id="doNotContact"
                        v-model="editProfile.DoNotContact">   
                        Hide my Profile
                    </label>
                    <label for="doNotContact"

                        class="check-label">
                        <input type="checkbox"
                        :disabled="submitting"
                        class="check"
                        id="doNotContact"
                        v-model="editProfile.ContactOptOut">   
                        Unsubscribe
                    </label>                
                </div>            

                <div class="form-profile-edit">
                    <div class="form-control">
                        <label>First Name</label>
                        <input :disabled="submitting" 
                            required
                            v-model="editProfile.FirstName"
                            aria-label="First Name">    

                    </div>

                    <div class="form-control">
                        <label>Last Name</label>
                        <input :disabled="submitting" 
                            required
                            v-model="editProfile.LastName"
                            aria-label="Last Name">    

                    </div>
                    
                    <div class="form-control">

                        <label>Display Name</label>
                        <input :disabled="submitting" 
                            required
                            v-model="editProfile.DisplayName"
                            aria-label="Display Name">    
                    </div>

                    <div class="form-control">

                        <label>Title</label>
                        <input :disabled="submitting" 
                            required
                            v-model="editProfile.ContactInfo.Title"
                            aria-label="Title">    
                    </div>

                    <div class="form-control">
                        <label>Company Name</label>
                        <input :disabled="submitting" 
                            required
                            v-model="editProfile.ContactInfo.SubTitle"
                            aria-label="Company Name">    

                    </div>

                    <div class="form-control">
                        <label>Biography</label>
                        <textarea v-model="editProfile.ContactInfo.Bio"
                                rows="5"
                                :disabled="submitting"
                                aria-label="Biography"
                                required></textarea>

                    </div>

                    <div class="form-control">
                        <label>Contact E-Mail (Not Public)</label>
                        <input :disabled="submitting" 
                            required
                            v-model="editProfile.PrimaryEmail"
                            aria-label="Contact E-Mail Address (Not Public)">    

                        <small>
                            This is used to send you e-mails from the organizers about this event. This e-mail 
                            will <strong>not</strong> be shared with anyone without your explicit permission.    
                        </small>

                    </div>


                    
                    <div class="form-control">
                        <label>SMS Number (Not Public)</label>

                        <div class="phone-input">
                            <input :disabled="submitting" 
                                    required
                                    v-model="proposedPhone"
                                    ref="phoneInput"
                                    type="tel"
                                    aria-label="SMS Number (Not Public)">    
                            <button @click="verifyNumber(false)">
                                Verify
                            </button>
                        </div>

                        <small>
                            This is used to send you SMS reminders and login links if requested by you. This phone 
                            number will <strong>not</strong> be shared with anyone without your explicit permission.
                        </small>
                    </div>



                    <template v-if="selectableCategories.length > 0">
                        <label class="title my-categories-title">
                            My Categories
                        </label>

                        <div class="input-control">
                            <div v-for="category in selectableCategories"
                                :key="category.Code"
                                class="info-link"
                                @click="toggleCategory(category)"
                                :class="{'active': editProfile.Categories.find(x => x.Code == category.Code)}">
                                <img-g v-if="category.IconUrl"
                                       class="square-image"
                                    :src="category.IconUrl">
                                </img-g>
                                <div class="info-details">
                                    <div class="info-link-title">
                                        <span class="icomoon-active">
                                            
                                        </span>
                                        {{category.Name}}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </template>
                </div>
            </div>

            <div class="edit-fields">

                <div v-for="(info, idx) in editProfile.ContactInfo.ContactInfo"
                     class="contact-info-editor-row">
                    <button class="round-button" 
                        @click="removeContactInfo(idx)">
                        <span class="icomoon"></span>
                    </button>
                    <div>
                        <div class="form-control">
                            <input :disabled="submitting" 
                                   required
                                   v-model="info.Value"
                                   :aria-label="info.Type"
                                   @change="onUpdateDisplayText(info)">

                            <label>{{info.Type}}</label>
                        </div>
                    </div>
                    <div>
                        <div class="form-control">
                            <input :disabled="submitting" 
                                   required
                                   v-model="info.DisplayText"
                                   aria-label="Link Label">

                            <label>Link Label</label>
                        </div>
                    </div>
                </div>

                <div class="link-actions">
                    <button :disabled="submitting" @click="addContactInfo('Website')">
                        <span class="icomoon"></span><span>Add Website</span>
                    </button>

                    <button :disabled="submitting" @click="addContactInfo('Email')">
                        <span class="icomoon"></span><span>Add E-Mail</span>
                    </button>

                    <button :disabled="submitting" @click="saveProfile('Email')">
                        <span class="icomoon"></span><span>Save</span>
                    </button>
                </div>                

                <hr>

                <div class="link-actions">
                    <router-link :to="{name: 'ProfileViewsAttendeeStandalone'}"
                                 class="btn">
                        <span class="icomoon"></span><span>My Visitors</span>
                    </router-link>
                    <button @click="sendLoginToMobile"
                            :disabled="!editProfile.PrimaryPhone">
                        <span class="icomoon"></span><span>Send Login via SMS</span>
                    </button>
                    <router-link class="btn" :to="{name: 'MyLoginBadge'}">
                        <span>Show Mobile Login QR Code</span>
                    </router-link>
                    <button @click="signOut">
                        <span class="icomoon"></span><span>Sign Out</span>
                    </button>
                </div>
            </div>
            <small class="version-number">Tractus Passport</small>

            <file-upload ref="uploader" 
                        style="display: none;"
                        :acceptFileType="'.jpg'"
                        :assetType="'profile'"
                        v-if="editProfile"
                        :showCode="editProfile.Show.Code"
                        @uploadSuccess="onFileUploaded"
                        @uploadFailed="onUploadFailed"
                        @uploadStarted="onUploadStarted"  />            
            <file-upload ref="uploaderCover"
                        v-if="editProfile"
                        style="display: none;"
                        :assetType="`profile_cover_${this.editProfile.Code}`"
                        :showCode="editProfile.Show.Code"
                        @uploadSuccess="onFileUploadedCover"
                        @uploadFailed="onUploadFailed"
                        @uploadStarted="onUploadStarted" />
        </div>
        <div class="upload-page-modal-blocker"
                v-if="uploadInProgress">
            <h1>
                Uploading file, please wait...
            </h1>
            <loading-container />
        </div>

    </div>
</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import { nextTick } from 'vue'
import intlTelInput from 'intl-tel-input';

export default {
    mixins: [
        Token,
        Common
    ],   

    computed: {
        selectableCategories() {
            if(!this.attendee) {
                return [];
            }

            return AppState.show.AttendeeCategories.filter(x => 
                x.CanAttendeeChoose);
        }
    },

    methods: {
        async verifyNumber(skipSuccess) {
            if(!this.phoneInput.isValidNumber()) {
                console.error(this.phoneInput.getValidationError())
                this.$awn.alert("Phone number is not valid. Please try a different number.")
                return false;
            }

            let number = this.phoneInput.getNumber();
            this.editProfile.PrimaryPhone = number;

            if(!skipSuccess) {
                this.$awn.success("Verified phone number.");
            }
            return true;
        },

        async sendLoginToMobile() {
            try {
                let result = await this.tryPut(`/api/attendeebadge/send/sms?p=${encodeURIComponent(this.editProfile.PrimaryPhone)}`);

                if(result.Errors.length == 0) {
                    this.$awn.success(`Login link sent to ${this.editProfile.PrimaryPhone}.`);
                } else {
                    console.log(result);
                    throw "Fail";
                }
            } catch {
                this.$awn.alert("Could not send login link, please try again later.");
            }
        },


        toggleCategory(category) {
            console.log(this.editProfile.Categories)
            let existingCategory = this.editProfile.Categories.find(x => x.Code == category.Code);

            if(!existingCategory) {
                this.editProfile.Categories.push(category);
            } else {
                let index = this.editProfile.Categories.indexOf(existingCategory);

                if(index != -1) {
                    this.editProfile.Categories.splice(index, 1);
                }
            }
        },

        onUploadCoverPhoto() {
            this.$refs.uploaderCover.requestChooseFile();
        },

        onUploadFailed() {
            this.uploadInProgress = false;
            this.$awn.alert('Could not upload your file. Try again later.');
        },

        onUploadStarted() {
            this.uploadInProgress = true;
        },

        onFileUploadedCover(file) {
            this.uploadInProgress = false;
            this.editProfile.PrimaryMedia.ThumbnailUri = file;
        },        

        onFileUploaded(file) {
            var fileName = file.toLowerCase();

            if(fileName.endsWith(".jpg")
                || fileName.endsWith(".png")
                || fileName.endsWith(".jpeg")
                || fileName.endsWith(".jfif")
                || fileName.endsWith(".gif")
                || fileName.endsWith(".jif")
                || fileName.endsWith(".jpe")) {
                this.changePhoto(file);
            }

            this.uploadInProgress = false;
        },

        onUpdateDisplayText(info) {
            if(!info.DisplayText) {
                info.DisplayText = info.Value;
            }
        },

        changePhoto(toAdd){
            this.$nextTick(() => {
                this.photo = toAdd;
                this.editProfile.ContactInfo.PhotoUrl = toAdd;
            });
        },

        requestAddPhoto() {
            this.$refs.uploader.requestChooseFile();
        },

        removeContactInfo(idx) {
            this.editProfile.ContactInfo.ContactInfo.splice(idx, 1);
        },

        addContactInfo(type) {
            let defaultValue = "https://";

            if(type == 'Email' && this.editProfile.PrimaryEmail) {
                defaultValue = this.editProfile.PrimaryEmail;
            }
            this.editProfile.ContactInfo.ContactInfo.push({
                "Type": type, 
                "Value": defaultValue, 
                "DisplayText": type == 'Email' ? defaultValue : '',
                "Details": ""
            });
        }, 

        async loadProfile() {
            this.submitting = true;

            try{
                let r = await this.tryGet('/api/app/me');

                if(r.Errors.length > 0) {
                    this.errorMessage = r.Errors[0];
                } else {
                    let attendee = r.Result.Attendee;

                    console.log(attendee);
                    this.socialMediaLinks = r.Result.SocialMediaLinks;
                    if(attendee.ContactInfo && attendee.ContactInfo.ContactInfo) {
                        for(var i = 0; i < attendee.ContactInfo.ContactInfo.length; i++) {
                            var item = attendee.ContactInfo.ContactInfo[i];

                            if(!item.DisplayText) {
                                item.DisplayText = item.Value;
                            }
                        }
                    }

                    if(attendee.ContactInfo && attendee.ContactInfo.PhotoUrl) {
                        this.photo = attendee.ContactInfo.PhotoUrl;
                    }

                    if(this.photo == '/Content/person.png') {
                        this.photo = '';
                    }

                    if(!attendee.ContactInfo.Title) {
                        attendee.ContactInfo.Title = '';
                    }

                    if(!attendee.ContactInfo.SubTitle) {
                        attendee.ContactInfo.SubTitle = '';
                    }

                    if(!attendee.PrimaryMedia) {
                        attendee.PrimaryMedia = {
                             ThumbnailUri: null,
                             Code: null,
                             Uri: null,
                             MediaType: null,
                             Title: null,
                             Description: null,
                             PlayAfterExit: null,
                             AutoPlay: false
                        };
                    }

                    this.editProfile = attendee;
                    this.proposedPhone = attendee.PrimaryPhone || "";
                    this.postAnalytics(this.editProfile.Code,'EditProfile',null,null,this.editProfile.Show.Code);
                }

                this.submitting = false;

            }
            catch(err){
                this.errorMessage = "We could not load your info. Please try again later"; 
                this.submitting = false;
            }

            nextTick(() => {
                let phoneInput = this.$refs.phoneInput;

                this.phoneInput = intlTelInput(phoneInput, {
                    utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js"
                });
            });
        },

        async saveProfile() {
            if(this.proposedPhone) {
                let verifyNumberResult = await this.verifyNumber(true);
                if(!verifyNumberResult) {
                    this.$awn.alert("Could not save profile.");
                    return;
                }
            } else {
                this.editProfile.PrimaryPhone = '';
            }

            this.submitting = true;

            this.postAnalytics(this.editProfile.Code,'SaveProfile',null,null,this.editProfile.Show.Code);

            this.editProfile.ContactInfo.PhotoUrl = this.photo;
            
            let toPost = {
                Attendee:this.editProfile,
                SocialMediaLinks:this.socialMediaLinks
            };

            try {
                let r = await this.tryPost(`/api/app/me`, JSON.stringify(toPost), 'application/json');
                if(r.Errors.length > 0) {
                    this.errorMessage = r.Errors[0];
                    this.submitting = false;
                    return;
                } 

                window.$bus.$emit('updatedprofilephoto', this.photo);
                this.$awn.success("Your profile has been updated.");
                this.loadProfile();
            } catch (r) {
                this.errorMessage = "We couldn't save your details, please try again later"; 
                this.submitting = false;
            }
        },

        signOut() {
            this.postAnalytics(this.editProfile.Code,'LogOut',null,null,this.editProfile.Show.Code);
            this.logOut();
        },        
    },

    data() {
        return {
            uploadInProgress: false,
            editProfile: null,
            socialMediaLinks: [],
            photo: '',

            proposedPhone: '',

            recordIntroVideo: false,
            mediaRecorder: null,
            recording: false,
            chunks: [],
            stream: null,

            cameras: [],
            cameraId: null,
            mics: [],
            micId: null,

            phoneInput: null,
        }
    },

    created: function() {
        window.addPhotoToDiscussion = this.changePhoto;
        window.addPhoto = this.changePhoto;
        this.loadProfile();
    },
}
</script>