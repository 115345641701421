<template>
    <div class="page-container chat-room-list">
        <vue-title :title="'Chat Rooms'" :brand="true"></vue-title>
        <div class='item-list chat-list' :class="[{'thin-list': chatSelected}]" v-if="!submitting">
            <div class="header-actions">
                <span class="title">
                    Chat Rooms
                </span>
                <div class="header-buttons">
                </div>
            </div>
            <div class="list-page-contents">
                <chat-room-item :discussion="discussion"
                                :class="[$route.params && $route.params.discussionCode && $route.params.discussionCode == discussion.Discussion.Code ? 'list-item-selected' : '']"
                                 v-for="discussion in discussions"
                                :key="discussion.Discussion.Code">

                </chat-room-item>
            </div>
        </div>
        <loading-container v-else
                           class="item-list thin-list" />

        <router-view></router-view>

    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'

import { AppState } from './appstate';

export default {
    props: ['code'],
    mixins: [
        Token,
        Common
    ],   

    computed: {
        chatSelected() {
            return this.$route.name == 'DiscussionInternalChat';
        }
    },

    methods: {
        onSelectItem(discussion) {
            this.$router.push({name: 'DiscussionInternalChat', params:{discussionCode: discussion.Code}});
        },

        async reloadDiscussions(setSubmitFlag) {

            if(setSubmitFlag) {
                this.submitting = true;
            }
            
            try {
                let r = await this.tryGet(`/api/discussions?type=GroupChat`);

                if(r.Errors.length > 0) {
                    this.errorMessage = r.Errors[0];
                } else {
                    this.discussions = r.Result;

                    this.postAnalytics(AppState.attendeeCode,'ChatRooms',null,"ChatRooms",AppState.showCode);
                }

                this.submitting = false;
            } catch {
                this.errorMessage = "We could not load your info. Please try again later"; 
            }

            this.submitting = false;
        },


        onDiscussionUpdated(discussion) {
            if(!discussion.TypeCode) {
                return;
            }

            this.reloadDiscussions(false);
        },
        
        onRemovedFromDiscussion() {
            this.reloadDiscussions(false);
        }
    },

    data() {
        return {
            discussions: [],
            HACK_flagToggle: false,
            showChooser: false,
        }
    },

    created: function() {
        this.reloadDiscussions(true);
        window.$bus.$on('Discussion-Name-Change', this.onDiscussionUpdated);
        window.$bus.$on('removed-from-discussion', this.onRemovedFromDiscussion);
        window.$bus.$on('added-to-discussion', this.onRemovedFromDiscussion);
    },

    beforeUnmount() {
        window.$bus.$off('removed-from-discussion', this.onRemovedFromDiscussion);
        window.$bus.$off('Discussion-Name-Change', this.onDiscussionUpdated);
        window.$bus.$off('added-to-discussion', this.onRemovedFromDiscussion);
    }
}
</script>