<template>
    <router-link class="attendee-list-item list-item"
                 :to="{name: destinationRouteNameDetails, params: {code: attendee.Code}}">

        <div>
            <img-g :src="attendee.ContactInfo.PhotoUrl"
                   fallbackSrc="/img/person.png" />
            
            <div>
                <span class="title">
                    <div class="online-icon" v-if="isOnline(attendee.Code)"></div>
                    {{attendee.DisplayName}}
                </span>
                <span class="subtitle contact-title">
                    {{attendee.ContactInfo.Title}}
                </span>
                <span class="subtitle" v-if="attendee.ContactInfo.SubTitle">
                    {{attendee.ContactInfo.SubTitle}}
                </span>
                <div v-if="displayCategories && filteredCategories && filteredCategories.length > 0"
                     class="category-container">
                    <div v-for="c in categories" 
                         class="pill" 
                         :class="`A-${c.Code}-Pill`">
                        {{c.Name}}
                    </div>
                </div>
            </div>
        </div>
        <div v-if="myCode != attendee.Code"
             class="attendee-card-actions">
            <button class="button-outline icomoon round-button"
                    @click.stop.prevent="emitFavoriteClick">
                {{isFavorite ? '' : ''}}
            </button>
            <router-link :to="{name: destinationRouteNameChat, query: { attendeeCode: true }, params: { discussionCode: attendee.Code }}"
                            class="button-outline btn icomoon round-button"></router-link>
        </div>
    </router-link>
</template>
<script>
import Common from "../common";
import { AppState } from "../appstate";

export default {
    mixins: [Common],
    props: [
        'attendee', 
        'onlineAttendeeCodes', 
        'show', 
        'displayCategories', 
        'filteredCategories',
        'myCode',
        'isFavorite',
    ],

    computed: {
        categories() {
            if(!this.filteredCategories || !this.displayCategories) {
                return [];
            }

            if(!this.attendee.Categories) {
                return [];
            }

            try {
                let filter = this.filteredCategories.filter(x => this.attendee.Categories.find(y => y.Code == x.Code));

                return filter;
            } catch {
                return [];
            }
        },

        destinationRouteNameDetails() {
            let toReturn = this.$route.name && this.$route.name.indexOf("AttendeeList") != -1
                ? 'attendeeDetailsAttendeeList'
                : 'attendeeDetailsNetwork';

            return toReturn;
        },

        destinationRouteNameChat() {
            let toReturn = this.$route.name && this.$route.name.indexOf("AttendeeList") != -1
                ? 'DiscussionForAttendeeAttendeeList'
                : 'DiscussionForAttendeeNetworkList';

            return toReturn;
        }

    },

    methods: {
        emitFavoriteClick() {
            window.$bus.$emit('request-toggle-favorite', {
                Type: "ATTENDEE",
                Code: this.attendee.Code,
                ReadOnly: false,
                AllowEditEntity: false,
                Active: !this.isFavorite
            });

            return false;
        }    
    }
}
</script>