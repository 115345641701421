<template>
    <div ref="output">

    </div>
</template>
<script>
import linkifyStr from 'linkifyjs/string';
const linkifyOptions = {
    target: '_blank'
};

export default {
    props: ['input'],
    watch: {
        input() {
            this.renderInput();
        }
    },

    methods: {
        renderInput() {
            let output = this.$refs.output;

            output.innerHTML = linkifyStr(this.input || '', linkifyOptions);
        },
    },

    mounted() {
        this.renderInput();
    }
}
</script>