<template>
    <div class="discussion-area"
        v-if="discussion && !submitting">
        <div class="discussion-pane" 
             :class="[discussion ? `discussion-${discussion.TypeCode.toLowerCase()}` : '']"
             ref="discussionpane">

            <vue-title v-if="!doNotModifyTitle && !isInline && discussion && !code" :title="roomTitle" :brand="true"></vue-title>
            <vue-title v-else-if="!doNotModifyTitle && !isInline && !discussion && !code" :title="'Loading...'" :brand="true"></vue-title>

            <div class="header-actions">
                <div class="title"
                     :title="discussion ? roomTitle : 'Connecting...'">
                    
                    <div v-if="showUserOnlineIndicator"
                         class="online-icon">

                    </div>
                    {{discussion ? roomTitle : "Connecting..." }}
                </div>

                <div class="header-buttons">
                    <button @click="showParticipants = !showParticipants; showQaModerate = false" 
                            v-if="discussion && showParticipantButton"
                            title="Members">
                        <span class="icomoon"></span>
                        <span v-if="participants.length > 1">{{participants.length}}</span>
                    </button>

                    <button @click="showQaModerate = !showQaModerate; showParticipants = false"
                            v-if="discussion && isUserAdministrator && showQaTools"
                            class="icomoon">
                        
                    </button>

                    <button @click="emitExitRequest"
                        v-if="isInline"
                        class="icomoon">
                                                
                    </button>
                </div>
            </div>
            <posts-list :postsToShow="posts"
                        :discussion="discussion"
                        :replyingToPost="replyingToPost"
                        @edit-post="onEditPostRequested"
                        @delete-post="onDeletePostRequested"
                        @click-post="onPostClick"
                        @reply-to-post="onReplyToPost"
                        @toggle-post-like="onTogglePostLike"
                        :areMembersTyping="areMembersTyping"
                        :participants="participants"
                        ref="postList">
            </posts-list>

            <div v-if="showParticipants"
                 class="discussion-participant-list">

                <template v-if="canUserEditChatRoom">

                
                    <div class="header-actions subheader">
                        <div class="title">
                            Customize Chat
                        </div>
                        <div class="header-buttons">
                        </div>                        
                    </div>

                    <div class="form-editor" 
                         v-if="canUserRenameRoom">
                        <h1>Change Chat Name</h1>
                        <input v-model="editName"
                            placeholder="Room Name"
                            @change="onChatNameChange">
                    </div>

                    <div class="form-actions">
                        <!-- <a class="action-link"
                        :class="{'active': subscribedToNotifications}"
                        @click="updateNotificationSubscription">
                            <span class="icomoon">
                                
                            </span>
                            <span v-if="subscribedToNotifications">
                                Mute Notifications
                            </span>
                            <span v-else>
                                Unmute Notifications
                            </span>
                        </a> -->
                        <a class="action-link active"
                            v-if="canInviteAttendees"
                            @click="showInviteAttendees = true">
                            <span class="icomoon">
                                
                            </span>
                            <span>
                                Invite...
                            </span>
                        </a>

                        <a class="action-link active"
                            @click="askLeaveChat"
                            v-if="canUserLeaveChatRoom">
                            <span class="icomoon">
                                
                            </span>
                            <span>
                                Leave Chat
                            </span>
                        </a>
                    </div>
                </template>

                <div v-if="discussion.TypeCode == 'Chat'"
                     class="form-editor">
                </div>


                <slot name="speaker-list">

                </slot>

                <div class="header-actions subheader">
                    <div class="title">
                        Connected ({{participants.length}})
                    </div>
                    <div class="header-buttons">
                    </div>                        
                </div>

                <div v-for="participant in participantList"
                     :key="participant.Code"
                     class="participant-card"
                     @click="$emit('participantclick', participant)">
                    <img-g :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${participant.Code}/profile`"
                           fallbackSrc="/img/person.png"
                           :noFade="true" />                     
                    <span v-if="emotions && emotions[participant.Code] == 'hand'"
                            class="icomoon">
                        
                    </span>
                    <span>
                        {{participant.DisplayName}}
                    </span>
                </div>
            </div>

            <div v-show="showQaModerate"
                 class="discussion-content-overlay">
                <qa-management :code="discussion.Code"
                               :attendee="attendee"
                               :posts="posts"
                               v-if="isUserAdministrator">

                </qa-management>
            </div>

            <div class="post-container">
                <ul class="image-preview" v-if="photosForPost.length > 0 || linkToPost">
                    <li v-if="linkToPost"
                        @click="linkToPost = null"
                        class="file-placeholder">
                        <span class="icomoon"></span>
                        <span>
                            {{getShortName(linkToPost)}}
                        </span>
                        <span class="icomoon remove-icon"></span>
                    </li>
                    <li v-for="(image, imageIndex) in photosForPost"
                        v-bind:key="imageIndex"
                        @click="removePhotoFromPost(imageIndex)">
                        <img :src="image">
                        <span class="icomoon remove-icon"></span>
                    </li>
                </ul>
                <div v-if="showEmojiPicker" 
                    class="emoji-picker" 
                    v-click-outside="onClickOutsideEmojiPicker">
                    <div class="emoji-group" 
                         v-for="emojiGroup in emojis"
                         :key="emojiGroup.Name">
                        <span class="subtitle">
                            {{emojiGroup.Name}}
                        </span>
                        <div class="emojis">
                            <button v-for="item in emojiGroup.Items" 
                                    :key="item" 
                                    @click="addEmojiToChat(item)">
                                {{item}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="post-container-controls"
                     v-if="canUserPost">
                    <div class="post-editor">
                        <div class="left-button-stack">
                            <button data-button-name="showEmojiPicker" 
                                    class="round-button button-icon"
                                    :disabled="!canUserPost"
                                    @click="showEmojiPicker=true;">
                                🙂
                            </button>
                            <button @click="onUploadFile('Attachment')"
                                    :disabled="!canUserPost"
                                    class="icomoon round-button button-icon">
                                
                            </button>
                        </div>
                        <textarea v-model="postBody"
                                    ref="postBox"
                                    :disabled="!canUserPost"
                                    :placeholder="postBoxPlaceholder"
                                    v-debounce:3000="onTypingStopped"   
                                    @keydown="onKeyUp">

                        </textarea>
                        <button @click="onPost" 
                                :disabled="!canUserPost"
                                class="icomoon round-button button-icon"
                                :class="[{'disable-button': isPosting}]">
                            
                        </button>
                    </div>
                    <div class="post-buttons" v-if="discussion.AllowAnonymous">
                        <div class="anon-post">
                            <label>
                                <input type="checkbox"
                                       :disabled="!canUserPost"
                                       v-model="postAsAnonymous">
                                Post Anonymously
                            </label>
                        </div>
                    </div>
                </div>
                <div class="post-container-controls post-disabled-warning"
                     v-else>
                    You are not allowed to post in this chat.
                </div>
            </div>
        </div>
        <div class="modal-moderator"
            v-if="showModerateBox">

            <div class="modal-container">
                <div class="header-actions">
                    <div class="title">Edit Post</div>
                </div>
                <textarea v-model="postModerating.Body"/>

                <div class="modal-buttons">
                    <button @click="cancelApprovePost">
                        Cancel
                    </button>
                    <button @click="approvePost">
                        Save
                    </button>
                </div>
            </div>
        </div>

        <file-upload ref="uploader"
                    style="display: none;"
                    :assetType="'discussionpost'"
                    v-if="discussion"
                    :showCode="discussion.ShowCode"
                    @uploadSuccess="onFileUploaded" />

        <attendee-chooser v-on:cancelled="showInviteAttendees = false" 
                          v-on:selected="onAttendeesChosen"
                          :showChooser="showInviteAttendees"
                          :selectedCodes="participantCodes"
                          :selectedCodesReadOnly="true"
                          :attendee="attendee"
                          :confirmLabel="'Invite'"
                          ref="chooseAttendees"
                          :title="'Invite...'">

        </attendee-chooser>

    </div>

    <div class="fixed-content-width" v-else>
        <loading-container />
    </div>
</template>
<script>

import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import DiscussionPost from '../models/DiscussionPost'
import { debounce } from 'vue-debounce'

import { AppState } from './appstate'

const SPLICE_MODE = "Splice";
const APPEND_MODE = "Append";

const APPROVALCODES = {
    APPROVED: "Approved",
    PENDING: "Pending",
    DECLINED: "Declined"
}

export default {
    props: [
        'attendee',
        'typeCode',
        'code', 
        'discussionCode', 
        
        'isInline', 
        'doNotModifyTitle',
        'showQaTools',
        'emotions'
    ],
    mixins: [
        Token,
        Common
    ],

    watch: {
        $route(to, from) {
        },

        discussionCode(to, from) {
            if(this.returnToMainRoomTimeout) {
                clearTimeout(this.returnToMainRoomTimeout);
            }
            this.switchDiscussion(to, from);
        },
    },

    computed: {
        showCode() {
            return AppState.showCode;
        },

        postBoxPlaceholder() {
            if(!this.canUserPost) {
                return 'Posting disabled by moderators.';
            }

            return this.replyingToPost
                ? `Replying to ${this.replyingToPost.DisplayName}...`
                : "Write a message...";
        },

        participantList() {
            let toReturn = this.participants;

            if(!this.emotions) {
                return toReturn;
            }

            let keys = Object.keys(this.emotions);

            if(keys.length == 0) {
                return toReturn;
            }

            let result = toReturn.sort((a, b) => {
                if(this.emotions[a.Code] && !this.emotions[b.Code]) {
                    return -1;
                } 

                if(!this.emotions[a.Code] && this.emotions[b.Code]) {
                    return 1;
                }

                return a.DisplayName > b.DisplayName
                    ? 1 : -1;
            });

            return result;
        },

        areMembersTyping() {
            return Object.keys(this.membersTyping).length;
        },

        isUserAdministrator() {
            let isUserAdministrator = AppState.attendee.RoleCode == "Administrator";
            let isUserAdminForChat = 
                this.discussion.ParticipantRoles &&
                this.discussion.ParticipantRoles[AppState.attendee.Code] == 'Administrator';

            return isUserAdminForChat || isUserAdministrator;
        },

        roomTitle() {
            return this.discussionName;
        },

        showUserOnlineIndicator() {
            return Object.keys(this.discussion.ParticipantRoles).length == 2
                && this.participants.length > 1
                && this.discussion.TypeCode == 'Chat';
        },

        canUserEditChatRoom() {
            return this.discussion.TypeCode == 'Chat';
        },

        canUserRenameRoom() {
            return this.discussion.TypeCode == 'Chat'
                && Object.keys(this.discussion.ParticipantRoles).length > 2;
        },

        canUserLeaveChatRoom() {
            return this.discussion.TypeCode == 'Chat' 
                && Object.keys(this.discussion.ParticipantRoles).length > 2;
        },

        showParticipantButton() {
            return true;
        },

        canInviteAttendees() {
            if(AppState.attendee.RoleCode == 'Administrator'
                && this.discussion.TypeCode == 'Chat') {
                return true;
            }

            let permissions = AppState.attendee.DiscussionPermissions;

            if(AppState.attendee.Categories.length == 0
                || permissions.length == 0) {
                return false;
            }

            for(let i = 0; i < AppState.attendee.Categories.length; i++) {
                let category = AppState.attendee.Categories[i];

                let permission = permissions.find(x => x.CategoryCode == category.Code);

                if(!permission) {
                    return false;
                }

                if(!permission.CanInviteOthers) {
                    return false;
                }
            }

            return true;
        },

        canUserPost() {
            return this.discussion.TypeCode == 'Chat'
                || this.isUserAdministrator
                || this.discussion.AllowPost;
        },

        participantCodes() {
            return Object.keys(this.discussion.ParticipantRoles);
        }
    },

    methods: {
        async onTogglePostLike(post) {
            if(!post.Likes) {
                post.Likes = [];
            }

            let indexOfLike = post.Likes.indexOf(AppState.attendee.Code);

            if(indexOfLike == -1) {
                post.Likes.push(AppState.attendee.Code);
            } else {

                while(indexOfLike != -1) {
                    post.Likes.splice(indexOfLike, 1);
                    indexOfLike = post.Likes.indexOf(AppState.attendee.Code);
                }
            }

            await this.tryPost('/api/discussion/post',
                                JSON.stringify(post), 
                                'application/json');
        },

        onReplyToPost(post) {
            if(this.replyingToPost && post == this.replyingToPost) {
                this.replyingToPost = null;
                return;
            }

            this.replyingToPost = post;
        },

        onPostClick(post) {
            this.$emit('post-clicked', post);
        },

        async updateNotificationSubscription() {
            this.subscribedToNotifications = !this.subscribedToNotifications;
        },

        async onAttendeesChosen(attendees) {
            try {
                await this.tryPost(`/api/discussion/${AppState.attendee.Show.Code}/${this.discussion.Code}/addusers`, JSON.stringify(attendees), 'application/json');

                for(let i = 0; i < attendees.length; i++) {
                    this.discussion.ParticipantRoles[attendees[i]] = 'Administrator';
                }

            } catch {
                this.$awn.alert("Could not invite user(s) to this chat. Try again later.");
            }



            this.showInviteAttendees = false;

        },

        async askLeaveChat() {
            let promise = new Promise(r => {
                this.$awn.confirm(
                    "Are you sure you want to leave this chat? You cannot rejoin it unless someone adds you again.",
                    () => {
                        r('Yes');
                    },
                    () => {
                        r('No');
                    },
                    {
                        labels: {
                            confirm: 'Yes',
                            cancel: 'No'
                        }
                    }
                )
            });

            let result = await promise;

            let shouldGoLive = result == 'Yes';

            if(!shouldGoLive) {
                return false;
            }

            let deleteResult = await this.tryDelete(
                `/api/discussion/${this.discussion.Code}/leave`);

            if(this.isInline) {
                this.emitExitRequest();  
            } else {
                this.$router.go(-1);
            }
        },

        async onEditPostRequested(post) {
            if(post.AttendeeCode == AppState.attendee.Code) {
                this.beginApprovePost(post);
            } else {
                this.beginApprovePost(post);
            }
        },

        async onDeletePostRequested(post) {
            let promise = new Promise(r => {
                this.$awn.confirm(
                    "Are you sure you want to delete this post? This cannot be undone.",
                    () => {
                        r('Yes');
                    },
                    () => {
                        r('No');
                    },
                    {
                        labels: {
                            confirm: 'Yes, Delete Post',
                            cancel: 'No'
                        }
                    }
                )
            });

            let result = await promise;

            let shouldGoLive = result == 'Yes';

            if(!shouldGoLive) {
                return false;
            }

            let deleteResult = await this.tryDelete(
                `/api/discussion/${this.discussion.Code}/post/${post.TimeStamp}`);
        },

        onPostDeleted(message) {
            if(message.AllPosts) {
                this.posts = [];
                this.postModerating = null;
                this.showModerateBox = false;
                return;
            }

            let postCode = message.PostCode;


            let post = this.posts.find(x => x.TimeStamp == postCode);
            if(post) {
                this.posts.splice(this.posts.indexOf(post), 1);
            }

        },

        onClickOutsideEmojiPicker(e){
            if(e
                && e.target 
                && e.target.dataset.buttonName == 'showEmojiPicker') {
                return;
            }

            this.showEmojiPicker = false;
        
        },
        addEmojiToChat(item){
            if(this.postBody){
                this.postBody += item;
            }
            else{
                this.postBody = item;
            }
            
            this.showEmojiPicker = false;
        },
        showMemberPane() {
            this.showParticipants = true;
        },

        forceRefreshSize() {
            this.$refs.postList.bringLatestMessageIntoView();
        },

        emitExitRequest() {
            this.$emit('closerequest', this.discussion.Code);
        },

        emitMaximizeRequest() {
            this.$router.push({name: 'DiscussionInternal', params:{discussionCode: this.discussionCode}});
            this.$emit('closerequest');
        },

        emitMinimizeRequest() {
            window.$bus.$emit('selectedchat', this.discussionCode);
        },

        getShortName(link) {
            let linkSplit = link.split('/');
            return linkSplit[linkSplit.length -1];
        },

        onUploadFile(type) {
            if(type == "Attachment"
                && this.linkToPost) {
                return;
            }
            this.$refs.uploader.requestChooseFile();
        },

        onFileUploaded(file) {
            var fileName = file;

            if(fileName.endsWith(".jpg")
                || fileName.endsWith(".png")
                || fileName.endsWith(".jpeg")
                || fileName.endsWith(".jfif")
                || fileName.endsWith(".gif")
                || fileName.endsWith(".jif")
                || fileName.endsWith(".jpe")) {
                this.photosForPost.push(file);
            } else {
                this.linkToPost = file;
            }
        },

        removePhotoFromPost(imageIndex) {
            if(this.isPosting) {
                return;
            }

            this.photosForPost.splice(imageIndex, 1);
        },

        onAddPhoto() {
            if(this.isPosting) {
                return;
            }

            this.onUploadFile('Image');
        },

        onKeyUp(e) {
            if(e.keyCode === 13
                && !e.shiftKey) {
                e.preventDefault();
                this.onPost();
                return;
            }

            if(!this.lastTypingIndicatorSent) {
                this.lastTypingIndicatorSent = new Date();
                this.sendTypingMessage("started");
            }
        },

        onTypingStopped() {
            if(!this.lastTypingIndicatorSent) {
                return;
            }

            this.lastTypingIndicatorSent = null;
            this.sendTypingMessage("stopped");
        },

        async sendTypingMessage(state) {
            if(!this.discussion.AllowTypingIndicator) {
                return;
            }

            window.$signalRConnection.invoke('ControlChannelMessage', {
                ShowCode: AppState.attendee.Show.Code,
                FromCode: AppState.attendee.Code,
                TypeCode: 'TypingIndicator',
                DID: this.getDeviceId(),
                DiscussionAreaCode: this.discussion.Code,
                Message: {
                    State: state,
                    Code: AppState.attendee.Code
                },
            });            
        },
        
        onTypingStateReceived(discussionCode, details) {
            if(discussionCode != this.discussion.Code
                || details.Code == AppState.attendee.Code) {
                return;
            }

            if(details.State == 'started') {
                this.membersTyping[details.Code] = new Date();
            } else if (details.State == 'stopped') {
                this.membersTyping[details.Code] = null;
            }
        },

        onCleanupWatchdog() {
            let now = new Date();
            for(const key in this.membersTyping) {
                let timeValue = this.membersTyping[key];
                if(!timeValue) {
                    continue;
                }

                let diff = now - timeValue;
                
                if(diff > 10000) {
                    this.membersTyping[key] = null;
                }
            }  
        },

        async onPost() {
            if(this.isPosting) {
                return;
            }

            if((!this.postBody && this.photosForPost.length < 1) 
                || this.isPosting){

                let hasPhotos = this.photosForPost && this.photosForPost.length > 0;
                let hasAttachments = this.linkToPost;

                if(!hasPhotos && !hasAttachments) {
                    return;
                }
            }

            this.sendTypingMessage("stopped");
            this.lastTypingIndicatorSent = null;
            var discussionPost = new DiscussionPost();
            discussionPost.DiscussionAreaCode = this.discussionCode;
            discussionPost.PostedDateTime = new Date().toJSON() //moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

            discussionPost.ApprovalCode = APPROVALCODES.APPROVED;
            if(this.discussion.TypeCode == 'LiveQa'){
                discussionPost.ApprovalCode = APPROVALCODES.PENDING;
            }

            discussionPost.IsAnonymous = this.postAsAnonymous;
            if(this.postAsAnonymous) {
                discussionPost.DisplayName = "Anonymous";
            }

            if(this.replyingToPost) {
                let numberReplies = this.posts.filter(x => x.Id.startsWith(this.replyingToPost.Id));
                discussionPost.ReplyToId = this.replyingToPost.Id;
                discussionPost.Id = `${this.replyingToPost.Id}_${numberReplies.length}`;
            }

            discussionPost.Body = this.postBody;

            discussionPost.Images = this.photosForPost;

            if(this.linkToPost) {
                if(this.linkToPost.startsWith("http")) {
                    discussionPost.LinkTypeCode = "Attachment";
                } else if(this.linkToPost.startsWith("tractus://")) {
                    discussionPost.LinkTypeCode = "TractusLink";
                }

                discussionPost.Link = this.linkToPost;
            }

            discussionPost.TimeStamp = "";
            discussionPost.Votes = null;

            this.isPosting = true;
            let originalBody = this.postBody;
            let originalPhotos = this.photosForPost;
            let originalLink = this.linkToPost;

            try {
                let route = `/api/discussion/post`;
                let r = await this.tryPost(route, JSON.stringify(discussionPost), 'application/json');

                if(r.Errors && r.Errors.length > 0) {
                    throw r.Errors[0];
                }

                this.$nextTick(() => {
                    this.$refs.postBox.focus();
                });

                this.postBody = null;
                this.photosForPost = [];
                this.linkToPost = null;

                window.$bus.$emit('Posted-To-Chat', {
                    EntityCode: this.discussion.Code,
                    TypeCode: this.discussion.TypeCode == 'Chat' ? 'Chat' : 'Discussion'
                });

                this.replyingToPost = null;

            } catch(ex) {
                this.postBody = originalBody;
                this.photosForPost = originalPhotos;
                this.linkToPost = originalLink;

                this.$awn.alert(ex);
            }

            this.isPosting = false;
        },

        populateMessages(toAdd) {
            
            toAdd.forEach(p => {

                if(p.ReplyToId) {

                    let parentPost = this.posts.find(x => x.Id == p.ReplyToId);

                    if(!parentPost) {
                        return;
                    }

                    let parentPostIndex = this.posts.indexOf(parentPost);

                    let associatedPosts = this.posts.filter(x => x.ReplyToId == p.ReplyToId);

                    let insertIndex = parentPostIndex + associatedPosts.length + 1;

                    this.posts.splice(insertIndex, 0, p);

                } else {
                    this.posts.push(p);
                }
            });

        },


        onGotNewMessage(post){

            if(!post || post.DiscussionAreaCode != this.discussionCode){
                return;
            }

            console.log('ON GOT NEW MESSAGE', post)
            window.$bus.$emit('DiscussionMessage', post);

            var postFound = this.posts.find(x => x.Id === post.Id);

            if(postFound){
                this.updatePost(post);
                this.$emit('messageupdated', post);
                return;
            }

            this.populateMessages([post]);

            this.$emit('messagereceived', post);

            window.$bus.$emit('notifymessageread', {
                EntityCode: this.discussionCode,
                TypeCode: this.discussion.TypeCode == 'Chat' ? 'Chat' : 'Discussion'
            });

            this.$refs.postList.checkAndScrollIntoView();
        },

        addPhotoToDiscussion(toAdd){
            this.photosForPost.push(toAdd);
        },

        onDiscussionUpdated(discussion) {
            if(discussion.Code != this.discussionCode){
                return;
            }
            
            this.discussion = discussion;
            this.discussionName = discussion.Name;
            this.setupWatchdogTimer();
        },

        setupWatchdogTimer() {
            if(this.cleanupWatchdogTimer) {
                clearInterval(this.cleanupWatchdogTimer);
            }

            if(this.discussion.AllowTypingIndicator) {
                setInterval(this.onCleanupWatchdog, 10000);
            }
        },

        connectToDiscussion(){
            if(window.$signalRConnection.notInitialized) {
                // Not quite ready yet...
                return;
            }

            this.removeSignalRSubscriptions();
            window.$signalRConnection.on('DiscussionMessage', this.onGotNewMessage);
            window.$signalRConnection.on('UpdateDiscussionArea', this.onDiscussionUpdated);
            window.$signalRConnection.invoke('JoinDiscussionArea', this.passedInCode, AppState.attendee.Code, AppState.attendee.Show.Code);
            this.connected = true;
        },

        async loadDiscussion() {
            if(!this.isInline) {
                window.$bus.$emit('close-chat', this.passedInCode);
            }

            this.submitting = true;

            try {
                let r = await this.tryGet(`/api/discussions/${this.passedInCode}/posts`);

                if(r.Errors.length > 0) {
                    return;
                }

                this.postAnalytics(
                    AppState.attendee.Code,
                    'DiscussionArea',
                    this.passedInCode,
                    "Discussion",
                    AppState.attendee.Show.Code);

                this.discussion = r.Result.Discussion;
                this.discussionName = r.Result.OverrideName || this.discussion.Name;
                this.discussion.Posts = r.Result.Posts;
                this.posts = r.Result.Posts;
                this.replyingToPost = null;

                this.participants = r.Result.ConnectedMembers;

                this.setupWatchdogTimer();

                this.connectToDiscussion();

                window.$bus.$emit('notifymessageread', {
                    EntityCode: this.discussionCode,
                    TypeCode: this.discussion.TypeCode == 'Chat' ? 'Chat' : 'Discussion'
                });

                this.$emit('posts-loaded', this.posts);

                this.editName = this.discussion.Name;

                this.submitting = false;

                this.$nextTick(() => {
                    try {
                        this.$refs.postBox.focus();
                    } catch {

                    }
                });

            } catch(ex) {
                this.errorMessage = "We could not load your info. Please try again later"; 
                this.submitting = false;
            }
        },

        beginApprovePost(post){
            this.postModerating = JSON.parse(JSON.stringify(post));
            this.showModerateBox = true;
        },

        approvePost(){
            var postMod = this.postModerating;

            if(!postMod.Body){
                return;
            }

            this.showModerateBox = false;
            this.tryPost(`/api/discussion/post`, JSON.stringify(postMod), 'application/json')
            .then(r => {
                if(r.Errors.length > 0) {
                    return;
                }
            })
            .catch(r => { this.errorMessage = "Oops! Couldn't post message, please try again later"; this.submitting = false; });
        },

        async updateStickyPost(post) {
            let stickyPosts = this.posts.filter(x => x.IsSticky);

            for(let i = 0; i < stickyPosts.length; i++) {
                let toUpdate = stickyPosts[i];
                toUpdate.IsSticky = false;

                await this.tryPost('/api/discussion/post', JSON.stringify(toUpdate), 'application/json');
            }

            if(!post) {
                return;
            }

            post.IsSticky = true;
            await this.tryPost('/api/discussion/post',
                                JSON.stringify(post), 
                                'application/json');
        },

        cancelApprovePost(){
            this.showModerateBox = false;
        },

        updatePost(post){
            let postToUpdateIndex = this.posts.findIndex(x => x.Id === post.Id);
            if(postToUpdateIndex != -1){
                this.posts.splice(postToUpdateIndex, 1, post);
            }
        },

        async loadDiscussionFromAttendeeCode(){
            try {
                let r = await this.tryPost('/api/discussion/find/members', JSON.stringify([this.passedInCode]), 'application/json');

                if(r.Errors.length > 0) {
                    throw "Error Connecting";
                }

                this.postAnalytics(AppState.attendee.Code,'DiscussionArea',this.passedInCode,'Attendee',AppState.attendee.Show.Code);
                this.passedInCode = r.Result.Code;

                this.$router.replace({name: this.$route.name, params: {discussionCode: r.Result.Discussion.Code}});

            } catch(Ex) {
                this.errorMessage = "Sorry. We can't connect to that chat right now. Please try again later";
                this.submitting = false;
            }

        },

        async switchDiscussion(to, from) {   

            if(this.checkForCallTimeoutHandler) {
                clearTimeout(this.checkForCallTimeoutHandler);
            }

            if(from != null) {
                this.removeSignalRSubscriptions();

                window.$signalRConnection.invoke('LeaveDiscussionArea', from, AppState.attendee.Code, AppState.attendee.Show.Code);
            }

            this.submitting = true;

            try {
                if(this.passedInCode) {
                }
            } catch {

            }

            this.passedInCode = this.discussionCode;

            if(this.$route.query.attendeeCode && !this.isInline){
                //passedInCode is attendeeCode
                await this.loadDiscussionFromAttendeeCode();
            }
            else{
                await this.loadDiscussion();
            }
        },

        onConnected() {
            this.loadDiscussion();
        },

        onReconnecting() {
            this.connected = false;
        },

        removeSignalRSubscriptions() {
            try {

                window.$signalRConnection.off('DiscussionMessage', this.onGotNewMessage);
                window.$signalRConnection.off('UpdateDiscussionArea', this.onDiscussionUpdated);

            } catch {

            }
        },
        
        async onChatNameChange() {
            let oldName = this.discussionName;

            this.editMode = null;

            this.discussion.Name = this.editName;
            this.discussionName = this.editName;

            try {
                await this.tryPost("/api/discussion", JSON.stringify(this.discussion), 'application/json');
            } catch {
                this.$awn.alert("Could not rename the chat. Try again later.");
                this.discussionName = oldName;
                this.discussion.Name = oldName;
            }
        },

        async checkCommitName(e) {
            if(e.keyCode === 13
                && !e.shiftKey) {
                e.preventDefault();
                await this.$refs.nameEditor.blur();
            }
        },

        onHelloGoodbye(message, type, discussionCode) {
            if(!discussionCode 
                || !this.discussion
                || this.discussion.Code != discussionCode) {
                return;
            }

            let foundAttendee = this.participants.find(x => x.Code == message.FromCode);

            if(type == 'Hello') {

                if(foundAttendee) {
                    if(message.FromCode == AppState.attendee.Code) {
                        return;
                    }

                    foundAttendee.Connections++;
                } else {
                    foundAttendee = {
                        Code: message.FromCode,
                        DisplayName: message.DisplayName,
                        Connections: 1,
                        PhotoUrl: null,
                    }

                    this.participants.push(foundAttendee);
                }

            } else {
                if(foundAttendee) {
                    if(message.FromCode == AppState.attendee.Code) {
                        return;
                    }

                    foundAttendee.Connections--;

                    if(foundAttendee.Connections <= 0) {
                        let index = this.participants.indexOf(foundAttendee);
                        if(index >= 0) {
                            this.participants.splice(index, 1);
                        } 
                    }
                }
            }
        },
    },

    data() {
        return {
            connected: false,

            subscribedToNotifications: false,

            postModerating: null,
            discussion: null,
            posts: [],
            photosForPost: [],
            linkToPost: null,
            postBody: null,
            showModerateBox: false,

            replyingToPost: null,

            postAsAnonymous: false,
            isPosting: false,

            passedInCode: null,

            anonymousPost: false,
            sortPosts: 'All',
            scrollMessage: 0,

            checkForCallTimeoutHandler: null,
            
            discussionName: null,

            editMode: null,
            editName: null,

            participants: [],
            showParticipants: false,
            showQaModerate: false,

            lastTypingIndicatorSent: null,
            membersTyping: {},

            cleanupWatchdogTimer: null,

            showEmojiPicker: false,
            showInviteAttendees: false,

            emojis: [
                {
                    Name: 'Smileys', 
                    Items: [
                        '🙂','😃','😄','😆','😅',
                        '😂','🤣','😇','😉','😊','🙃',
                        '😋','😌','🥰','😘',
                        '😗','😙','😚','🤪','😜','😝',
                        '😛','🤑','😎','🤓','🧝','🤠',
                        '🥳','🤗','🤡','😶',
                        '😑','😒','🙄','🤨','🤔','🤫',
                        '🤭','🤥','😳','😞','😟','😠',
                        '😡','🤬','😔','😕',
                        '😬','🥺','😣','😖','😫','😩',
                        '🥱','😤','😮','💨','😱',
                        '😨','😰','😯','😦','😧','😢',
                        '😥','😪','🤤','😓','😭','🤩',
                        '😵','💫','🥴','😲','🤯',
                        '🤝','😷','🤕','🤒','🤮','🤢',
                        '🤧','🥵','🥶','😴','💤','😈',
                        '👿','👹','👺','💩','👽','😺',
                        '😸','😹','😻','😼','😽','🙀',
                        '😿','😾','❤','🧡','💛','💚',
                        '💙','💜','🤎','🖤','💔',
                        '💕','💞','💓','💗','💖',
                        '💘','💝','💯','💢','💣'
                    ]
                },
                {
                    Name: "Hand Gestures",
                    Items: [
                        '👍','👎','🤝','👊','🖖',
                        '✌','👉','👈','👇','👆',
                        '💪','👂','🦻','👃',
                        '👀','👅'
                    ]
                },

            ],
        }
    },

    created: function() {
        this.switchDiscussion(this.discussionCode);
        window.$bus.$on('connected-to-signalr', this.onConnected);
        window.$bus.$on('reconnecting-to-signalr', this.onReconnecting);
        window.$bus.$on('Received-Hello-Goodbye', this.onHelloGoodbye);
        window.$bus.$on('typing-indicator', this.onTypingStateReceived);
        window.$bus.$on('post-deleted', this.onPostDeleted);
    },

    beforeUnmount() {
        // if(this.checkForCallTimeoutHandler) {
        //     clearTimeout(this.checkForCallTimeoutHandler);
        // }

        window.$bus.$off('connected-to-signalr', this.onConnected);
        window.$bus.$off('reconnecting-to-signalr', this.onReconnecting);
        window.$bus.$off('Received-Hello-Goodbye', this.onHelloGoodbye);
        window.$bus.$off('typing-indicator', this.onTypingStateReceived);
        window.$bus.$off('post-deleted', this.onPostDeleted);
        this.removeSignalRSubscriptions();
        clearInterval(this.cleanupWatchdogTimer);

        if(window.$signalRConnection.notInitialized) {
            // Not quite ready yet...
            return;
        }

        window.$signalRConnection.invoke('LeaveDiscussionArea', this.passedInCode, AppState.attendee.Code, AppState.attendee.Show.Code);
    },
}
</script>