<template>
    <div class="video-player-container"
         ref="videoPlayerRoot">
        <video class="video-js"
               id="videojs"
               ref="videojs"
               v-if="src"
               :controls="showControls"
               preload="false"
               :poster="previewImage"
               playsinline>
            <source :src="src" />
        </video>
    </div>
</template>
<script>
import videojs from "video.js"
import { nextTick } from 'vue';
import { useDebounceFn, useThrottleFn } from '@vueuse/core'
import Common from './common'
import Token from './authentication/token'

export default {
    props: [
        'src', 
        'waitForStream', 
        'inPreviewMode', 
        'previewImage', 
        'showControls',
        'clearStartAtTime',
        'suppressEventEmit',
        'startAtSeconds',
        'emitProgress',
        'entityCode',
        'entityTypeCode',
        'lastUserPositionSec'
    ],

    mixins: [
        Token,
        Common,
        //ElementMixin
    ],   
    watch: {
        src(to, from) {
        },
    },

    data() {
        return {
            videoPlayer: null,

            showPlayer: false,

            lastTime: 0,
            startAtTime: null,

            didStartAtSavedTime: false,
            debouncePositionChangeHandler: null,

            wasWarnedAboutAutoplayBlock: false,

            didStartPlayback: false,
        }
    },

    computed: {
        videoCode() {
            if(!this.src) {
                return null;
            }

            if(!this.src.startsWith("https://tractusvideo")) {
                return null;
            }

            let firstHalf = this.src.split('_');
            
            let videoCodeHalf = firstHalf[firstHalf.length - 1];

            let videoCode = videoCodeHalf.split('/')[0];

            return videoCode;
        },
    },

    methods: {

        playVideoAtTime(src, time) {
            console.log('playVideoAtTime')
            if(this.src == src) {
                this.seek(time, true);
                return;
            }

            this.startAtTime = time;
            this.initializePlayer();
        },

        seek(time, force) {
            console.log('seek')
            this.startAtTime = time;

            let timeStamp = new Date(time);

            if(timeStamp == NaN) {
                return;
            }

            let seekTimeSeconds = (new Date() - timeStamp) / 1000.0;

            let currentPosition = this.videoPlayer.currentTime();

            if(currentPosition != NaN && currentPosition > 0 && !force) {
                
                let difference = Math.abs(currentPosition - seekTimeSeconds);

                if(difference <= 1.0) {
                    // We're OK with a 1 second time difference.
                    return;
                }
            }

            if(seekTimeSeconds > this.videoPlayer.duration()) {
                seekTimeSeconds = this.videoPlayer.duration() - 1.0;
            }

            this.videoPlayer.currentTime(seekTimeSeconds);
        },


        async onCanPlay() {
            console.log('onCanPlay')
            let v = this.videoPlayer;

            if(this.startAtTime) {
                this.seek(this.startAtTime);
            }

            if(this.lastUserPositionSec && !this.didStartAtSavedTime) {
                console.log("Starting at indicated replay time ", this.lastUserPositionSec, this)
                this.didStartAtSavedTime = true;
                this.videoPlayer.currentTime(this.lastUserPositionSec);
            }

            if(!this.didStartAtSavedTime) {
                console.log("Starting at indicated time ", this.startAtSeconds, this)
                this.didStartAtSavedTime = true;
                if(this.startAtSeconds) {
                    this.videoPlayer.currentTime(this.startAtSeconds);
                }
            }            

            if(this.waitForStream) {
                return;
            }

            try {
                await v.play();
            } catch(ex) {
                if(this.wasWarnedAboutAutoplayBlock) {
                    return;
                }

                this.wasWarnedAboutAutoplayBlock = true;

                let promise = new Promise(r => {
                    this.$awn.confirm(
                        "Your browser has blocked auto-play of the conference audio. Click 'Join Audio' to listen.",
                        () => {
                            r('End');
                        },
                        false,
                        {
                            labels: {
                                confirm: "Join Audio",
                            }
                        }
                    )
                });

                await promise;

                if(this.startAtTime) {
                    this.seek(this.startAtTime);
                }

                await v.play();
            }

            if(this.clearStartAtTime) {
                this.startAtTime = null;
            }            
        },

        initializePlayer() {
            console.log('initializePlayer')
            if(this.videoPlayer) {
                this.disposeVideoPlayer();
            }

            if(!this.src) {
                return;
            }

            this.videoPlayer = videojs(this.$refs.videojs, {
                fill: true,
                playbackRates: [0.5, 1, 1.25, 1.5, 1.75, 2, 2.5]
            });

            const player = this.videoPlayer;

            player.on('play', this.onVideoEvent);
            player.on('pause', this.onVideoEvent);
            player.on('ended', this.onVideoEvent);
            player.on('canplay', this.onCanPlay);

            if(this.emitProgress) {
                player.on('timeupdate', this.debounceOnPositionChange);
            }
            //player.qualityLevels().on('change', this.onQualityLevelChange);


        },

        reportedPosition() {
            let videoPlayer = this.videoPlayer;

            return videoPlayer 
                && videoPlayer.currentTime() != NaN
                ? videoPlayer.currentTime()
                : NaN;            
        },        

        debounceOnPositionChange(e) {
            if(!this.emitProgress) {
                return;
            }

            if(!this.debouncePositionChangeHandler) {
                this.debouncePositionChangeHandler = useThrottleFn(this.onReportProgress, 60000);
            }

            this.debouncePositionChangeHandler(e);
        },

        async onReportProgress() {

            // We don't want to report if playback hasn't started
            if(!this.didStartPlayback) {
                return;
            }

            if(!this.emitProgress) {
                return;
            }

            if(!this.src.startsWith('https://tractusvideo')) {
                return;
            }

            let videoCode = this.videoCode;

            if(!videoCode) {
                return;
            }

            console.log("Progress Handler Running...");
            let currentPosition = this.videoPlayer.currentTime();
            let duration = this.videoPlayer.duration();
            let speed = this.videoPlayer.playbackRate();

            // "https://tractusvideo.blob.core.windows.net/video/PHC2023/2DDA2EA9EF1C4C22B766518740901C28_02517187496664654357/master.m3u8"

            let toPost = {
                EntityTypeCode: this.entityTypeCode,
                EntityCode: this.entityCode,
                VideoCode: videoCode,
                PositionSec: Math.trunc(currentPosition),
                DurationSec: Math.trunc(duration),
                Speed: speed
            }

            await this.tryPost('/api/analytics/videoprogress', JSON.stringify(toPost), 'application/json');
        },

        onVideoEvent(e) {
            if(this.suppressEventEmit) {
                return;
            }

            if(e.type == 'play') {
                this.didStartPlayback = true;
            }

            let ev = {
                eventType: e.type,
                time: this.videoPlayer.currentTime(),
                raised: new Date(),
                src: this.src
            };

            if(this.emitProgress && (e.type == 'pause' || e.type == 'ended')) {
                // Report progress right away - don't give the system time to think
                this.onReportProgress();
            }

            window.$bus.$emit('videoevent', ev);

            this.$emit('videoevent', ev);
        },

        disposeVideoPlayer() {
            if(this.videoPlayer) {
                this.videoPlayer.off('play', this.onVideoEvent);
                this.videoPlayer.off('pause', this.onVideoEvent);
                this.videoPlayer.off('ended', this.onVideoEvent);
                this.videoPlayer.off('canplay', this.onCanPlay);

                if(this.emitProgress) {
                    this.videoPlayer.off('timeupdate', this.debounceOnPositionChange);
                }
                
                this.debouncePositionChangeHandler = null;
                
                let finalTimeStamp = this.videoPlayer.currentTime() || 0;

                let currentTime = Math.ceil(finalTimeStamp);

                //this.videoPlayer.qualityLevels().off('change', this.onQualityLevelChange);

                if(!this.suppressEventEmit) {
                    window.$bus.$emit('videoevent', {
                        eventType: 'stopped:destroy',
                        time: currentTime,
                        raised: new Date(),
                        src: this.src
                    });     

                    if(this.emitProgress) {
                        this.onReportProgress();
                    }
                }

                this.videoPlayer = null;
            }
        },

        async onJumpToVideoPosition(position) {
            if(!this.videoPlayer.hasStarted()) {
                await this.videoPlayer.play();
                this.videoPlayer.currentTime(position);
            } else {
                this.videoPlayer.currentTime(position);
            }
        }
    },

    beforeUnmount() {
        this.disposeVideoPlayer();
        window.$bus.$off('jump-to-video-position', this.onJumpToVideoPosition);
    },

    mounted() {
        console.log('mounted')
        window.$bus.$on('jump-to-video-position', this.onJumpToVideoPosition);
        this.initializePlayer();
    }
}
</script>