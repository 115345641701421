<template>
    <div class="announcements">
        <div v-for="post in posts"
            :key="post.SentDateTime"
            @click="attemptNavigate(post)"
            class="activity-post"
            :class="[post.RelatedCode ? 'activity-post-link' : '' ]">
            <div class="activity-title">
                <div class="activity-icon" v-if="!post.Image">
                    <span v-if="post.RelatedTypeCode == 'Poll'"
                          class="icomoon">
                        
                    </span>
                    <span v-else
                          class='icomoon'>
                        
                    </span>
                </div>
                <div class="activity-icon" v-else>
                    <img-g :src="post.Image" class="activity-icon-img" />
                </div>
                <div><span v-if="post.IsPinned" class="icomoon"></span></div>
                <div class="activity-title-details">
                    <div class="activity-subject subtitle">
                    {{post.Subject}}
                    </div>
                    <div class="activity-time subtitle">{{$filters.moment(post.PublishedDateTime, 'DD-MMM-YY, h:mm a')}}</div>
                </div>

            </div>
            <div class="activity-body-container">
                <div class='activity-body'
                     v-html="parseBodyForImages(post.Body)">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import Vue from "vue";
import Token from "./authentication/token";
import Common from "./common";
import moment from "moment";

export default {
  props: [
    "mode",
    "myInfo",
    "activities",
    "photosForPost",
    "scrollMessage",
    'attendee'
  ],
  mixins: [Token, Common],

  watch: {
    scrollMessage(from, to) {
      if (from != to) {
        this.alertNewMessage();
      }
    }
  },

  methods: {
    attemptNavigate(post) {
        if (!post.RelatedTypeCode 
            || post.RelatedTypeCode == "null" 
            || !post.RelatedTypeCode 
            || post.RelatedTypeCode == "null") {

            return;
        }

        let tractusLink = `tractus://${post.RelatedTypeCode}?c=${post.RelatedCode}&s=${AppState.attendeeCode}`;

        this.openLink(tractusLink, this.attendee);
    },

    parseBodyForImages(postText) {
      if (!postText) {
        return;
      }
      var httpArray = postText.split("http");

      if (httpArray.length <= 1) {
        return postText;
      }

      var outputArray = [];
      httpArray.forEach(function(web) {
        let toPush = web;
        if (
          web.includes(".jpg") ||
          web.includes(".jpeg") ||
          web.includes(".png")
        ) {
          toPush = `<br><div class="post-img-container"><img class="responsive hide-until-create fade-in-on-create" src="http${toPush}"></div>`;
        }
        outputArray.push(toPush);
      });

      return outputArray.join(" ");
    },
  },

  computed: {
    posts(){
      let toReturn = this.activities;
      toReturn.sort((a, b) => {
        
        if((a.IsPinned && b.IsPinned) || (!a.IsPinned && !b.IsPinned)){
            return moment(b.PublishedDateTime) - moment(a.PublishedDateTime)
        }
        else{
          if(a.IsPinned > b.IsPinned){
                return -1;
            }
            if(a.IsPinned < b.IsPinned){
                return 1;
            }
          }

      });
      
      return toReturn;
    }
  },
};
</script>