<template>
    <div class="login-screen">
        <vue-title :title="'Tractus Passport | Login'"></vue-title>
        <form class="loginForm" @submit="onLogin">
            <img-g src="/img/Logo_Dark_2022.png" class="responsive" />
            
            <div class="form-control">
                <input
                v-model="attendeeCode"
                autocomplete="off"
                autocorrect="off"
                :disabled="submitting"
                required
                maxlength="16"
                class="form-control"
                placeholder="Attendee Code"
                aria-label="Attendee Code" />
                
            </div>

            <div class="form-control">
                <input
                v-model="attendeePin"
                :disabled="submitting"
                required
                inputmode="numeric"
                maxlength="16"
                type="password"
                placeholder="PIN"
                class="form-control"
                aria-label="PIN" />
            </div>

            <div class="form-control">
                <input
                v-model="showCode"
                autocomplete="off"
                autocorrect="off"
                maxlength="16"
                :disabled="submitting"
                required
                placeholder="Show Code"
                class="form-control"
                aria-label="Show Code" />

                        
            </div>


            <div
                v-if="validateErrors && validateErrors.length > 0"
                class="alert alert-danger text-left"
                role="alert">
                Oops! It looks like something happened. Please double-check and
                try again.
                <ul>
                    <li v-for="error in validateErrors">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <button @submit.prevent
                    type="button"
                    @click="onLogin"
                    :disabled="submitting">
                Sign In
            </button>
        </form>
    </div>
</template>
<script>
import Common from "./common";

// import Vue from "vue";
import Token from "./authentication/token";

export default {
    mixins: [Token, Common],
    name: "PublicPollResponse",
    data() {
        return {
            validateErrors: [],
            submitting: false,
            attendeePin: "",
            showCode: "",
            attendeeCode: ""
        };
    },

    created: function () {
        if (this.$route.query.s) {
            this.showCode = this.$route.query.s;
        }

        if (this.$route.query.p) {
            this.attendeePin = this.$route.query.p;
        }

        if (this.$route.query.a) {
            this.attendeeCode = this.$route.query.a;
        }

        if (this.$route.query.dl) {
            this.onLogin();
        }

        if (this.$route.query.l) {
            this.tryLoginFromBase64();
        }
    },

    methods: {
        async tryLoginFromBase64() {
            let segments = atob(this.$route.query.l).split(':');
            let attendeeCode = segments[0];
            let pin = segments[1];
            let showCode = segments[2];
            let redirect = this.$route.query.redirect;

            let path = redirect || '/';

            if('indexedDB' in window) {
                try {
                    let attendee = await this.get('attendee');
                    
                    if(attendee.Code == attendeeCode 
                        && attendee.Show.Code == showCode
                        && isLoggedIn()) {

                        this.$router.replace({path: path});
                        return;
                    }
                } catch(ex) {
                    console.error(ex);
                }
            }

            this.showCode = showCode;
            this.attendeePin = pin;
            this.attendeeCode = attendeeCode;
            this.onLogin();
        },

        validateForm: function () {
            this.validateErrors = [];

            if (!this.attendeeCode) {
                this.validateErrors.push("Attendee code cannot be blank.");
            }

            if (!this.attendeePin) {
                this.validateErrors.push("PIN cannot be blank.");
            }

            if (!this.showCode) {
                this.validateErrors.push("Show code cannot be blank.");
            }

            return this.validateErrors.length === 0;
        },

        async onLogin(ev) {

            this.clear();
            localStorage.clear();

            this.submitting = true;

            if (!this.validateForm()) {
                this.submitting = false;
                return;
            }
            
            try {
                let r = await this.tryAttendeeLogin(
                    this.attendeeCode,
                    this.attendeePin,
                    this.showCode
                );


                if (r.Errors && r.Errors.length > 0) {
                    this.validateErrors.push(r.Errors[0]);
                } else {
                    if(this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect);
                    } else {
                        this.$router.push("/");
                    }
                }

            } catch(ex) {
                this.validateErrors.push(ex);
            }

            this.submitting = false;
        },
    },
};
</script>
