<template>
    <div v-if="submitting"
         class="fixed-content-width">
        <loading-container></loading-container>
    </div>
    <div v-else
         class="page-container">
        
        <div class="list-page-full">
            <vue-title :title="'My Profile Views'" :brand="true"></vue-title>

            <div class="list-page-header">
                <div class="search-container">

                    <div class="search-box">
                        <span class="icomoon">
                            
                        </span>
                        <input placeholder="Search" 
                            v-debounce:150="updateSearchTerm"   
                            type="text"/>
                    </div>
                </div>
            </div>

            <div class="list-page-contents">
                <div class="category-item">
                    <div class="category-items attendee-list-items">

                        <attendee-list-item v-for="item in filteredResults"
                                    :key="item.Code"
                                    :attendee="item.Attendee"
                                    :isFavorite="item.IsFavorite"
                                    :online="item.IsOnline"
                                    :class="[$route.params && ($route.params.discussionCode && $route.params.discussionCode == item.Code) || ($route.params.code == item.Code) ? 'list-item-selected' : '']">
                        </attendee-list-item>

                    </div>
                </div>
            </div>
        </div>
        <router-view :attendee="attendee" ></router-view>
    </div>
</template>
<script>
import Common from './common'
import Token from './authentication/token'
import { AppState } from './appstate';;
import moment from 'moment'
import loading from './loading.vue';

export default {
    mixins: [
        Token,
        Common
    ],   

    props: ['attendee', 'code', 'typeCode'],

    watch: {
    },

    computed: {

        filteredResults() {
            if(!this.viewedBy) {
                return [];
            }            

            let targetArray = this.viewedBy;

            if(this.searchTerm
                && this.searchTerm.length > 0) {

                let searchTest = new RegExp(this.searchTerm, 'i');
                targetArray = targetArray.filter(s => searchTest.test(s.Attendee.DisplayName) 
                    || searchTest.test(s.Attendee.ContactInfo.Title)
                    || searchTest.test(s.Attendee.ContactInfo.SubTitle));

            }

            targetArray = targetArray.sort((a, b) => {
                let isAOnline = a.IsOnline;
                let isBOnline = b.IsOnline;

                if(!isAOnline && isBOnline) {
                    return 1;
                }

                if(isAOnline && !isBOnline) {
                    return -1;
                }

                return 0;
            });

            if(this.favoritesOnly) {
                targetArray = targetArray.filter(x => x.IsFavorite);                
            }

            return targetArray;            
        },

        attendeeRouteDestinationName() {

            return this.$route.name == 'ProfileViewsAttendeeStandalone'
                || this.$route.name == 'attendeeDetailsAnalytics'
                ? 'attendeeDetailsAnalytics'
                : 'attendeeDetails';
        }
    },

    methods: {
        closePage() {
            this.goBackOneRoute();
        },

        updateSearchTerm(inputVal, eventObject){
            this.searchTerm = inputVal;
        },

        async sendMessage(booking) {
            try {
                let foundArea = await this.tryPost('/api/discussion/find/members', JSON.stringify([booking.AttendeeCode]), 'application/json');
                if(foundArea.Result) {
                    window.$bus.$emit('selectedchat', foundArea.Result.Discussion.Code);
                } else {
                    throw "Nope";
                }
            } catch {
                this.$awn.alert("Sorry, we couldn't start a chat session. Please try again later.");
            } finally {
            }
        },


        async build() {
            this.submittingRequest = true;
            await this.refresh();

            await this.connectToControlChannel();
            this.submittingRequest = false;

            this.postAnalytics(
                AppState.attendeeCode,
                'ProfileAnalytics',
                null,
                null,
                AppState.showCode);
        },

        async refresh() {
            try {
                let views = await this.tryGet(`/api/profileviews/${AppState.showCode}/attendee/${AppState.attendeeCode}`);

                if(views.Result) {
                    this.viewedBy = views.Result;
                } else {
                    throw 'error';
                }
            } catch {
                this.$awn.alert("We couldn't load your analytics. Please try again later.");
                this.closePage();
            } finally {
            }
        },

        async connectToControlChannel(){
            if(window.$signalRConnection.notInitialized) {
                return;
            }

            window.$signalRConnection.invoke(
                'RegisterForPageAnalytics', 
                `${AppState.showCode}_ATTENDEE_${AppState.attendeeCode}`.toUpperCase());
        },    
        

        onFavoritesUpdated(favorites){
            if(!favorites || !favorites.forEach) {
                this.refresh();
            } else {
                favorites.forEach(f => {
                    if(f.EntityTypeCode != 'ATTENDEE') {
                        return;
                    }

                    let result = this.viewedBy.find(x => x.Code == f.EntityCode);

                    if(!result) {
                        return;
                    }

                    result.IsFavorite = f.IsActive;
                });
            }
        },        
    },

    data() {
        return {
            viewedBy: [],
            submittingRequest: false,
            searchTerm: '',
            favoritesOnly: false,
        }
    },
    
    created() {
        this.build();
    },

    mounted() {
        window.$bus.$on('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$on('profile-view', this.refresh);
        window.$bus.$on('connected-to-signalr', this.connectToControlChannel);            
    },

    beforeUnmount() {
        window.$bus.$off('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$off('profile-view', this.refresh);
        window.$bus.$off('connected-to-signalr', this.connectToControlChannel);       
        window.$signalRConnection.invoke(
            'UnregisterForPageAnalytics', 
            `${AppState.showCode}_ATTENDEE_${AppState.attendeeCode}`.toUpperCase());

    },
}
</script>