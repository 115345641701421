<template>
    <div class="iframe-overlay lite-poll-overlay"
         :class="{'iframe-show': poll}">
        <div v-if="poll">
            <div class="page-side-panel">
                <div class="iframe-controls">
                    <span>Open Poll</span>

                    <button class="icomoon" 
                            type="button"
                            @click="close">
                        
                    </button>
                </div>
                <div class="inner-poll-details">

                    <div class="inner-poll-header">
                        <h1>{{poll.Title}}</h1>
                        <p>{{poll.Body}}</p>
                    </div>

                    <label v-for="(option, i) in poll.Options"
                           :class="{'option-selected': i == selectedOption}">
                        <input type="radio"
                               name="poll"
                               v-model="selectedOption"
                               :value="i">
                        {{option}}                             
                    </label>

                </div>
                <button @click="submitAnswerAsync"
                        :disabled="submitting || selectedOption == null">
                    Submit
                </button>
            </div>
        </div>
    </div>

</template>
<script>
import Common from './common'
import Token from './authentication/token'
import { AppState } from './appstate';

export default {
    props: [
    ],
        
    mixins: [
        Common,
        Token,
    ],

    methods: {
        close() {
            this.poll = null;
        },

        onPollStateChange(poll) {
            this.selectedOption = null;

            if(poll.IsOpen) {
                this.poll = poll;
            } else {
                this.poll = null;
            }
        },  

        async submitAnswerAsync() {
            this.submitting = true;
            try {
                
                let result = await this.tryPost(`/api/litepoll/answer/${AppState.attendee.Show.Code}/${this.poll.RoomCode}/${this.poll.Code}/${this.selectedOption}`,
                    JSON.stringify({}),
                    "application/json");

                this.poll = null;
            } catch(ex) {
                console.error(ex);
                this.$awn.alert("Could not send your answer. Please try again.");
            }

            this.submitting = false;
        }
    },

    data() {
        return {
            poll: null,
            selectedOption: null,
        }
    },

    created() {
        window.$bus.$on('lite-poll-state-change', this.onPollStateChange);
    },

    beforeUnmount() {
        window.$bus.$off('lite-poll-state-change', this.onPollStateChange);
    }
}
</script>