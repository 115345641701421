<template>
    <div class="loading-container">
        <loading></loading>
    </div>
</template>
<style scoped>
.loading-container {
    align-self: center;
    flex-basis: content;
    flex-grow: unset !important;
    align-items: center;
    display: flex;
    justify-content: center;
}
</style>
<script>
export default {
}
</script>