<template>
    <div v-if="!submitting" class="list-page-full">
        <vue-title :title="'Polls'" :brand="true"></vue-title>
        <div class="list-page-contents">
            <div class="category-item" v-if="polls.length > 0">
                <span class="category-title">
                    Open Polls
                </span>

                <div class="category-items poll-category-items">
                    <router-link class="poll-list-item list-item"
                                v-for="item in polls"
                                :key="item.Code"
                                :to="{name: 'PollAnswer', params:{code: item.Code}}">

                        <span class="title">{{item.Name}}</span>
                        <span class="subtitle"
                                v-if="item.HeaderText">{{item.HeaderText}}</span>
                    </router-link>                    
                </div>
            </div>
            <div class="category-item" v-else>
                <span class="category-title">
                    Open Polls
                </span>

                <div class="no-polls-msg">There are no open polls.</div>
            </div>
        </div>
    </div>
    <div v-else class="fixed-content-width">
        <loading-container />
    </div>

</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'


export default {
    props: ['code'],
    mixins: [
        Token,
        Common
    ],   

    data() {
        return {
            polls: [],
        }
    },

    methods: {
        async getOpenPolls(){
            try{
                let r = await this.tryGet(`/api/polls/open`);

                if(r.Errors.length > 0) {
                    this.errorMessage = r.Errors[0];
                } else {
                    this.polls = r.Result;
                }
                this.postAnalytics(AppState.attendeeCode,'PollsList',null,null,AppState.showCode);
                this.submitting = false;
            }
            catch(err){
                this.errorMessage = "We could not load your info. Please try again later"; 
                this.submitting = false;
            }
        }
    },

    created: function() {
        this.submitting = true;
        this.getOpenPolls();
    },
}
</script>