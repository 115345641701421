import { getApiUrl } from '../environment.hci'
import { v4 as uuidv4 } from 'uuid';

const ERR_PROMISE_REJECT_500 = "We weren't able to connect to the server. Please try again later.";
const HTTP_PATTERN = /^((http|https|ftp):\/\/)/;

export function requireAttendeeAuth(to, from, next) {
    if (!isLoggedIn()) {
        next({
            path: '/login',
            query: { l: to.query.l, redirect: to.fullPath }
        });
    } else {
        next();
    }
}

export function getIdToken() {
    return JSON.parse(localStorage.getItem('currentUser'));
}

export function isLoggedIn() {
    const idToken = getIdToken();
    return !!idToken;
}

export default {

    methods: {
        getApiUrl() {
            return getApiUrl();
        },

        newGuid() {
            return uuidv4().toUpperCase();
        },

        checkedIsLoggedIn() {
            return isLoggedIn();
        },

        logOut: function () {
            let metaThemeColor = document.querySelector("meta[name=theme-color]");
            metaThemeColor.setAttribute('content', "#203864");

            let style = document.getElementById('theme-stylesheet');
            if (style) {
                style.innerHTML = '';
            }

            var path = '/login';

            let promptPersist = localStorage.getItem('webrtc-permission-prompt');

            localStorage.clear();

            if (promptPersist) {
                localStorage.setItem('webrtc-permission-prompt', 'yes');
            }

            try {
                sessionStorage.removeItem('dk');
                window.deviceKey = null;
            } catch {

            }

            this.$router.replace(path);
            this.$emit("auth:changed", false);
        },

        tryPostBinary: async function (path, data, contentType) {
            var original = this;
            var currentDetails = this.getUserDetails();
            var headers = {};
            headers['Authorization'] = 'Bearer ' + currentDetails.token;

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            if (contentType) {
                headers['Content-Type'] = contentType;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'post',
                    credentials: 'include',
                    headers: headers,
                    body: data
                });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                return null;
                            }
                            return original.tryPostBinary(path, data, contentType);
                        }
                        catch (ex) {
                            return null;
                        }
                    }
                }

                try {
                    r = await r.blob();
                }
                catch (ex) {
                    return null;
                }

                return r;

            }
            catch (err) {
                return null;
            }
        },

        //returns json object on success
        async tryGetAnonymous(path) {

            var original = this;

            var headers = {
                'Accept': 'application/json',
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'get',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                return null;
                            }
                            return original.tryGetAnonymous(path);
                        }
                        catch (ex) {
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                return r;
            }
            catch (err) {
                return {
                    Errors: ["Network Error, please check your network connection and try again."],
                    ErrorCodes: ['ERR_Network'],
                    Result: null
                };
            }
        },

        //returns json object on success
        async tryGet(path) {

            var original = this;

            var currentDetails = this.getUserDetails();
            var headers = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + currentDetails.token
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'get',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                return null;
                            }
                            return await this.tryGet(path);
                        }
                        catch (ex) {
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                return r;
            }
            catch (err) {
                return {
                    Errors: ["Network Error, please check your network connection and try again."],
                    ErrorCodes: ['ERR_Network'],
                    Result: null
                };
            }
        },

        //returns json object on success
        async tryPut(path) {

            var original = this;

            var currentDetails = this.getUserDetails();
            var headers = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + currentDetails.token
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'put',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                return null;
                            }
                            return await this.tryPut(path);
                        }
                        catch (ex) {
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                return r;
            }
            catch (err) {
                return {
                    Errors: ["Network Error, please check your network connection and try again."],
                    ErrorCodes: ['ERR_Network'],
                    Result: null
                };
            }
        },

        tryGetBinary: async function (path) {
            var currentDetails = this.getUserDetails();
            var headers = {};
            headers['Authorization'] = 'Bearer ' + currentDetails.token;

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'get',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                return null;
                            }
                            return await this.tryGetBinary(path);
                        }
                        catch (ex) {
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.blob();
                }
                catch (ex) {
                    return null;
                }

                return r;

            }
            catch (err) {
                return null;
            }
        },


        async tryDelete(path) {

            var original = this;

            var currentDetails = this.getUserDetails();
            var headers = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + currentDetails.token
            };

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }

            try {
                let r = await fetch(path, {
                    mode: 'cors',
                    method: 'delete',
                    credentials: 'include',
                    headers: headers
                });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                return null;
                            }
                            return await this.tryDelete(path);
                        }
                        catch (ex) {
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                return r;
            }
            catch (err) {
                return {
                    Errors: ["Network Error, please check your network connection and try again."],
                    ErrorCodes: ['ERR_Network'],
                    Result: null
                };
            }
        },

        async tryRefreshToken() {
            let expiredToken = getIdToken().token;
            let userName = getIdToken().username;

            try {
                let r = await fetch(`${getApiUrl()}/api/token/refresh`, {
                    mode: 'cors',
                    method: 'post',
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                    body: JSON.stringify({ ExpiredToken: expiredToken })
                });

                try {
                    r = await r.json();
                }
                catch (ex) {
                    this.$router.push('/login');
                    return null;
                }

                if (!r.Result) {
                    this.$router.push('/login');
                    return null;
                }

                this.authenticated = true;
                localStorage.setItem("currentUser", JSON.stringify({
                    username: userName,
                    token: r.Result
                }));

                return r;
            }
            catch (err) {
                return null;
            }
        },

        //returns json
        async tryPost(path, data, contentType, redirectToLoginOnFail = true) {
            var currentDetails = this.getUserDetails();
            var original = this;

            var headers = {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + currentDetails.token
            };

            if (contentType) {
                headers['Content-Type'] = contentType;
            }

            if (!HTTP_PATTERN.test(path)) {
                path = getApiUrl() + path;
            }


            try {
                let r = await fetch(path,
                    {
                        mode: 'cors',
                        method: 'post',
                        headers: headers,
                        body: data
                    });

                if (!r.ok) {
                    if (r.status === 401) {
                        try {
                            let result = await this.tryRefreshToken();
                            if (result == null || result == undefined) {
                                return null;
                            }
                            return await this.tryPost(path, data, contentType, redirectToLoginOnFail);
                        }
                        catch (ex) {
                            return null;
                        }
                    }

                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

                try {
                    r = await r.json();
                    return r;
                }
                catch (ex) {
                    return {
                        Errors: [r.statusText],
                        ErrorCodes: ['ERR_' + r.status],
                        Result: null
                    };
                }

            }
            catch (err) {
                return {
                    Errors: ["Network Error, please check your network connection and try again."],
                    ErrorCodes: ['ERR_Network'],
                    Result: null
                };
            }
        },

        getUserDetails: function () {
            return getIdToken();
        },

        async tryAttendeeLogin(attendeeCode, attendeePin, showCode) {
            var request = {
                TypeCode: 'Attendee',
                Elements: [
                    {
                        Code: 'AttendeeCode',
                        Value: attendeeCode
                    },
                    {
                        Code: 'ShowCode',
                        Value: showCode
                    },
                    {
                        Code: 'Pin',
                        Value: attendeePin
                    },
                    {
                        Code: 'PlatformCode',
                        Value: 'Web'
                    },
                    {
                        Code: 'DeviceUniqueId',
                        Value: attendeeCode + attendeePin + showCode
                    }
                ]
            };

            try {
                const apiUrl = getApiUrl();
                console.log("API URL: ", apiUrl)
                let r = await fetch(apiUrl + '/api/token',
                    {
                        mode: 'cors',
                        method: 'post',
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
                        body: JSON.stringify(request)
                    });

                try {
                    r = await r.json();
                }
                catch (ex) {
                    return {
                        Errors: [ERR_PROMISE_REJECT_500],
                        ErrorCodes: ['ERR_500'],
                        Result: ex
                    };
                }

                var success = false;

                success = r && r.Errors.length == 0 && r.Result;
                this.$emit("auth:changed", success);

                if (success) {
                    this.authenticated = true;
                    localStorage.setItem("currentUser", JSON.stringify({
                        username: attendeeCode,
                        token: r.Result
                    }));
                }

                return r;

            } catch (ex) {
                return {
                    Errors: [ERR_PROMISE_REJECT_500],
                    ErrorCodes: ['ERR_500'],
                    Result: r && r.json ? r.json() : r
                };
            }


        }
    }
}