<template>
    <div class="qa-post-moderate"
         :class="{'qa-post-answered': post.IsAddressed, 'qa-post-active': post.Id == activePost}">
        <span class="qa-header">
            <button @click.stop="toggleAnswered()"
                    class="icomoon">
                {{post.IsAddressed ? '' : ''}}
            </button>
            <span class="icomoon">
                
            </span>
            <span>
                {{post.Likes.length}}
            </span>
            <span>
                {{post.DisplayName}}
            </span>
        </span>
        <span class="qa-time">
            {{$filters.moment(post.PostedDateTime, "L LT")}}
        </span>
        <span class="qa-question-body">
            {{post.Body}}
        </span>
        <div v-if="selectedPost == post.Id"
             class="qa-post-controls">
            <button @click="sendAsActiveQuestion">
                <span v-if="activePost != post.Id">
                    Set as Active Question
                </span>
                <span v-else>
                    Current Active Question
                </span>
            </button>
            <button @click="projectQuestion">
                <span>
                    Project
                </span>
            </button>
            <button @click="copyQuestionToClipboard"
                    class="icomoon">
                
            </button>
        </div>
    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'
import moment from 'moment';

export default {
    props: ['post', 'selectedPost', 'activePost'],
    mixins: [
        Token,
        Common
    ],

    methods: {
        projectQuestion() {
            this.$emit('project-question', this.post);
        },
        
        sendAsActiveQuestion() {
            this.$emit('send-active-question', this.post);
        },

        toggleAnswered() {
            this.$emit('toggle-answered', this.post);
        },

        copyQuestionToClipboard() {
            navigator.clipboard.writeText(`${this.post.DisplayName}\r\n${moment(this.post.PostedDateTime).format("L LT")}:\r\n\r\n${this.post.Body}`);
        }
    }
}
</script>