<template>
    <form enctype="multipart/form-data" novalidate class="upload-control">
        <input type="file" 
                :name="uploadFieldName" 
                :disabled="isSaving"
                ref="fileUploadButton"
                @change="filesChange($event.target.name, $event.target.files); fileCount = $event.target.files.length"
                onclick="this.value=null;"
                :accept="acceptFileType" 
                class="input-file">
    </form>
</template>
<script>
const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;
import Token from './authentication/token'
import { AppState } from './appstate';

export default {
    props: ['assetType', 'acceptFileType'],
    mixins: [
        Token
    ],
    
    data() {
        return {
            currentStatus: STATUS_INITIAL,
            uploadedFiles: [],
            uploadFieldName: 'uploads'
        }
    },

    computed: {
        isInitial() {
           return this.currentStatus === STATUS_INITIAL;
        },
        isSaving() {
            return this.currentStatus === STATUS_SAVING;
        },
        isSuccess() {
            return this.currentStatus === STATUS_SUCCESS;
        },
        isFailed() {
            return this.currentStatus === STATUS_FAILED;
        }
    },

    methods: {
        requestChooseFile() {
            this.reset();
            this.$refs.fileUploadButton.click();
        },

        reset() {
            // reset form to initial state
            this.currentStatus = STATUS_INITIAL;
            this.uploadedFiles = [];
            this.uploadError = null;
        },

        async save(formData) {
            let result = {
                status: STATUS_INITIAL,
                error: null,
                fileUrl: null,
            };

            // upload data to the server

            this.currentStatus = STATUS_SAVING;
            if(!this.assetType){
                this.assetType = "";
            }

            try {
                this.$emit('uploadStarted');
                
                let route = this.assetType == 'profile'
                    ? '/api/profilepic'
                    : '/api/asset/?contentPath=' + this.assetType + '&showCode=' + AppState.showCode;

                let x = await this.tryPost(route, formData);
                this.$emit('uploadSuccess', x.Result);
                this.uploadedFiles = [].concat(x);
                this.currentStatus = STATUS_SUCCESS;
                this.reset();

                result.status = STATUS_SUCCESS;
                result.fileUrl = x.Result;

            } catch(err) {
                console.error("File upload failed", err);
                this.$emit('uploadFailed');
                this.uploadError = err.response;
                this.currentStatus = STATUS_FAILED;

                result.status = STATUS_FAILED;
                result.error = err.response;
            }

            return result;
        },

      async uploadFile(toUpload) {
          let data = new FormData();
          data.append('file', toUpload, toUpload.name);
          
          let result = await this.save(data);

          return result;
      },

      filesChange(fieldName, fileList) {
        // handle file changes
        const formData = new FormData();
        if (!fileList.length) 
        {
            console.log("Cancel upload")
            this.$emit('cancel-upload');
            return;
        }
        // append the files to FormData
        Array
          .from(Array(fileList.length).keys())
          .map(x => {
            formData.append(fieldName, fileList[x], fileList[x].name);
          });
        // save it
        this.save(formData);
      }
    },
    mounted() {
        this.reset();
    },
}
</script>
