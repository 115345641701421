import { createApp } from 'vue'
import App from './App.vue'
import router from './router.js'

// Styles

import './styles/reset.css'
import './styles/colors.css'

import './styles/page-defaults.sass'
import './styles/input-defaults.sass'
import './styles/common-controls.sass'
import './styles/buttons.sass'
import './styles/main-app-layout.sass'
import './styles/components.sass'
import './styles/profile-editor.sass'
import './styles/call.window.sass'

import './styles/style.css'
import './styles/main-index-page.css'
import './styles/entity-details.css'
import './styles/layouts/main-layout.css'
import './styles/size-adjust.css'

import 'intl-tel-input/build/css/intlTelInput.css';
import 'video.js/dist/video-js.min.css'


import Loading from './components/loading.vue'

import LoadingContainer from './components/loading.container.vue'

import PostsList from './components/posts.list.vue'
import ActivityList from './components/activity.list.vue'


import ContactInfoList from './components/contact.info.list.vue'
import FileUpload from './components/file.upload.vue'
import VueTitle from './components/title.vue'
import VideoPlayer from './components/video.player.vue';

import PollAnswer from './components/poll.answer.vue'
import PollAnswerControl from './components/poll.answer.control.vue'

// import VueAWN from "vue-awesome-notifications"
import AWN from 'awesome-notifications'

import { vue3Debounce } from 'vue-debounce'

import SessionListItem from './components/list-items/session.vue';
import PageListItem from './components/list-items/page.vue'
import PageDeluxeListItem from './components/list-items/page.deluxe.vue'
import AttendeeListItem from './components/list-items/attendee.vue'
import AttendeeNetworkListItem from './components/list-items/attendee.network.vue'
import FromIndicator from './components/from.indicator.vue';
import HomeItem from './components/list-items/home.item.vue';
import ChatRoomItem from './components/list-items/chatroom.vue'

import AttendeeChooser from './components/attendee.chooser.vue';
import Discussionlist from './components/discussion.list.vue';
import DiscussionArea from './components/discussion.area.vue';

import ImgGraceful from './components/img.graceful.vue'
import LinkifyArea from './components/linkify.area.vue';
import ContactInfoItem from './components/contact.info.item.vue'

import LitePollEditor from './components/lite.poll.editor.vue';
import LitePollAnswer from './components/lite.poll.answer.vue';

import InlineNetworkList from './components/inline.network.list.vue';
import InlineNotificationsList from './components/inline.announcement.list.vue';

import FavoritesList from './components/favorites.list.vue';
import MediaContainer from './components/media.container.vue';
import AgendaScheduleSidebar from './components/agenda.schedule.sidebar.vue';

import ContactInfoEditorPane from './components/contact.info.editor.pane.vue';
import PageDetailEditorPane from './components/page.detail.editor.pane.vue';

import QaManagement from './components/qa.management.vue'

import moment from 'moment'
import 'moment-timezone';

import marked from 'marked'

// import { Datetime } from 'vue-datetime'

// You need a specific loader for CSS filesy
// import 'vue-datetime/dist/vue-datetime.css'

import { EventBus, EventBusLogLevels } from "@agfinn/yet-another-bus"
import { AppState } from './components/appstate.js'
window.HELP_IMPROVE_VIDEOJS = false;

window.$markdown = {}
window.$markdown.linkRenderer = new marked.Renderer()
window.$markdown.linkRenderer.link = function (href, title, text) {
    let link = marked.Renderer.prototype.link.apply(this, arguments)
    return link.replace("<a ", "<a target='_blank' ")
}

marked.setOptions({
    renderer: window.$markdown.linkRenderer
})


let awnOptions ={
    position: "top-right",
    maxNotifications: 6
};

window.$bus = new EventBus()

const app = createApp(App)

app.config.globalProperties.$bus = window.$bus;
console.log("WINDOW BUS:", window.$bus)

app.component('loading', Loading)
    .component('loading-container', LoadingContainer)
    .directive('debounce', vue3Debounce({lock: true})) 
    .component('contact-info-editor-pane', ContactInfoEditorPane)
    .component('page-detail-editor-pane', PageDetailEditorPane)
    .component('qa-management', QaManagement)

    .component('lite-poll-editor', LitePollEditor)
    .component('lite-poll-answer', LitePollAnswer)
    
    .component('img-g', ImgGraceful)
    .component('poll-answer',PollAnswer)
    .component('poll-answer-control',PollAnswerControl)
    
    .component('hls-video-player', VideoPlayer)
    .component('vue-title', VueTitle)
    .component('file-upload', FileUpload)
    .component('posts-list', PostsList)
    .component('activity-list', ActivityList)
    .component('contact-info-list', ContactInfoList)
    
    .component('session-item', SessionListItem)
    .component('page-item', PageListItem)
    .component('page-item-deluxe', PageDeluxeListItem)
    .component('attendee-list-item', AttendeeListItem)
    .component('attendee-network-list-item', AttendeeNetworkListItem)
    .component('from-indicator', FromIndicator)
    .component('home-item', HomeItem)
    .component('chat-room-item', ChatRoomItem)
    .component('attendee-chooser', AttendeeChooser)
    
    .component('chat-list', Discussionlist)
    .component('chat-area', DiscussionArea)
    
    .component('network-list', InlineNetworkList)
    .component('announcement-list', InlineNotificationsList)
    
    .component('loading',Loading)
    .component('loading-container', LoadingContainer)
    .component('linkify-area', LinkifyArea)
    .component('contact-info-item', ContactInfoItem)
    .component('favorites-list', FavoritesList)
    .component('media-container', MediaContainer)
    .component('agenda-schedule', AgendaScheduleSidebar)    

// Components that NEED to be replaced:
//.component('vue-cal', VueCal)
//    .use(require('vue-moment'));

window._userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

app.config.globalProperties.$awn = new AWN(awnOptions)

app.config.globalProperties.$filters = {};

function momentFormat(value, format) {
    let toReturn = moment(value);

    if(format) {
        return toReturn.format(format);
    }

    return toReturn;
}

console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);

function momentFormatTz(value, format) {
    try {
        let tz = '';
        //tz = "America/Los_Angeles";
        if(AppState.show.ShowTimeZone) {
            tz = AppState.show.ShowTimeZone;
        }
    
        if(!tz) {
            console.warn("No timezone added...");
            return momentFormat(value, format);
        }
    
        let toReturn = moment(value);
    
        if(format) {
            return toReturn.tz(tz).format(format);
        }
    
        return toReturn;    
    } catch {
        return momentFormat(value, format);
    }
}

function momentFormatLocalTz(value, format) {
    try {
        let tz = window._userTimeZone;

        if(!tz) {
            console.warn("No timezone added...");
            return momentFormat(value, format);
        }
    
        let toReturn = moment(value);
    
        if(format) {
            return toReturn.tz(tz).format(format);
        }
    
        return toReturn;    
    } catch {  
        return momentFormat(value, format);
    }
}


app.config.globalProperties.$filters['moment'] = function(value, format) {
    return momentFormat(value, format);
};

app.config.globalProperties.$filters['momentEventTz'] = function(value, format) {
    return momentFormatTz(value, format);
};

app.config.globalProperties.$filters['momentLocalTz'] = function(value, format) {
    return momentFormatLocalTz(value, format);
};

let style = document.getElementById('theme-stylesheet');
if(!style) {
    style = document.createElement('style');
    style.setAttribute('id', 'theme-stylesheet');
    document.head.appendChild(style);
}

window.$signalRConnection = { notInitialized: true };

app.directive("click-outside", {
    bind(el, binding, vnode) {

        el.keypressEvent = (event) => {
            if(event.key == 'Escape') {
                vnode.context[binding.expression]();
            }
        };

        el.clickOutsideEvent = (event) => {

            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };

        document.body.addEventListener("click", el.clickOutsideEvent);

        document.body.addEventListener("keydown", el.keypressEvent);
    },
    unbind(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
        document.body.removeEventListener("keydown", el.keypressEvent);
    },
})


app.use(router)

app.mount('#app')
