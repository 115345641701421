<template>
    <div class="qa-moderate-console"
         ref="mainScroller">
        <div>
            <div class="qa-controls">
                <label>Sort Order</label>
                <select v-model="filterMode">
                    <option v-for="filterMode in filterModes"
                            :key="filterMode.Code"
                            :value="filterMode.Code">
                        {{filterMode.Name}}
                    </option>
                </select>
                <label>
                    <input type="checkbox"
                        v-model="hideAnswered">
                    Hide Answered Questions
                </label>

                <div class="button-group">
                    <button @click="clearProjectedPosts">
                        Clear Projected Post
                    </button>
                </div>
            </div>
            
            <template v-if="postList.length > 0">
                <QaPost v-for="post in postList"
                        :key="post.Id"
                        :post="post"
                        :selectedPost="selectedPost"
                        :activePost="activePost"
                        @click.native="selectPost(post)"
                        @toggle-answered="toggleAnswered"
                        @send-active-question="onSendActiveQuestion"
                        @project-question="onProjectQuestion"
                        :class="{'selected': selectedPost == post.Id}">
                </QaPost>
            </template> 
            <template v-else>
                <div class="empty-qa-list-message">
                    Post list is empty.
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import QaPost from './qa.post.moderate.item.vue'
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'

export default {
    components: {
        QaPost
    },

    props: ['attendee', 'code', 'posts'],

    mixins: [
        Token,
        Common
    ],

    data() {
        return {
            filterModes: [
                {
                    Code: 'HighestVoted',
                    Name: 'Highest Voted Posts'
                },
                {
                    Code: 'MostRecent',
                    Name: "Most Recent Posts"
                },
                {
                    Code: "LeastRecent",
                    Name: "Oldest Posts"
                }
            ],
            hideAnswered: false,
            filterMode: 'HighestVoted',
            selectedPost: null,
            activePost: null,
        }
    },  

    computed: {

        postList() {
            this.selectedPost;
            this.activePost;
            
            if(!this.posts) {
                return [];
            }

            let allPosts = this.posts.filter(x => !x.ReplyToId);

            if(this.hideAnswered) {
                allPosts = allPosts.filter(x => !x.IsAddressed);
            }

            if(this.filterMode == 'HighestVoted') {

                allPosts = allPosts.sort((a, b) => {
                    if(a.Likes.length > b.Likes.length) {
                        return -1;
                    } else if(a.Likes.length < b.Likes.length) {
                        return 1;
                    } 

                    return 0;
                });

            } else if(this.filterMode == 'MostRecent') {

                allPosts = allPosts.sort((a, b) => {
                    if(a.Id > b.Id) {
                        return -1;
                    } else if(a.Id < b.Id) {
                        return 1;
                    } 

                    return 0;
                });

            } else if(this.filterMode == 'LeastRecent') {

                allPosts = allPosts.sort((a, b) => {
                    if(a.Id > b.Id) {
                        return 1;
                    } else if(a.Id < b.Id) {
                        return -1;
                    } 

                    return 0;
                });

            }

            if(this.activePost) {
                allPosts = allPosts.sort((a, b) => {
                    if(a.Id == this.activePost) {
                        return -1;
                    }

                    return 0;
                });                
            }


            if(this.selectedPost) {
                allPosts = allPosts.sort((a, b) => {
                    if(a.Id == this.selectedPost) {
                        return -1;
                    }

                    return 0;
                });                
            }

            return allPosts;
        }
    },

    watch: {
        code(to, from) {
            this.build();
        }
    },

    methods: {
        clearProjectedPosts() {
            this.tryPut(`/api/discussion/post/clear/${AppState.showCode}/${this.code}`);
        },

        onProjectQuestion(post) {
            this.tryPut(`/api/discussion/post/active/${AppState.showCode}/${this.code}/${post.Id}?project=true`);
        },

        onSendActiveQuestion(post) {
            this.tryPut(`/api/discussion/post/active/${AppState.showCode}/${this.code}/${post.Id}`);
        },

        onWebinarControlMessage(message) {
            if(message.Type != 'ActivePostChange') {
                return;
            }

            let post = this.posts.find(x => x.Id == message.Code);

            if(!post) {
                return;
            }

            this.activePost = message.Code;
        },

        selectPost(post) {
            if(this.selectedPost == post?.Id) {
                return;
            }

            this.selectedPost = post?.Id;

            if(this.selectedPost) {
                this.$refs.mainScroller.scrollTo(0, 0);
            }
        },

        async toggleAnswered(post) {
            post.IsAddressed = !post.IsAddressed;

            try {
                let r = await this.tryPost(
                    `/api/discussion/post`, 
                    JSON.stringify(post), 
                    'application/json');
            } catch {

            }
        },

        async build() {
        },
    },

    beforeUnmount() {
        window.$bus.$off('webinar-control-message', this.onWebinarControlMessage);
    },

    mounted() {
        this.build();
        window.$bus.$on('webinar-control-message', this.onWebinarControlMessage);
    }
}
</script>