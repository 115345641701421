<template>
    <router-link class="list-item session-list-item"
                 :class="[`S-${categories[0].Code}-tab`]"
                 :to="{name: 'agendaDetails', params:{ code: session.Code}}">

        <div class="left-column">
            <span class="time-marker" v-if="localTimeZoneEqualsShowTimeZone">
                {{$filters.moment(session.SessionStartDateTime, 'LT')}} - {{$filters.moment(session.SessionEndDateTime, 'LT')}}
            </span>
            <span class="time-marker" v-else>
                {{$filters.momentEventTz(session.SessionStartDateTime, 'LT z')}} - {{$filters.momentEventTz(session.SessionEndDateTime, 'LT z')}}
            </span>
            <div class="category-pills">
                <div v-for="c in categories" 
                    :key="c.Code"
                    :class="`pill S-${c.Code}-Pill`">
                    {{c.Name}}
                </div>
            </div>
        </div>
        <div class="main-column">
            <span class="title">
                <div class="online-icon online" 
                     v-if="isLive"></div>
                {{session.Name}}</span>
            <span class="location"
                  v-if="session.Location">
                <span class="icomoon"></span>{{session.Location}}
            </span>
            <span class="description">
                {{session.Headlines || session.ContactInfo.Bio}}
            </span>
            <span class="featured-speakers"
                v-if="speakers.length > 0">
                <span v-for="speaker in speakers" class="speaker">
                    <div class="photo-icon">
                        <img-g :src="getAttendeeProfilePhotoUrl(show.Code, speaker.Value)" 
                            :fallbackSrc="'/img/person.png'" />
                    </div>
                    <span>{{speaker.DisplayText}}</span>
                </span>
            </span>
        </div>
        <div class="right-column">
            <button type="button"
                    class="button-outline square-button icomoon"
                    :class="[sessionModel.CanEditFavorite ? '' : 'button-noninteractive']"
                    @click.stop.prevent="emitFavoriteClick">
                {{sessionModel.IsFavorite ? '' : ''}}
            </button>
        </div>

    </router-link>          
</template>
<script>
import Common from '../common'
import moment from 'moment'

import { AppState } from '../appstate';

export default {
    props: ['sessionModel'],
    mixins: [
        Common
    ],   

    computed: {
        show() {
            return AppState.show;
        },

        localTimeZoneEqualsShowTimeZone() {
            try {
                return !AppState.show.ShowTimeZone || window._userTimeZone == AppState.show.ShowTimeZone;
            } catch {
                return true;
            }
        },

        isLive() {
            return this.session.PrimaryMedia
                && this.session.PrimaryMedia.MediaType == 'MuxLive'
                && this.session.PrimaryMedia.Uri;
        },

        session() {
            return this.sessionModel.Session;
        },

        categories() {
            let toReturn = [];

            let primaryCategory = this.primaryCategory;

            if(primaryCategory) {
                toReturn.push(primaryCategory);
            }

            if(this.sessionModel.Session.SubCategoryCodes) {

                for(let i = 0; i < this.sessionModel.Session.SubCategoryCodes.length; i++) {
                    let candidate = this.sessionModel.Session.SubCategoryCodes[i];

                    let category = this.show.SessionCategories.find(x => x.Code == candidate);
                    if(category && !category.IsHidden) {
                        toReturn.push(category);
                    }
                }
            }

            return toReturn;
        },

        primaryCategory() {
            return this.show.SessionCategories.find(x => x.Code == this.session.Category.Code);
        },

        duration() {
            return `${moment(this.session.SessionEndDateTime).diff(this.session.SessionStartDateTime, 'minutes')} minutes`;
        },
    },

    methods: {
        emitFavoriteClick() {
            let sessionModel = this.sessionModel;

            if(!sessionModel.CanEditFavorite) {
                return;
            }

            window.$bus.$emit('request-toggle-favorite', {
                Type: "SESSION",
                Code: sessionModel.Session.Code,
                ReadOnly: false,
                AllowEditEntity: sessionModel.IsPageAdmin,
                Active: !sessionModel.IsFavorite
            })
        }
    },

    data() {
        return {
            speakers: []
        }
    },

    created() {
        let sessionContactInfo = this.session.ContactInfo
            ? this.session.ContactInfo.ContactInfo
            : null;

        if(!sessionContactInfo) {
            return;
        }

        let speakers = sessionContactInfo.filter(x => x.Value.indexOf('//ATTENDEE') != -1);

        if(!speakers || speakers.length == 0) {
            return;
        }

        this.speakers = speakers;
    }
}
</script>