<template>
    <div class="page-side-panel">
        <div class="header-actions">
            <span class="title">Analytics</span>
            <div class="actions">            
                <button class="icomoon" @click="exportToCsv">
                    
                </button>
                <button class="icomoon" @click="closePage">
                    
                </button>
            </div>
        </div>
        
        <div class="page-side-panel-content">
            <div class="submit-request" v-if="submittingRequest">
                <loading></loading>
            </div>
            <div class='page-side-panel-list'
                 v-else>
                <attendee-network-list-item v-for="item in viewedBy"
                                    :key="item.Code"
                                    :attendee="item.Attendee"
                                    :class="[$route.params && ($route.params.discussionCode && $route.params.discussionCode == item.Code) || ($route.params.code == item.Code) ? 'list-item-selected' : '']"
                                    :online="item.IsOnline"
                                    class="attendee-network-list-item-right-action">
                    <template slot="actions">
                        <div class="attendee-card-actions">
                            <button type="button"
                                    class="button-outline icomoon round-button"
                                    @click.stop="sendMessage(item.Code)">
                                
                            </button>
                        </div>
                    </template>
                    <template slot="extra-details">
                        <span class="small-subtitle">
                            <from-indicator :time="item.LastViewedAt"></from-indicator>
                        </span>
                    </template>
                </attendee-network-list-item>    
            </div>
        </div>
    </div>
</template>
<script>
import Common from './common'
import Token from './authentication/token'
import { AppState } from './appstate';;
import moment from 'moment'

export default {
    mixins: [
        Token,
        Common
    ],   

    props: ['attendee', 'code', 'typeCode'],

    watch: {
    },

    methods: {
        closePage() {
            this.goBackOneRoute();
        },

        exportToCsv() {

            let csvFile = 'Name,Headline,Title,Last Visit Date/Time\n';

            for(let i = 0; i < this.viewedBy.length; i++) {
                let booking = this.viewedBy[i];

                csvFile += `"${booking.Attendee.DisplayName}","${booking.Attendee.ContactInfo?.Title || ''}","${booking.Attendee.ContactInfo?.SubTitle || ''}","${moment(booking.LastViewedAt).format("YYYY-MM-DD HH:mm")}"\n`;
            }

            let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });

            let link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                let url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", 'export_page_views.csv');
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }

        },

        async sendMessage(booking) {
            let routeToFetch = `/api/chats/${AppState.showCode}/VENDOR/${this.code}/${booking.Code}`;

            try {
                let result = await this.tryGet(routeToFetch);
                console.log(result);

                if(!result?.Result?.Discussion) {
                    throw "Not found";
                }

                let route = {
                    name: 'DiscussionForVendor', 
                    params: { code: this.code, typeCode: 'vendor', discussionCode: result.Result.Discussion.Code }};
                
                this.$router.push(route);

            } catch(ex) {
                console.error(ex);
                this.$awn.alert("Could not start the chat. Please try again later.");
            }
        },

        async build() {
            this.submittingRequest = true;
            await this.refresh();

            await this.connectToControlChannel();
            this.submittingRequest = false;

            this.postAnalytics(
                AppState.attendeeCode,
                'PageProfileAnalytics',
                this.code,
                this.typeCode,
                AppState.showCode);
        },

        async refresh() {
            try {
                let views = await this.tryGet(`/api/profileviews/${AppState.showCode}/${this.typeCode.toUpperCase()}/${this.code}`);

                if(views.Result) {
                    this.viewedBy = views.Result;
                } else {
                    throw 'error';
                }
            } catch {
                this.$awn.alert("We couldn't load your analytics. Please try again later.");
                this.closePage();
            } finally {
            }
        },

        async connectToControlChannel(){
            if(window.$signalRConnection.notInitialized) {
                return;
            }

            window.$signalRConnection.invoke(
                'RegisterForPageAnalytics', 
                `${AppState.showCode}_${this.typeCode}_${this.code}`.toUpperCase());
        },        
    },

    data() {
        return {
            viewedBy: [],
            submittingRequest: false,
        }
    },
    
    created() {
        this.build();
    },

    mounted() {
        window.$bus.$on('profile-view', this.refresh);
        window.$bus.$on('connected-to-signalr', this.connectToControlChannel);            
    },

    beforeUnmount() {
        window.$bus.$off('profile-view', this.refresh);
        window.$bus.$off('connected-to-signalr', this.connectToControlChannel);       
        window.$signalRConnection.invoke(
            'UnregisterForPageAnalytics', 
            `${AppState.showCode}_${this.typeCode}_${this.code}`.toUpperCase());

    },
}
</script>