<template>
    <div class="fixed-content-width detail-page-container">
        <div class="notfound-404">
            <h1>404. Couldn't find what you were looking for.</h1>

            <p>We're sorry. We couldn't find what you were looking for. We're working on fixing the problem.</p>

            <router-link :to="{name: 'home'}"
                         class="btn">
                Go Home
            </router-link>
        </div>
    </div>
</template>
<script>

export default {
}
</script>