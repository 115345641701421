<template>
  <div class="html-content-page"
       :class="{'html-content-page-no-scroll': source.startsWith('http')}">
    <vue-title :title="title" :brand="true"></vue-title>
    <iframe allow="camera;microphone;display-capture;fullscreen;usb;midi;" v-if="source.startsWith('http')" :src="source"></iframe>
    <div v-else v-html="source"></div>
  </div>
</template>
<script>
// import Vue from "vue";
import Token from "./authentication/token";
import Common from "./common";
import { AppState } from "./appstate";

export default {
  mixins: [Token, Common],

  props: ["sectionCode"],
    watch: {
        sectionCode() {
            this.build();
        }
    },

    data() {
        return {
            source: ""
        };
    },

    beforeUnmount() {
        window.removeEventListener('message',this.onReceiveWindowMessage);
    },

    computed: {
        title() {
            return AppState.show.Sections[this.sectionCode].Title;
        }
    },

    methods: {
        onReceiveWindowMessage(e){

            if(!e.data || e.data == undefined){
                return;
            }
            if(!e.data.messageType || e.data.messageType == undefined){
                return;
            }

            if(e.data.messageType == 'NavigateUri'){
                let uri = e.data.uri;
                if(!uri.startsWith("tractus://")){
                    return;
                }

                let url = new URL(uri);

                let searchDetails = url.searchParams;

                let showCode = searchDetails.get("s");
                
                if(AppState.showCode != showCode){
                    return;
                }

                this.openLink(uri, this.attendee);

            }
        },
        build() {
            this.postAnalytics(
                AppState.attendeeCode,
                `HtmlContent-${this.sectionCode}`,
                this.sectionCode,
                "Section",
                AppState.showCode
            );
            let source = AppState.show.Sections[this.sectionCode].Content;
            if(source.startsWith('INTERNALEMBED:::')) {

                source = source.replace('INTERNALEMBED:::', '');

                if(source.indexOf('{{showCode}}') != -1) {
                    source = source.replace('{{showCode}}', AppState.showCode);
                } 

                if(source.indexOf('{{attendeeCode}}') != -1) {
                    source = source.replace('{{attendeeCode}}', AppState.attendeeCode);
                }
            }
            this.source = source;
        },
    },

    mounted() {
        window.addEventListener('message', this.onReceiveWindowMessage);
        this.build();
    }
};
</script>