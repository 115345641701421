<template>
    <div v-if="submitting"
         class="fixed-content-width">
        <loading-container />
    </div>
    <div v-else
         class="page-container">
        <div class="list-page-full">
            <div v-if="isInline"
                class="header-actions">
                <div class="title">
                    My Bookmarks
                </div>
                <div class="header-buttons">
                    <button class="icomoon" 
                            @click="requestClose()">
                        
                    </button>
                </div>
            </div>
            <div class="list-page-contents"
                 v-if="vendors.length == 0 && sessions.length == 0 && attendees.length == 0">

                <div class="category-item">
                    <div class="category-title">
                        You have no bookmarks.
                    </div>
                </div>
            </div>
            <div class="list-page-contents">
                <div class="category-item"
                     v-if="vendors.length > 0">
                    <div class="category-item-header">
                        <span class="category-title">
                            Pages
                        </span>
                    </div>
                    <div class="category-items">                        
                        <template v-for="item in vendors">
                            <router-link v-if="item.Type == 'VENDOR'"
                                         :to="{name: 'vendorDetails', params: {code: item.Code}}"
                                         :key="item.Code"
                                         class="favorite-list-item list-item">
                                <div>
                                    <img-g :src="item.Entity.ContactInfo.PhotoUrl"
                                        fallbackSrc="/img/person.png" />
                                    
                                    <div>
                                        <span class="title">
                                            {{item.Entity.Name}}
                                        </span>
                                        <span class="subtitle">
                                            {{item.Entity.Headlines}}
                                        </span>
                                    </div>

                                </div>
                            </router-link>
                        </template> 
                    </div>
                </div>

                <div class="category-item"
                     v-if="attendees.length > 0">
                    <div class="category-item-header">
                        <span class="category-title">
                            Attendees
                        </span>
                    </div>
                    <div class="category-items">                        
                        <template v-for="item in attendees">
                            <router-link v-if="item.Type == 'ATTENDEE'"
                                         :to="{name: 'attendeeDetails', params: {code: item.Code}}"
                                         :key="item.Code"
                                         class="favorite-list-item list-item">
                                <div>
                                    <img-g :src="item.Entity.ContactInfo.PhotoUrl"
                                           class="round-photo"
                                        fallbackSrc="/img/person.png" />
                                    <div>
                                        <span class="title">
                                            {{item.Entity.DisplayName}}
                                        </span>
                                        <span class="subtitle"
                                              v-if="item.Entity.ContactInfo && item.Entity.ContactInfo.Title">
                                            {{item.Entity.ContactInfo.Title}}
                                        </span>
                                    </div>
                                </div>
                            </router-link>
                        </template> 
                    </div>
                </div>

                <div class="category-item"
                     v-if="sessions.length > 0">
                    <div class="category-item-header">
                        <span class="category-title">
                            Sessions
                        </span>
                    </div>
                    <div class="category-items">                        
                        <template v-for="item in sessions">
                            <router-link v-if="item.Type == 'SESSION'"
                                         :to="{name: 'agendaDetails', params: {code: item.Code}}"
                                         :key="item.Code"
                                         class="favorite-list-item session-favorite-list-item list-item">
                                <div>
                                    <span class="title">
                                        {{item.Entity.Name}}
                                    </span>
                                    <span class="subtitle">
                                        <span class="icomoon"></span>{{$filters.moment(item.Entity.SessionStartDateTime, 'dddd MMMM Do')}}
                                    </span>
                                    <div class="category-code pill"
                                          :style="`background: var(--S-${item.Entity.Category.Code});`">
                                        {{getSessionCategoryName(item.Entity.Category.Code)}}
                                    </div>
                                </div>
                            </router-link>
                        </template> 
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'

export default {
    mixins: [
        Token,
        Common
    ],   

    props: ['attendee','isInline'],

    computed: {
        vendors() {
            return this.favoritesList.filter(x => x.Type == 'VENDOR');
        },

        sessions() {
            return this.favoritesList.filter(x => x.Type == 'SESSION');
        },

        attendees() {
            return this.favoritesList.filter(x => x.Type == 'ATTENDEE');
        }
    },

    methods: {
        getSessionCategoryName(code) {
            let foundCategory = AppState.show.SessionCategories.find(x => x.Code == code);

            if(foundCategory) {
                return foundCategory.Name;
            }

            return "Session";
        },

        requestClose() {
            this.$emit('request-close');
        },

        async build(){
            this.submitting = !this.favoritesList;
            try{
                let r = await this.tryGet("/api/favorites/my");
                this.favoritesList = r.Result;
            }
            catch(ex){

            }
            this.submitting = false;
        },

        onEntityUpdated(entity) {
            if(!entity) {
                return;
            }

            const foundEntity = this.favoritesList.find(x => x.Code == entity.EntityCode);

            if(!foundEntity) {
                return;
            }

            this.build();
        }
    },

    data() {
        return {
            favoritesList: null,
            submitting: true,
        }
    },

    beforeUnmount() {
        window.$bus.$off('favorites-updated', this.build);
        window.$bus.$off('entity-updated', this.onEntityUpdated);
    },

    created: function() {
        window.$bus.$on('favorites-updated', this.build);
        window.$bus.$on('entity-updated', this.onEntityUpdated);
        this.build();
    },
}
</script>