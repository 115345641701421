<template>
    <div v-if="!submitting"
         class="login-screen">
        <vue-title :title="'Tractus Passport | Login'"></vue-title>
        <form class="loginForm">
            <img-g :src="details.LogoUrl" class="responsive" />

            <input
                v-model="attendeeCode"
                autocomplete="off"
                autocorrect="off"
                :disabled="submitting"
                maxlength="16"
                class="form-control"
                placeholder="Attendee Code"
            />

            <input
                v-model="attendeePin"
                :disabled="submitting"
                inputmode="numeric"
                maxlength="16"
                type="password"
                class="form-control"
                placeholder="PIN"
            />

            <div
                v-if="validateErrors && validateErrors.length > 0"
                class="alert alert-danger text-left"
                role="alert">
                Oops! It looks like something happened. Please double-check and
                try again.
                <ul>
                    <li v-for="error in validateErrors">
                        {{ error }}
                    </li>
                </ul>
            </div>

            <button @submit.prevent
                    type="button"
                    @click="onLogin">
                Sign In
            </button>

            <router-link style="cursor: pointer; padding-top:1em;"  :to="{name: 'SelfServe', params: {showCode: showCode}}">
                Forgot your Sign-In Details?
            </router-link>
           
        </form>
    </div>
    <div v-else class="main-page-loading-container">
        <loading-container  />
    </div>
</template>
<script>
import Common from "./common";

// import Vue from "vue";
import Token from "./authentication/token";

export default {
    mixins: [Token, Common],
    props: ['showCode'],
    data() {
        return {
            validateErrors: [],
            submitting: false,
            attendeePin: "",
            attendeeCode: "",
            details: null,
        };
    },

    created: function () {
       this.onLoad();
    },

    computed:{
        title(){
            if(!this.details){
                return "";
            }
            return `Sign into ${this.details.Name}`
        }
    },

    methods: {
        async onLoad(){
            this.submitting = true;
            try{
                let r = await this.tryGet(`/api/show/${this.showCode}/welcome`);
                
                if(!r || r.Errors.length > 0){
                    this.$router.push("/login");
                    //navigate back to login
                    return;
                }

                this.details = r.Result;
                document.getElementById('theme-stylesheet').innerHTML = r.Result.StyleCss;

            }
            catch(ex){
                console.error(ex);
            }
            this.submitting = false;
        },
      

        validateForm: function () {
            this.validateErrors = [];

            if (!this.attendeeCode) {
                this.validateErrors.push("Attendee code cannot be blank.");
            }

            if (!this.attendeePin) {
                this.validateErrors.push("PIN cannot be blank.");
            }

            return this.validateErrors.length === 0;
        },

        async onLogin(ev) {

            this.clear();
            localStorage.clear();

            this.submitting = true;

            if (!this.validateForm()) {
                this.submitting = false;
                return;
            }

            try {
                let r = await this.tryAttendeeLogin(
                    this.attendeeCode,
                    this.attendeePin,
                    this.showCode
                );

                if (r.Errors && r.Errors.length > 0) {
                    this.validateErrors.push(r.Errors[0]);
                } else {
                    if(this.$route.query.redirect) {
                        this.$router.push(this.$route.query.redirect);
                    } else {
                        this.$router.push("/");
                    }
                }

            } catch(ex) {
                this.validateErrors.push(ex);
            }

            this.submitting = false;
        },
    },

    beforeUnmount() {
        document.getElementById('theme-stylesheet').innerHTML = '';
    },
};
</script>