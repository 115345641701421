// Tractus.Data.Discussion.DiscussionPost
// Generated on 2019-11-12 12:53:01 PM by Elias

class DiscussionPost {
	Id = null;
	//public Nullable<System.Guid> ReplyToId
	ReplyToId = null;
	//public String DiscussionAreaCode
	DiscussionAreaCode = null;
	//public DateTime PostedDateTime
	PostedDateTime = new Date();
	//public Boolean IsSticky
	IsSticky = false;
	//public System.String[] Likes
	Likes = [];
	//public String DisplayName
	DisplayName = null;
	//public String AttendeeCode
	AttendeeCode = null;
	//public String ApprovalCode
	ApprovalCode = null;
	//public String Body
	Body = null;
	//public Boolean IsAnonymous
	IsAnonymous = false;
	//public String TimeStamp
	TimeStamp = null;

	PostTypeCode = null;

	Images = [];

	Link = null;

	LinkTypeCode = null;

    IsAddressed = false;
}
export default DiscussionPost
