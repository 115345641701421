<template>
    <router-link class="attendee-network-list-item list-item"
                 :to="{name: destinationRouteNameDetails, params: {code: attendee.Code}}">
        <div>
            <img-g :src="attendee.ContactInfo.PhotoUrl"
                   fallbackSrc="/img/person.png" />
            
            <div>
                <span class="title">
                    <div class="online-icon" v-if="online || isOnline(attendee.Code)"></div>
                    {{attendee.DisplayName}}
                </span>
                <span class="subtitle contact-title">
                    {{attendee.ContactInfo.Title}}
                </span>
                <span class="subtitle" v-if="attendee.ContactInfo.SubTitle">
                    {{attendee.ContactInfo.SubTitle}}
                </span>

                <slot name="extra-details">

                </slot>
            </div>
        </div>
        <slot name="actions">

        </slot>
    </router-link>
</template>
<script>
import Common from "../common";
import Token from '../authentication/token'
import { AppState } from "../appstate";

export default {
    mixins: [Common, Token],
    props: [
        'attendee', 
        'onlineAttendeeCodes', 
        'online',
        'show', 
        'displayCategories', 
        'filteredCategories',
    ],

    computed: {
        isMe() {
            return AppState.attendeeCode == this.attendee?.Code;
        },

        destinationRouteNameDetails() {
            return "attendeeDetails";
        },

        destinationRouteNameChat() {
            return 'DiscussionForAttendee';
        }

    },
}
</script>