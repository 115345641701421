<template>
    <div class="page-side-panel">
        <div class="header-actions">
            <span class="title">Page Chats</span>

            <div class="actions">            
                <button class="icomoon" @click="closeShowBookings">
                    
                </button>
            </div>
        </div>

        <div class="page-side-panel-content">
            <div class='booking-table'
                 v-if="!submittingRequest">
                <div v-for="chat in chats"
                        v-bind:key="chat.Discussion.Code"
                        class="booking-item"
                        @click="sendMessage(chat.Discussion.OriginatingAttendeeCode)">
                    <div>
                        <img-g :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${chat.Discussion.OriginatingAttendeeCode}/profile`"
                                fallbackSrc="/img/person.png" />
                        <div>
                            <span class="title">
                                <div class="online-icon" v-if="chat.IsOnline"></div>
                                {{chat.OverrideName}}
                            </span>
                            <span class="subtitle contact-title">
                                {{chat.Title}}
                            </span>
                            <span class="subtitle">
                                {{chat.SubTitle}}
                            </span>
                        </div>
                    </div>                       
                </div>
            </div>
            <div v-if="submittingRequest">
                <loading></loading>
            </div>

        </div>
    </div>
</template>
<script>
import Common from './common'
import Token from './authentication/token'
import { AppState } from './appstate';;
import moment from 'moment'

export default {
    mixins: [
        Token,
        Common
    ],   

    props: ['attendee', 'code', 'typeCode'],

    computed: {
        showCode() {
            return AppState.showCode
        },
    },

    methods: {
        closeShowBookings() {
            this.goBackOneRoute();
        },

        async build() {
            this.submittingRequest = true;
            try {
                await this.loadChats();

                this.postAnalytics(
                    AppState.attendeeCode,
                    'PageChatList',
                    this.code,
                    this.typeCode,
                    AppState.showCode);

            } catch {
                alert("We couldn't load your page chats. Please try again later.");
                this.closeShowBookings();
            }
            this.submittingRequest = false;
        },

        async loadChats() {
            let chats = await this.tryGet(`/api/chats/${AppState.showCode}/${this.typeCode.toUpperCase()}/${this.code}`);

            this.chats = chats.Result || [];
        },

        async sendMessage(attendeeCode) {
            let routeToFetch = `/api/chats/${AppState.showCode}/VENDOR/${this.code}/${attendeeCode}`;

            try {
                let result = await this.tryGet(routeToFetch);
                console.log(result);

                if(!result?.Result?.Discussion) {
                    throw "Not found";
                }

                let route = {
                    name: 'DiscussionForVendor', 
                    params: { code: this.code, typeCode: 'vendor', discussionCode: result.Result.Discussion.Code }};
                
                this.$router.push(route);

            } catch(ex) {
                console.error(ex);
                this.$awn.alert("Could not start the chat. Please try again later.");
            }
        },
    },

    data() {
        return {
            bookings: [],
            submittingRequest: false,
            selectedBooking: null,
            canAdjustScheduleSettings: false,
            filterMode: 'AllChats',
            pageAdmins: [],
            chats: [],
        }
    },
    
    created() {
        this.build();
    },

    mounted() {
    },

    beforeUnmount() {
    },
}
</script>