<template>
    <router-link class="page-deluxe-list-item"
                 :to="{name: 'vendorDetails', params: {code: page.Code}}">

        
        <div class="list-cover-photo-container">
            <div class="cover-photo-holder">
                <img-g :src="page.ListCoverUrl"
                    v-if="page.ListCoverUrl"
                    class="responsive list-cover-photo">

                </img-g>
                <img-g :src="page.ContactInfo.PhotoUrl"
                    v-else-if="page.ContactInfo.PhotoUrl"
                    class="responsive list-cover-photo">
                </img-g>
            </div>
        </div>


        <div class="page-list-item-details">
            
            <img-g :src="page.ContactInfo.PhotoUrl"
                    v-if="page.ContactInfo.PhotoUrl"
                    class="responsive list-item-icon" />
            
            <div class="right-col">
                <span class="title">
                    <div class="online-icon online" 
                            v-if="page.DiscussionWebinarStatus == 'Public'"></div>
                     {{page.Name}}
                </span>
                <span class="location"
                        v-if="page.Location">
                    <span class="icomoon"></span>{{page.Location}}
                </span>
                <span class="subtitle">
                    {{page.Headlines || page.ContactInfo.Bio}}
                </span>
            </div>
        </div>

        <button type="button"
                class="button-outline round-button favorite-button"
                :class="[pageModel.CanEditFavorite ? '' : 'button-noninteractive']"
                @click.stop.prevent="emitFavoriteClick">
            <span class="icomoon">
                {{pageModel.IsFavorite ? '' : ''}}
            </span>
        </button>

    </router-link>
</template>
<script>
export default {
    props: ['pageModel'],

    computed: {
        page() {
            return this.pageModel
                ? this.pageModel.Vendor
                : null;
        }
    },

    methods: {
        emitFavoriteClick() {
            let pageModel = this.pageModel;

            if(!pageModel.CanEditFavorite) {
                return;
            }

            window.$bus.$emit('request-toggle-favorite', {
                Type: "VENDOR",
                Code: pageModel.Vendor.Code,
                ReadOnly: false,
                AllowEditEntity: pageModel.IsPageAdmin,
                Active: !pageModel.IsFavorite
            });

            return false;
        }
    },        
}
</script>