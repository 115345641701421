<template>
    <div class="modal-moderator"
         v-if="showChooser">
        <div class="modal-container">
            <div class="header-actions">
                <div class="title">
                    {{title}}
                </div>
                <div class="header-buttons">
                    <button @click="notifyCancel" 
                            :disabled="submitting"
                            class="icomoon">
                                                
                    </button>
                </div>
            </div>
            
            <input v-model="searchName"
                placeholder="Search by name..."
                :disabled="submitting" />

            <div class="attendee-finder category-items"
                 v-if="!submitting">
                <div v-for="option in searchOptions"
                     v-bind:key="option.Code"
                     class="list-item attendee-finder-list-item"
                     :class="[option.Selected ? 'list-item-selected' : '']"
                     @click="selectOption(option)">
                    <img-g :src="option.Attendee.ContactInfo.PhotoUrl"
                           :fallbackSrc="'/img/person.png'" />
                    <div>
                        <span class="title">
                            <div class="online-icon" v-if="option.IsOnline"></div>
                            {{option.Attendee.DisplayName}}
                        </span>
                        <span class="subtitle">
                            {{option.Attendee.ContactInfo.Title}}
                        </span>
                    </div> 
                </div>
            </div>
            <div v-else class="attendee-finder category-items attendee-finder-loading-placeholder">
                <loading></loading>
            </div>

            <div class="modal-buttons">
                <button @click="notifyCancel">
                    Cancel
                </button>
                <button :disabled="selectedAttendees.length == 0" @click="notifyOk">
                    {{confirmLabel || 'Next'}}
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'

export default {
    props: [
        'showChooser', 
        'selectedCodes',
        'selectedCodesReadOnly', 
        'confirmLabel',
        'title',
        'attendee'
    ],

    mixins: [
        Token,
        Common
    ],   

    data() {
        return {
            searchName: '',
            onlineCodes: [],
            options: [],
        }
    },

    computed: {
        searchOptions() {

            let targetArray = this.options;

            if(this.searchName) {

                let regex = new RegExp(this.searchName, 'i');

                targetArray = targetArray.filter(x => 
                    regex.test(x.Attendee.DisplayName)
                    || x.Selected);
            }

            targetArray = targetArray.sort((a, b) => {
                let isAOnline = this.onlineCodes.indexOf(a.Attendee.Code) != -1;
                let isBOnline = this.onlineCodes.indexOf(b.Attendee.Code) != -1;

                if(!isAOnline && isBOnline) {
                    return 1;
                }

                if(isAOnline && !isBOnline) {
                    return -1;
                }

                return 0;
            });

            return targetArray;
        },


        selectedAttendees() {
            return this.options.filter(x => x.Selected).map(x => x.Code);   
        }
    },

    watch: {
        showChooser() {
            if(this.showChooser) {
                this.options = [];
            }
            this.reloadNetwork();
        },
    },

    methods: {
        selectOption(option) {
            if(option.ReadOnly) {
                return;
            }

            option.Selected = !option.Selected;
        },

        notifyCancel() {
            this.$emit('cancelled');
        },  

        notifyOk() {
            if(this.selectedAttendees.length == 0) {
                return;
            }

            this.$emit('selected', this.selectedAttendees);
        },

        async reloadNetwork() {
            if(!this.showChooser) {
                return;
            }

            this.submitting = true;
            try {

                let r = await this.tryGet(`/api/interactions/mynetwork`);
                this.onlineCodes = r.Result.OnlineCodes;

                let results = r.Result.FullNetwork;
                let myNetwork = r.Result.MyNetworkCodes || [];
                let listOfOptions = [];

                for(let i = 0; i < results.length; i++) {
                    if(results[i].Code == AppState.attendeeCode) {
                        continue;
                    }
                    //selectedCodesReadOnly
                    let selected = this.selectedCodes && this.selectedCodes.find(x => x == results[i].Code);

                    listOfOptions.push({
                        Attendee: results[i].Attendee,
                        Selected: selected,
                        Code: results[i].Code,
                        IsOnline: this.onlineCodes.find(x => x == results[i].Code),
                        IsMyNetwork: myNetwork.find(x => x == results[i].Code),
                        ReadOnly: selected && this.selectedCodesReadOnly
                    });
                }

                this.options = listOfOptions;

            } catch(ex) {
                console.error(ex)
            }

            this.submitting = false;
        },
    },

    created() {
        if(this.showChooser) {
            this.reloadNetwork();
        }
    }
}
</script>