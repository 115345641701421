<template>
    <loading-container v-if="submitting"
                       class="fixed-content-width" />
    <div v-else class="page-container network-list">
        <div class="attendee-list list-page-full">

            <vue-title :title="title" 
                       :brand="true" />

            <div class="list-page-header">
                <div class="search-container">

                    <div class="search-box">
                        <span class="icomoon">
                            
                        </span>
                        <input placeholder="Search" 
                            v-debounce:150="updateSearchTerm"   
                            type="text"/>
                    </div>

                    <div class="text-holder">
                        <span class="category-title"><div class="online-icon"></div>{{onlineCodes.length}} online</span>
                    </div>

                    <div class="button-group filter">
                        <button :class="{'active': showFilters}"
                                @click="showFilters = !showFilters">
                            <span class="icomoon">
                                
                            </span>
                            <span>
                                Filter
                            </span>
                        </button>
                        <button @click="favoritesOnly = !favoritesOnly">
                            <span class="icomoon">
                                
                            </span>
                            <span>
                                My Favorites
                            </span>
                        </button>
                    </div>

                    <div class="list-page-filter"
                         v-show="showFilters">
                        <div class="filter-buttons">
                            <button type="button"
                                    class="button-outline"
                                    v-for="category in categoryList"
                                    :key="category.Code"
                                    @click="selectCategory(category.Code)"
                                    :class="{'active': selectedCategories.length == 0 || selectedCategories.indexOf(category.Code) != -1}">
                                <span class="category-pill" :class="`A-${category.Code}-filter`">

                                </span>
                                {{category.Name}}
                            </button>

                        </div>

                    </div>
                </div>

            </div>

            <div class='list-page-contents' 
                 ref="list"
                 v-debounce:300ms="onScroll"
                 debounce-events="scroll">
                 <div class="category-item">
                     <div class="category-items attendee-list-items">
                        <attendee-list-item v-for="item in filteredResults"
                                    :key="item.Attendee.Code"
                                    :attendee="item.Attendee"
                                    :class="[$route.params && ($route.params.code == item.Code) ? 'list-item-selected' : '']"
                                    :displayCategories="true"
                                    :isFavorite="item.IsFavorite"
                                    :filteredCategories="categoryList">
                            {{item.Attendee.DisplayName}}
                        </attendee-list-item>
                    </div>
                </div>
                
            </div>
        </div>
        <router-view class="right-pane"></router-view>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
export default {
    props: ['sectionCode'],
    mixins: [
        Token,
        Common
    ],   

    computed:{
        title() {
            return AppState.show.Sections[this.sectionCode].Title;
        },
        
        showThinList() {
            return this.$route.name == 'attendeeDetailsAttendeeList'
                || this.$route.name == 'DiscussionForAttendeeAttendeeList';
        },

        filteredResults(){
            if(!this.items) {
                return [];
            }

            let toReturn = Array.from(this.items);

            if(this.favoritesOnly) {
                toReturn = toReturn.filter(x => x.IsFavorite);
            }

            if(this.selectedCategories.length != 0) {
                toReturn = toReturn.filter( x => {
                    var found = false;
                    for(let category of this.selectedCategories){
                        found = x.Attendee.Categories.some(y => y.Code === category);
                        if(found){
                            break;
                        }
                    }
                    return found; 
                })
            }

            if(this.searchTerm && this.searchTerm.length > 0) {
                let searchTest = new RegExp(this.searchTerm, 'i');

                toReturn = toReturn.filter(s => 
                    searchTest.test(s.Attendee.DisplayName) 
                    || searchTest.test(s.Attendee.ContactInfo.Title)
                    || searchTest.test(s.Attendee.ContactInfo.SubTitle));

            }

            toReturn = toReturn.sort((a, b) => {
                let isAOnline = this.onlineCodes.indexOf(a.Attendee.Code) != -1;
                let isBOnline = this.onlineCodes.indexOf(b.Attendee.Code) != -1;

                if(!isAOnline && isBOnline) {
                    return 1;
                }

                if(isAOnline && !isBOnline) {
                    return -1;
                }

                return 0;
            });

            return toReturn;
        }
    },

    watch: {
        sectionCode(to, from) {
            this.loadItems();
        },
        attendee() {
            this.loadItems();
        }
    },

    methods: {

        onFavoritesUpdated(favorites){
            if(!favorites || !favorites.forEach) {
                this.reloadNetwork();
            } else {
                favorites.forEach(f => {
                    if(f.EntityTypeCode != 'ATTENDEE') {
                        return;
                    }

                    let result = this.items.find(x => x.Code == f.EntityCode);

                    console.log(result, this.items, f);
                    if(!result) {
                        return;
                    }

                    result.IsFavorite = f.IsActive;
                });
            }
        },

        onScroll() {
            if(!window.sessionStorage) {
                return;
            }
            try {
                window.sessionStorage.setItem(`scroll:${this.sectionCode}`, this.$refs.list.scrollTop);
            } catch {

            }
        },

        onEntityUpdated(){
            this.loadItems();
        },

        selectCategory(categoryCode) {
            if(this.selectedCategories.length == 0) {
                this.selectedCategories.push(categoryCode)
            } else if(this.selectedCategories.indexOf(categoryCode) != -1) {
                this.selectedCategories.splice(this.selectedCategories.indexOf(categoryCode), 1);
            } else {
                this.selectedCategories.push(categoryCode);
            }

            if(this.selectedCategories.length == this.categoryList.length) {
                this.selectedCategories = [];
            }
        },

        updateSearchTerm(inputVal, eventObject){
            this.searchTerm = inputVal;
        },

        async loadItems(){
            this.submitting = true;

            // Step 1: Post your analytics here.
            var section = AppState.show.Sections[this.sectionCode];
            this.postAnalytics(
                AppState.attendeeCode, 
                `Pages:${this.code}`,
                this.code,
                section.SectionTypeCode, 
                AppState.showCode);

            // Step 2: Load your list from the web API.

            // This is just an example - you would replace this with
            // a call to the web API.

            var section = AppState.show.Sections[this.sectionCode];
            let categoryQuery = section.Categories?.map(x => x.Code)?.join(';') || '';

            this.categoryList = section.Categories || [];

            this.categoryList.forEach(x => {
                let showCategory = AppState.show.AttendeeCategories.find(c => c.Code == x.Code);

                if(showCategory) {
                    x.Name = showCategory.Name;
                } else {
                    x.Name = null;
                }
            });

            this.categoryList = this.categoryList.filter(x => x.Name);
            
            let attendeeList = await this.tryGet(`/api/attendees?c=${categoryQuery}`);
                        
            this.onlineCodes = attendeeList.Result.OnlineCodes;

            this.items = attendeeList.Result.FullNetwork;

            // Step 4: Restore the scroll position if needed.

            this.$nextTick(() => {
                if(!window.sessionStorage) {
                    return;
                }

                let scrollPosition = window.sessionStorage.getItem(`scroll:${this.code}`);

                if(!scrollPosition) {
                    return;
                }

                this.$refs.list.scrollTo(0, parseInt(scrollPosition));
            });

            this.submitting = false;

            if(!this.intervalTimer) {
                this.intervalTimer = setInterval(this.reloadOnlineAttendees, 60000);
            }
        },

        async reloadOnlineAttendees() {
            try {

                let r = await this.tryGet(`/api/interactions/allonline`);

                if(r.Errors.length == 0) {
                    this.onlineCodes = r.Result;
                }

            } catch(ex) {

            }
        },
    },

    data() {
        return {
            items: [],
            selectedCategories: [],
            searchTerm: '',
            submitting: false,
            categoryList: [],
            onlineCodes: [],
            intervalTimer: null,
            favoritesOnly: false,
            showFilters: true,
        }
    },


    
    beforeUnmount() {
        window.$bus.$off('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$off('entity-updated',this.onEntityUpdated);
        if(this.intervalTimer) {
            clearInterval(this.intervalTimer);
        }
    },

    created: function() {
        if(this.isOnExtraSmallPlatform()) {
            this.showFilters = false;
        }

        this.loadItems();
        window.$bus.$on('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$on('entity-updated',this.onEntityUpdated);
    },
}
</script>