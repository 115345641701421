<template>
    <div class="post-list" ref="posts">
        <div>
            <div v-for="post in postsToShow" :key="post.Id"
                :class="[{ 'editing-post': editingPostCode && editingPostCode == post.Id, 'my-post': post.AttendeeCode == myCode, 'post-declined': post.ApprovalCode == 'Declined', 'is-reply': post.ReplyToId, 'replying-to-post': replyingToPost && replyingToPost == post }]"
                class="discussion-post">



                <div class="post-header" v-if="post.PostTypeCode != 'Announcement'">
                    <button class="subtle-edit-button post-menu-button icomoon"
                        v-if="post.AttendeeCode == myCode || isUserAdmin" type="button">
                        
                        <div class="discussion-post-submenu">
                            <button class="icomoon" @click="emitEditRequest(post)">
                                
                            </button>
                            <button class="icomoon" @click="emitDeleteRequest(post)">
                                
                            </button>
                        </div>
                    </button>
                    <div class="post-profile-image" v-if="!post.IsAnonymous">
                        <img-g
                            :src="`${getApiUrl()}/api/asset/${showCode}/attendee/${post.AttendeeCode}/profile`"
                            fallbackSrc="/img/person.png" />
                    </div>
                </div>

                <div class="post-content" v-if="post.PostTypeCode != 'Announcement'">

                    <div class="post-content-container">
                        <div class="post-author">
                            {{ post.DisplayName }}
                            <!--  · <span class="post-time">
                        <from-indicator :short="true" :time="post.PostedDateTime"></from-indicator>
                        </span> -->
                        </div>

                        <div v-if="post.Images" class="post-img-container">
                            <img-g v-for="image in post.Images" 
                                   class="responsive" 
                                   :key="image" 
                                   :src="image"
                                   @click="zoomImage(image)" />
                        </div>

                        <div class="post-body" v-if="post.Body || post.Link && post.LinkTypeCode == 'Attachment'"
                            @click="onPostClick(post)">
                            <linkify-area :input="post.Body">

                            </linkify-area>

                            <div class="file-link" v-if="post.Link && post.LinkTypeCode == 'Attachment'">
                                <span class="icomoon">
                                    
                                </span>
                                <a :href="post.Link" target="_blank">
                                    {{ getShortName(post.Link) }}
                                </a>
                            </div>

                            <div class="post-body-time">
                                {{$filters.moment(post.PostedDateTime, "L LT")}}
                            </div>
                        </div>

                    </div>
                    <div class="post-reaction-buttons">
                        <button class="subtle-button icomoon" type="button" v-if="!post.ReplyToId"
                            @click="setReplyPost(post)">
                            
                        </button>
                        <button class="subtle-button" type="button" @click="notifyLikePost(post)">
                            <span class="icomoon">
                                {{post.Likes && post.Likes.indexOf(myCode) != -1 ? '' : ''}}
                            </span>
                            <span>
                                {{post.Likes && post.Likes.length > 0 ? post.Likes.length : ''}}
                            </span>
                        </button>
                    </div>
                </div>

                <div v-if="post.PostTypeCode && post.PostTypeCode == 'Announcement'" class="post-header">
                    <span class="post-announcement">
                        {{post.Body}}
                    </span>
                </div>
            </div>
            <div v-if="areMembersTyping" class="typing-blinker fade-in-on-create">
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div class="new-message-indicator" :class="{'show-new-message-indicator' : showGoToBottom}"
                @click="bringLatestMessageIntoView">
                New Message
            </div>
        </div>
    </div>
</template>
<script>
// import Vue from "vue";
import Token from "./authentication/token";
import Common from "./common";
import moment from "moment";
import linkifyArea from './linkify.area.vue';

import { AppState } from "./appstate";

export default {
    components: { linkifyArea },
    props: [
        "postsToShow",
        "discussion",
        "areMembersTyping",
        "participants",
        "editingPostCode",
        "replyingToPost"
    ],

    mixins: [Token, Common],

    computed: {
        myCode() {
            return AppState.attendeeCode;
        },

        showCode() {
            return AppState.showCode;
        },

        isUserAdmin() {
            return ((this.discussion.ParticipantRoles[AppState.attendee.Code]
                && this.discussion.ParticipantRoles[AppState.attendee.Code] == 'Administrator' && this.discussion.showModeratorControls) 
                || AppState.attendee.RoleCode == 'Administrator');
        },

    },

    watch: {
        areMembersTyping() {
            if(this.areMembersTyping) {
                this.shiftDownIfCloseToBottom();
            }
        },

    },

    methods: {
        zoomImage(imageUrl) {
            this.$bus.emit('iframe-open-request', {
                Url: image,
                Title: 'Image'
            })
        },

        notifyLikePost(post) {
            this.$emit('toggle-post-like', post);
        },

        setReplyPost(post) {
            this.$emit('reply-to-post', post);
        },

        onPostClick(post) {
            this.$emit('click-post', post);
        },

        emitEditRequest(post) {
            this.$emit('edit-post', post);
        },

        emitDeleteRequest(post) {
            this.$emit('delete-post', post);
        },

        shiftDownIfCloseToBottom() {
            this.$nextTick(() => {
                let scrollTarget = this.$refs.posts;

                let scrollY = scrollTarget.scrollTop;
                let innerHeight = scrollTarget.scrollHeight;

                let elementHeight = scrollTarget.offsetHeight;

                if(innerHeight - scrollY - elementHeight > 20) {
                    return;
                }

                scrollTarget.scrollTo(0, scrollTarget.scrollHeight);
            });

        },

        scrollHandler(){
            if(!this.showGoToBottom){
                return;
            }

            let scrollTarget = this.$refs.posts;
            let elementHeight = scrollTarget.offsetHeight;
            let scrollPosition = scrollTarget.scrollTop;
            let fullHeight = scrollTarget.scrollHeight;
            
            this.showGoToBottom = fullHeight - scrollPosition - elementHeight < 10 ? false : true;
        },
        checkAndScrollIntoView(){
            let scrollTarget = this.$refs.posts;
            
            let fullHeight = scrollTarget.scrollHeight;
            let elementHeight = scrollTarget.offsetHeight;
            let scrollPosition = scrollTarget.scrollTop;

            let isElementCloseToBottom = fullHeight - scrollPosition - elementHeight < 10 ? true : false;

            if(isElementCloseToBottom){
                this.bringLatestMessageIntoView();
            }
            else{
                this.showGoToBottom = true;
            }
        },
        getShortName(link) {
            try {
                let linkSplit = link.split("/");

                linkSplit = linkSplit[linkSplit.length - 1].split("%2F");
                
                return linkSplit[linkSplit.length - 1];

            } catch {
                return link;
            }
        },

        alertNewMessage() {
            this.bringLatestMessageIntoView();
        },

        bringLatestMessageIntoView(offset) {
            this.showGoToBottom = false;
            this.$nextTick(() => {
                let scrollTarget = this.$refs.posts;
                scrollTarget.scrollTo(0, scrollTarget.scrollHeight);
            });
        },

    },

    data() {
        return {
            showGoToBottom: false,
            
        };
    },

    mounted: function () {
        this.$nextTick(() => {
            this.bringLatestMessageIntoView();
            let scrollTarget = this.$refs.posts;
            console.log(scrollTarget);
            scrollTarget.addEventListener('scroll',this.scrollHandler);

            let o = new ResizeObserver(e => {
                for(let entry of e) {
                    const cr = entry.contentRect;
                    const offset = scrollTarget.scrollTop;
                    if(cr.height > 0 && offset == 0) {
                        this.bringLatestMessageIntoView();
                        o.unobserve(scrollTarget);
                    }
                }
            });

            o.observe(scrollTarget);
        });
    },
};
</script>