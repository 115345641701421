<template>
    <div class="home-container"
         :style="homeStyle"
         v-if="attendee">
        <vue-title :title="attendee.Show.Name" :brand="true"></vue-title>
        <div v-if="attendee.Show.Sections" class="home-link-container"> 
            <template v-for="(section,i) in attendee.Show.Sections">
                <home-item @interacted="doNavigate(getRouterLink(section,i))"
                           :key="section.Code"
                           v-if="!section.Size.startsWith('menuonly') && attendeeAllowedToViewSection(section)"
                           :section="section">
                </home-item>

            </template>
        </div>
    </div>
</template>
<script>
import Token from './authentication/token'
import Common from './common'

import { AppState } from './appstate';

export default {
    props: [
    ],

    mixins: [
        Token,
        Common
    ],

    computed: {
        attendee() {
            return AppState.attendee;
        },

        homeStyle() {
            let theme = AppState.attendee.Show.Theme;
            if(!theme) {
                return null;
            }

            if(theme.HomeBackgrounds && theme.HomeBackgrounds.length > 0) {
                
                let randomIndex = Math.floor(Math.random() * theme.HomeBackgrounds.length);

                return `background-image: url('${theme.HomeBackgrounds[randomIndex]}');'`;
            }
        }
    },

    methods: {

    },

    data() {
        return {
        }
    },

    mounted: function() {
        this.postAnalytics(
            AppState.attendee.Code, 
            'LandingPage',
            null,
            null, 
            AppState.attendee.Show.Code);
    }
}
</script>
