<template>
    <div class="page-side-panel contact-info-editor-pane"
         v-if="contactInfoCopy">
        <div class="header-actions">
            <span class="title">Edit Element</span>
            <div class="actions">            
                <button class="icomoon" @click="closePage">
                    
                </button>
            </div>
        </div>
        
        <div class="page-side-panel-content"
             v-if="contactInfoCopy">

            <label>
                Item Width
            </label>

            <div class="button-group button-group-sm">
                <button @click="contactInfoCopy.Size = 'Full'"
                        :class="{'active': contactInfoCopy.Size == 'Full'}">
                    100%
                </button>
                <button @click="contactInfoCopy.Size = 'TwoThird'"
                        :class="{'active': contactInfoCopy.Size == 'TwoThird'}">
                    66%
                </button>
                <button @click="contactInfoCopy.Size = 'Half'"
                        :class="{'active': contactInfoCopy.Size == 'Half'}">
                    50%
                </button>
                <button @click="contactInfoCopy.Size = 'Third'"
                        :class="{'active': contactInfoCopy.Size == 'Third'}">
                    33%
                </button>
            </div>

            <label>
                Visibility
            </label>

            <div class="button-group button-group-sm">
                <button @click="contactInfoCopy.IsHidden = false"
                        :class="{'active': contactInfoCopy.IsHidden == false}">
                    Visible
                </button>
                <button @click="contactInfoCopy.IsHidden = true"
                        :class="{'active': contactInfoCopy.IsHidden == true}">
                    Hidden
                </button>
            </div>

            <template v-if="contactInfoCopy.Type == 'Image'">

                <label>
                    Image
                </label>

                <div class="drop-zone-image">
                    <img-g :src="contactInfoCopy.IconUrl"
                           :fallbackUrl="'/placeholder.png'"
                           class="responsive"
                           @dragover.native="onDragOverImage"
                           @drop.native="onDropImage"
                           @click.native="showFilePicker">

                    </img-g>
                </div>
                
                <div class="form-control">
                    <label>
                        Caption Text
                    </label>

                    <textarea v-model="contactInfoCopy.Details"
                              required
                              aria-label="Caption Text"
                              rows="8">

                    </textarea>

                </div>

                <div class="form-control">
                    <label>
                        Link URL
                    </label>
                    <input v-model="contactInfoCopy.Value"
                           required
                           aria-label="Link URL">
                </div>

            </template>

            <template v-if="contactInfoCopy.Type == 'Downloadable'">

                <label>
                    Download
                </label>

                <div class="drop-zone-file"
                     @dragover="onDragOverImage"
                     @drop="onDropImage"
                     @click="showFilePicker">
                    <div>
                        Click to upload file, or drag file here
                    </div>
                    <div v-if="contactInfoCopy.Value">
                        {{getShortFileName()}}
                    </div>

                    <button v-if="contactInfoCopy.Value"
                            @click.stop="showPreviewOfFile">
                        Preview File
                    </button>
                </div>
                
                <div class="form-control">
                    <label>
                        Title
                    </label>
                    <input v-model="contactInfoCopy.DisplayText"
                           required
                           aria-label="Title">
                </div>

                <div class="form-control">

                    <label>
                        Details
                    </label>
                    <textarea v-model="contactInfoCopy.Details"
                              required
                              aria-label="Details"
                              rows="8">

                    </textarea>
                </div>                       


            </template>

            <template v-if="contactInfoCopy.Type == 'Website' || contactInfoCopy.Type == 'Email'">

                <label>
                    Web Link
                </label>


                <div class="form-control">
                    <label>
                        Title
                    </label>
                    <input v-model="contactInfoCopy.DisplayText"
                           required
                           aria-label="Title">
                </div>

                <div class="form-control">
                    <label>
                        Details
                    </label>
                    <textarea v-model="contactInfoCopy.Details"
                              required
                              aria-label="Details"
                              rows="8">

                    </textarea>

                </div>      

                <div class="form-control">
                    <label>
                        Link URL
                    </label>
                    <input v-model="contactInfoCopy.Value"
                           required
                           aria-label="Link URL">
                </div>

            </template>


            <template v-if="contactInfoCopy.Type == 'Section'">

                <div class="form-control">
                    <label>
                        Title
                    </label>
                    <input v-model="contactInfoCopy.DisplayText"
                           required
                           aria-label="Title">
                </div>

                <div class="form-control">

                    <label>
                        Body Text
                    </label>
                    <textarea v-model="contactInfoCopy.Details"
                              required
                              aria-label="Body Text"
                              rows="8">

                    </textarea>
                </div>      

                <label>
                    Header Alignment
                </label>
                <div class="button-group button-group-sm">
                    <button @click="headerAlignment = 'left'"
                            :class="{'active': headerAlignment == 'left'}">
                        Left
                    </button>
                    <button @click="headerAlignment = 'center'"
                            :class="{'active': headerAlignment == 'center'}">
                        Center
                    </button>
                    <button @click="headerAlignment = 'right'"
                            :class="{'active': headerAlignment == 'right'}">
                        Right
                    </button>
                </div>

                <label>
                    Body Alignment
                </label>
                <div class="button-group button-group-sm">
                    <button @click="bodyAlignment = 'left'"
                            :class="{'active': bodyAlignment == 'left'}">
                        Left
                    </button>
                    <button @click="bodyAlignment = 'center'"
                            :class="{'active': bodyAlignment == 'center'}">
                        Center
                    </button>
                    <button @click="bodyAlignment = 'right'"
                            :class="{'active': bodyAlignment == 'right'}">
                        Right
                    </button>
                </div>

                <label>
                    Background Color
                </label>
                <div class="swatches">
                    <button v-for="color in swatches"
                            :key="color"
                            @click="backgroundColor = color"
                            :style="`background: ${color}`"
                            class="swatch-button"
                            :class="{'clear-swatch': color == 'transparent', 'selected-color': color == backgroundColor}">
                    </button>   
                </div>
                <label>
                    Text Color
                </label>
                <div class="swatches">
                    <button v-for="color in swatches"
                            :key="color"
                            @click="textColor = color"
                            :style="`background: ${color}`"
                            class="swatch-button"
                            :class="{'clear-swatch': color == 'transparent', 'selected-color': color == textColor}">
                    </button>   
                </div>


                <div class="form-control">
                    <label>
                        Border Radius
                    </label>
                    <input v-model="borderRadius"
                           required
                            type="number"
                            min="0"
                            max="20" />
                </div>


                <label>
                    Embed Aspect Ratio
                </label>
                <div class="button-group button-group-sm">
                    <button @click="embedAspect = 'var(--aspect-16x9)'"
                            :class="{'active': embedAspect == 'var(--aspect-16x9)'}">
                        16:9
                    </button>
                    <button @click="embedAspect = 'var(--aspect-4x3)'"
                            :class="{'active': embedAspect == 'var(--aspect-4x3)'}">
                        4:3
                    </button>
                    <button @click="embedAspect = 'var(--aspect-1x1)'"
                            :class="{'active': embedAspect == 'var(--aspect-1x1)'}">
                        1:1
                    </button>
                    <button @click="embedAspect = 'var(--aspect-3x4)'"
                            :class="{'active': embedAspect == 'var(--aspect-3x4)'}">
                        3:4
                    </button>
                    <button @click="embedAspect = 'var(--aspect-9x16)'"
                            :class="{'active': embedAspect == 'var(--aspect-9x16)'}">
                        9:16
                    </button>
                </div>

                <label>
                    Embed Shadow
                </label>
                <div class="button-group button-group-sm">
                    <button @click="embedShadow = 'var(--right-pane-shadow)'"
                            :class="{'active': embedShadow == 'var(--right-pane-shadow)'}">
                        On
                    </button>
                    <button @click="embedShadow = 'none'"
                            :class="{'active': embedShadow == 'none'}">
                        Off
                    </button>
                </div>


            </template>

            <!-- <div>
                {{parsedStyle}}
            </div>
            <textarea v-model="contactInfoCopy.Style">

            </textarea> -->

            <button @click="requestDeleteElement"
                    class="end-call">
                Delete Element
            </button>
        </div>
    </div>
</template>
<script>
import Common from './common'
import Token from './authentication/token'
import { AppState } from './appstate';;
import moment from 'moment'
import css from 'css'

export default {

    mixins: [
        Token,
        Common
    ],   

    props: ['attendee', 'sidebarParams'],

    watch: {
        'sidebarParams.ContactInfo': {
            immediate: true, 
            handler() {
                this.contactInfoCopy = JSON.parse(JSON.stringify(this.sidebarParams.ContactInfo));
            }
        },

        'contactInfoCopy': {
            deep: true,
            handler() {
                window.$bus.$emit('ContactInfoItemEdited', 
                    this.sidebarParams.EntityTypeCode,
                    this.sidebarParams.EntityCode,
                    this.contactInfoCopy);
            }
        },
    },

    computed: {
        embedAspect: {
            get() {
                return this.getStyleProperty('#ci_ID .iframe-ratio::before', 'padding-top', 'var(--aspect-16x9)');
            },
            set(value) {
                this.updateStyleProperty('#ci_ID .iframe-ratio::before', 'padding-top', value);
            }
        },

        embedShadow: {
            get() {
                return this.getStyleProperty('#ci_ID .iframe-ratio', 'box-shadow', 'var(--right-pane-shadow)');
            },
            set(value) {
                this.updateStyleProperty('#ci_ID .iframe-ratio', 'box-shadow', value);
            }
        },

        headerAlignment: {
            get() {
                return this.getStyleProperty('#ci_ID h1', 'text-align', 'left');
            },
            set(value) {
                this.updateStyleProperty('#ci_ID h1', 'text-align', value);
            }
        },

        bodyAlignment: {
            get() {
                return this.getStyleProperty('#ci_ID p, #ci_ID ul, #ci_ID ol', 'text-align', 'left');
            },
            set(value) {
                this.updateStyleProperty('#ci_ID p, #ci_ID ul, #ci_ID ol', 'text-align', value);
            }
        },

        backgroundColor: {
            get() {
                return this.getStyleProperty('#ci_ID', 'background-color', "transparent");
            },
            set(value) {
                this.updateStyleProperty('#ci_ID', 'background-color', value);
            }
        },

        textColor: {
            get() {
                return this.getStyleProperty('#ci_ID', 'color', 'var(--text-color)');
            },
            set(value) {
                this.updateStyleProperty('#ci_ID', 'color', value);
            }
        },

        borderRadius: {
            get() {
                return this.getStyleProperty('#ci_ID', 'border-radius', '8').replace('px', '');
            },
            set(value) {
                this.updateStyleProperty('#ci_ID', 'border-radius', `${value}px`);
            }
        },


        parsedStyle() {
            let style = this.contactInfoCopy.Style;

            if(!style) {
                return [];
            }

            try {
                return css.parse(style, {
                    silent: true
                }).stylesheet.rules;
            } catch {
                return [];
            }
        }
    },

    beforeUnmount() {
        window.$bus.$off('asset-uploaded-contact-info', this.onAssetUploaded);
    },

    mounted() {
        window.$bus.$on('asset-uploaded-contact-info', this.onAssetUploaded);
    },

    methods: {
        updateColor(propertyName, e) {
            console.log(propertyName, e);
            this[propertyName] = e.hex8;
        },

        requestDeleteElement() {
            window.$bus.$emit('delete-contact-info-element', this.contactInfoCopy);
        },

        getStyleProperty(selector, propertyName, fallbackValue) {
            let rules = this.parsedStyle;

            let allSelectors = selector.split(',').map(x => x.trim());
            let rule = rules.find(x => 
                x.selectors.filter(y => allSelectors.includes(y)).length == allSelectors.length);



            console.log(allSelectors, selector, rule, this.contactInfoCopy.Style);

            if(!rule) {
                return fallbackValue;
            }

            let declaration = rule.declarations.find(x => 
                x.property == propertyName);

            if(!declaration) {
                return fallbackValue;
            }

            return declaration.value || fallbackValue;
        },

        updateStyleProperty(selector, propertyName, propertyValue) {
            let toSave = {
                stylesheet: {
                    rules: this.parsedStyle
                }
            };

            console.log('updateStyleProperty', selector, propertyName, propertyValue)

            let allSelectors = selector.split(',').map(x => x.trim());

            let rule = toSave.stylesheet.rules.find(x => 
                x.selectors.filter(y => allSelectors.includes(y)).length == allSelectors.length);

            if(!rule) {
                rule = {
                    type: 'rule',
                    selectors: [ ...allSelectors ],
                    declarations: []
                };

                toSave.stylesheet.rules.push(rule);
            }

            let declaration = rule.declarations.find(x => 
                x.property == propertyName);

            if(!declaration) {
                declaration = {
                    type: 'declaration',
                    property: propertyName,
                    value: propertyValue
                };

                rule.declarations.push(declaration);
            }

            declaration.value = propertyValue;

            let newStyle = css.stringify(toSave);

            this.contactInfoCopy.Style = newStyle;
        },

        showFilePicker() {
            window.$bus.$emit('request-choose-file-contact-info', this.contactInfoCopy);
        },

        getShortFileName() {
            let fileParts = this.contactInfoCopy.Value.split("/");

            return fileParts[fileParts.length - 1];
        },

        showPreviewOfFile() {
            window.$bus.$emit('iframe-open-request', {
                Url: this.contactInfoCopy.Value, 
                Title: "File Preview"
            });
        },

        onAssetUploaded(contactInfo, fileUrl) {
            if(!contactInfo) {
                return;
            }

            if(contactInfo._LocalCode != this.contactInfoCopy._LocalCode) {
                return;
            }

            if(this.contactInfoCopy.Type == 'Image') {
                this.contactInfoCopy.IconUrl = fileUrl;
            } else {
                this.contactInfoCopy.Value = fileUrl;
            }
                console.log(this.contactInfoCopy);
        },

        onDragOverImage(e) {
            e.preventDefault();
        },

        onDropImage(e) {
            e.preventDefault();
            if(e.dataTransfer.files.length > 0) {

                let file = e.dataTransfer.files[0];

                if(!file.type.startsWith('image/') && this.contactInfoCopy.Type == 'Image') {
                    this.$awn.alert("File chosen is not an image. Please use an image.");
                    return;
                }

                window.$bus.$emit('upload-asset-contact-info', this.contactInfoCopy, file);
            }
        },

        closePage() {
            window.$bus.$emit('contact-info-editor-closed');
            this.$emit('closerequest');
        },
    },

    data() {
        return {
            contactInfoCopy: null,
            swatches: [
                'transparent',
                'var(--primary-color)',
                'var(--primary-color-dark)',
                'var(--title-bar-color)',
                'var(--home-item-background)',
                'var(--text-color)',
                'var(--subtle-text-color)',
                'var(--light-text-color)',
                'var(--inverse-text-color)',
                'var(--light-bg-color)',
                'var(--med-bg-color)',
                'var(--dark-bg-color)',
                'var(--full-bright-bg-color)',
                'var(--full-dark-bg-color)',
                'white',
                'black'
            ]
        }
    },
}
</script>