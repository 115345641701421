<template>
    <div v-if="submitting"
         class="fixed-content-width">
        <loading-container  />
    </div>
    <div v-else class="list-page-full session-list">
        <vue-title :title="title" :brand="true"></vue-title>
        <div class="list-page-header">
            <div class="search-container">
                <div class="search-box">
                    <span class="icomoon">
                        
                    </span>
                    <input placeholder="Search Sessions" 
                        v-debounce:150="updateSearchTerm"   
                        type="text"/>
                </div>

                <div class="button-group filter">
                    <button :class="{'active': showFilter}"
                            @click="showFilter = !showFilter">
                        <span class="icomoon">
                            
                        </span>
                        <span>
                            Filter
                        </span>
                    </button>

                    <button :class="{'active': favoritesOnly}"
                            @click="favoritesOnly = !favoritesOnly"
                            v-if="allowEditFavorites">
                        <span class="icomoon">
                            
                        </span>
                        <span>
                            My Favorites
                        </span>
                    </button>

                    <button @click="onPrintListRequested">
                        <span class="icomoon">
                            
                        </span>
                        <span>
                            Print
                        </span>
                    </button>

                </div>
                <div class="list-page-filter"
                     v-show="showFilter">
                    <div class="filter-buttons">
                        <button type="button"
                                class="button-outline"
                                @click="selectedDate = null"
                                :class="{'active': !selectedDate}">
                            All Dates
                        </button>
                        <button type="button"
                                v-for="date in dates"
                                v-bind:key="date"
                                @click="selectedDate = date"
                                class="button-outline"
                                :class="{'active': selectedDate == date}">
                            {{$filters.moment(date, 'ddd MMM. Do')}}
                        </button>

                    </div>
                </div>

                <div class="list-page-filter"
                     v-show="showFilter">

                    <div class="filter-buttons">
                        <button type="button"
                                v-for="category in categories"
                                v-bind:key="category.Code"
                                @click="handleSelection(category)"
                                class="button-outline"
                                :class="{'active': selectedCategories.length == 0 || selectedCategories.indexOf(category) != -1}">
                            <span class="category-pill" :class="`S-${category.Code}-filter`">

                            </span>
                            {{category.Name}}
                        </button>


                    </div>
                </div>
            </div>

        </div>
        <div class="list-page-contents">
            <template v-for="(date, dateKey) in events"> 
                <div v-bind:key="dateKey"
                     :id="dateKey"
                    v-if="!selectedDate || selectedDate == dateKey"
                    class='category-item'>
                    <div class='category-item-header'>
                        <span class="category-title">
                            {{$filters.moment(dateKey, 'dddd MMMM Do')}}
                        </span>
                    </div>

                    <div class="category-items session-items"
                         v-for="(times, timeKey) in events[dateKey]"
                         :key="timeKey">
                        <span class="session-time-key" v-if="localTimeZoneEqualsShowTimeZone">
                            {{$filters.moment(timeKey, 'LT')}}
                        </span>
                        <span class="session-time-key" v-else>
                            {{$filters.momentEventTz(timeKey, 'LT z')}} / {{$filters.momentLocalTz(timeKey, 'LT z')}}
                        </span>
                        <session-item v-for="session in times"
                                      :key="session.Session.Code"
                                      :sessionModel="session">
                        </session-item>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>

import Token from './authentication/token'
import Common from './common'
import moment from 'moment'
import { AppState } from './appstate'

export default {
    props: ['sectionCode'],
    mixins: [
        Token,
        Common
    ],   

    watch: {
        sectionCode() {
            this.loadAgenda();
        },
        attendee() {
            this.loadAgenda();
        }
    },

    computed: {
        localTimeZoneEqualsShowTimeZone() {
            try {
                return !AppState.show.ShowTimeZone || window._userTimeZone == AppState.show.ShowTimeZone;
            } catch {
                return true;
            }
        },

        allowEditFavorites() {
            let section = AppState.show.Sections[this.sectionCode];
            return section?.SectionTypeCode != 'AgendaFavorites';
        },

        title() {
            return AppState.show.Sections[this.sectionCode].Title;
        },

        events() {
            let toReturn = this.sessions;
            
            let searchTest = new RegExp(this.searchTerm, 'i');

            toReturn = {};

            for(const dateIndex in this.dates) {

                const date = this.dates[dateIndex];
                const times = this.sessions[date];

                for(const key in times) {

                    let sessions = this.sessions[date][key];

                    sessions = sessions.filter(s => this.isSessionPartOfFilter(searchTest, s));
                    if(sessions.length == 0) {
                        continue;
                    }
                    
                    if(!toReturn[date]) {
                        toReturn[date] = {}
                    };

                    toReturn[date][key] = sessions;
                }
            }
            return toReturn;
        },
    },

    methods: {
        onPrintListRequested() {
            let fieldsToPrint = [
                {
                    'field': 'Session.Name',
                    'displayName': 'Name' 
                }, 
                {
                    'field': 'Session.Location',
                    'displayName': 'Location',
                    columnSize: '20%'
                }, 
                {
                    'field': 'Session._SessionTime',
                    'displayName': 'Session Time' 
                }, 
                {
                    'field': 'Session.Category.Name',
                    'displayName': 'Category' 
                }, 
            ];

            let toPrint = [...this.allSessions];

            toPrint.forEach(s => s.Session.Location = s.Session.Location || '');
            toPrint.forEach(s => s.Session._SessionTime = moment(s.Session.SessionStartDateTime).format('ddd MMM d, LT') || '');
            toPrint.forEach(s => s.Session.Category.Name = (AppState.attendee.Show.SessionCategories.find(x => x.Code == s.Session.Category.Code)?.Name) || '');

            printJS({
                printable: toPrint,
                properties: fieldsToPrint,
                type: 'json',
                header: `<h3>${this.title}</h3><h4>${AppState.attendee.Show.Name}</h4>`,
                style: 'h3{text-align:center;font-size:24px;}h4{text-align:center;}*{font-family:Tahoma,sans-serif;}'
            });
        },

        onWebinarUpdated(webinarDetails) {
            let session = this.allSessions.find(x => x.DiscussionCode == webinarDetails.RoomCode);

            if(!session) {
                return;
            }

            session = session.Session;

            let sessionLiveIndex = this.liveSessions.indexOf(session.Code);
            let isSessionLive = sessionLiveIndex != -1;

            let isRoomLive = 
                (session.DiscussionWebinarStatus == 'Public' || session.DiscussionWebinarStatus == 'Waiting')
                && (webinarDetails.IsOpen
                || webinarDetails.VideoUrl && webinarDetails.VideoStartDateTime);

            if(isSessionLive && !isRoomLive) {
                this.liveSessions.splice(sessionLiveIndex, 1);
            } else if(!isSessionLive && isRoomLive) {
                this.liveSessions.push(session.Code);
            }
        },

        isSessionPartOfFilter(searchTest, s) {
            let result = this.selectedCategories.find(x => 
                x.Code == s.Session.Category.Code
                || s.Session.SubCategoryCodes
                && s.Session.SubCategoryCodes.indexOf(x.Code) != -1);

            if(!result) {
                return false;
            }

            if(this.favoritesOnly && !s.IsFavorite) {
                return false;
            }

            if(this.searchTerm.length > 0 
                && !searchTest.test(s.Session.Name)
                && !searchTest.test(s.Session.ContactInfo.Bio)
                && !s.Session.ContactInfo.ContactInfo.find(c => c.DisplayText && searchTest.test(c.DisplayText)))
                return false;

            return true;
            
        },

        handleSelection(category) {
            let index = this.selectedCategories.indexOf(category);

            let keys = Object.keys(this.categories);

            if(this.selectedCategories.length == keys.length) {
                this.selectedCategories = [category];
                return;
            }

            if(index == -1) {
                this.selectedCategories.push(category);
            } else {
                this.selectedCategories.splice(index, 1);
            }

            if(this.selectedCategories.length == 0) {
                this.selectedCategories = [...this.categories];
            }
        },

        updateSearchTerm(inputVal, eventObject){
            this.selectedDate = null;
            this.searchTerm = inputVal;
        },

        onFavoritesUpdated(favorites){
            if(!favorites) {
                this.loadAgenda();
            } else {
                favorites.forEach(f => {
                    if(f.EntityTypeCode != 'SESSION') {
                        return;
                    }

                    let session = this.allSessions.find(x => x.Session.Code == f.EntityCode);

                    if(!session) {
                        return;
                    }

                    session.IsFavorite = f.IsActive;
                });
            }
        },


        onEntityUpdated(details) {
            if(details.EntityTypeCode != "SESSION") {
                return;
            }

            this.loadAgenda();
        }, 

        async loadAgenda(){
            this.postAnalytics(
                    AppState.attendeeCode,
                    'Agenda',
                    this.sectionCode,
                    'Section', 
                    AppState.showCode);

            let section = AppState.show.Sections[this.sectionCode];
            var showCategories = [];
            var sectionCategories = section.Categories;

            if(section.SectionTypeCode == 'AppLink'
                && section.AppLinkTypeCode == 'Agenda') {
                sectionCategories = AppState.show.SessionCategories;
            }

            for(var i = 0; i < sectionCategories.length; i++){
                var sessionCategory = AppState.show.SessionCategories.filter( n => sectionCategories[i].Code == n.Code);

                if( sessionCategory.length > 0){
                    showCategories.push(sessionCategory[0]);
                }
            }

            if(showCategories == null){
                this.submitting = false;
                return;
            }

            console.log(showCategories);
            let categoryQuery = showCategories.map(x => x.Code).join(';');

            try {
                let onlyShowFavorites = section.SectionTypeCode == 'AgendaFavorites';
                this.onlyShowFavorites = onlyShowFavorites;

                let favoritesParam = (onlyShowFavorites) ? "only" : "";

                let result = await this.tryGet(`/api/show/${AppState.show.Code}/agenda?f=${favoritesParam}&c=${categoryQuery}`)

                this.categories = result.Result.Categories;
                this.sessions = result.Result.Sessions;
                this.liveSessions = result.Result.LiveSessionCodes;
                this.allSessions = result.Result.Sessions;

                let dates = [];
                let sessions = {};

                for(let session of this.sessions) {
                    let startDateTime = new Date(session.Session.SessionStartDateTime)

                    let dayKey = `${startDateTime.getFullYear()}-${(startDateTime.getMonth() + 1).toString().padStart(2, "0")}-${(startDateTime.getDate()).toString().padStart(2, "0")}T00:00:00`;

                    if(!dates.find(x => x == dayKey)) {
                        dates.push(dayKey);
                        sessions[dayKey] = {};
                    }

                    let timeKey = startDateTime
                    
                    if(!sessions[dayKey][timeKey]) {
                        sessions[dayKey][timeKey] = [];
                    }

                    sessions[dayKey][timeKey].push(session);
                }

                this.dates = dates;
                this.sessions = sessions;

            } catch(ex) {
                console.error(ex);
                this.submitting = false;
                return;
            }

            for(let category in this.categories) {
                this.selectedCategories.push(this.categories[category]);
            }

            this.submitting = false;

            this.$nextTick(() => {
                try {
                    
                    let now = new Date();

                    for(let i = 0; i < this.dates.length; i++) {
                        let date = new Date(this.dates[i]);
                        let diff = ((now - date) / 1000) / 60 / 60                   
                        if(diff > 0 && diff < 24) {
                            document.getElementById(this.dates[i]).scrollIntoView();
                        }
                    }

                } catch(ex) {
                }
            });
        },

        onWebinarLiveChanged(args) {
            if(args.EntityTypeCode != "SESSION") {
                return;
            }

            let session = this.allSessions.find(x => x.Session.Code == args.EntityCode);

            if(!session) {
                return;
            }

            session.Session['DiscussionWebinarStatus'] = args.WebinarStatus;
        }
    },

    data() {
        return {
            sessions: {},
            dates: [],
            categories: [],
            liveSessions: [],
            allSessions: [],

            selectedCategories: [],
            selectedDate: null,

            searchTerm: '',
            showFilter: true,
            submitting: true,
            favoritesOnly: false,
            onlyShowFavorites: false,
        }
    },

    beforeUnmount() {
        window.$bus.$off('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$off('entity-updated', this.onEntityUpdated);
        window.$bus.$off('webinar-update', this.onWebinarUpdated);
        window.$bus.$off('WebinarLiveChange', this.onWebinarLiveChanged);
    },

    created: function() {
        if(this.isOnExtraSmallPlatform()) {
            this.showFilter = false;
        }

        this.loadAgenda();
        window.$bus.$on('favorites-updated',this.onFavoritesUpdated);
        window.$bus.$on('entity-updated', this.onEntityUpdated);    
        window.$bus.$on('webinar-update', this.onWebinarUpdated);    
        window.$bus.$on('WebinarLiveChange', this.onWebinarLiveChanged);
    },
}
</script>