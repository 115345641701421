<template>
    <router-link class="chatroom-list-item"
                 :to="{name: 'DiscussionInternalChat', params: {discussionCode: discussion.Discussion.Code}}">

        <img-g :src="discussion.Discussion.PhotoUrl"
               v-if="discussion.Discussion.PhotoUrl"
               class="responsive list-item-icon" />
        <span class="title"><span class="notification-badge" v-if="unread"></span>{{discussion.Discussion.Name}}</span>
        <span class="subtitle description">{{discussion.Discussion.Description}}</span>
    </router-link>
</template>
<script>
import { AppState } from '../appstate';

export default {
    props: ['discussion', 'unreadCodes'],

    computed: {
        unread() {
            return AppState.notifications.UnreadChatCodes.indexOf(this.discussion.Discussion.Code) != -1
                && AppState.notifications.UnreadDiscussionCodes.indexOf(this.discussion.Discussion.Code) != -1;
        }
    }
}
</script>