<template>
    <div v-if="submitting"
         class="fixed-content-width">
        <loading-container />
    </div>
    <div class="poll-answer fixed-content-width" 
         v-else>
        <div v-if="editPoll && !isSuccess"
             class="detail-page poll-page">
            <vue-title :title="editPoll.Name" :brand="true"></vue-title>

            <h1>
                {{editPoll.Name}}
            </h1>

            <div v-if="editPoll.HeaderText"
                 v-html="markdown(editPoll.HeaderText)"
                 class="poll-message">

            </div>

             <div class="poll-question-container">
                 <div v-for="question in editPoll.Questions"
                      v-bind:key="question.Code"
                      class="poll-question"
                      :class="{'poll-question-mandatory' : question.IsMandatory}">

                        <!-- v-if="question.QuestionType != 'INTERNAL'"  -->
                        <poll-answer-control 
                                             :disabled="denyResponseEditCheck(question.Code)"
                                             v-on:responseChanged="responseChanged"
                                             v-on:groupInsert="groupInsert"
                                             v-on:groupDelete="groupDelete"
                                             v-bind:question="question"
                                             v-bind:poll="editPoll" 
                                             v-bind:submitting="submitting">
                        </poll-answer-control>
                 </div> 
             </div>


            <div class="error-box" v-if="errors.length > 0">
                <p> 
                    Oops! We couldn't save your submission. Correct the following and try again.
                </p>
                <ul class="poll-list-ul">
                    <li v-for="(error, ix) in errors"
                        v-bind:key="ix"
                        class="poll-list-li">
                    {{error}} is mandatory.
                    </li>
                </ul>
            </div>
            <div class="poll-actions">
                <button @click="submitPoll">
                    <span class="icomoon"></span><span>Submit</span>
                </button>
            </div>
        </div>
        <div v-if="editPoll && isSuccess"
             class="detail-page poll-page">
            <h1>
                {{editPoll.ConclusionTitle}}
            </h1>
            <div v-if="editPoll.ConclusionMessage"
                 v-html="markdown(editPoll.ConclusionMessage)"
                 class="poll-message">

            </div>
            <div class="poll-actions">
                <button @click="backOnePage">
                    OK
                </button>
            </div>
        </div>
    </div>
</template>
<script>
import { AppState } from './appstate';
import Token from './authentication/token'
import PollQuestion from '../models/PollQuestion.js'
import { IconLookups, Lookups } from './common'
import Common from './common'
import moment from 'moment'

export default {
    props: ['attendee', 'code','passedInPoll'],
    mixins: [
        Token,
        Common
    ],   

    watch: {
        code(to, from) {
            this.build();
        },
    },

    methods: {
        backOnePage() {
            try {
                this.$router.go(-1);
            } catch(ex) {
                this.$router.replace({name: 'home'});
            }
        },

        groupDelete:function(groupTag){
            let subGroup = groupTag.SubGroup;
            let group = groupTag.GroupTag;
            var filtered = this.editPoll.Questions.filter(x => !x.SubGroup || x.SubGroup != subGroup);
            this.editPoll.Questions = filtered;
            window.$bus.$emit('updateVisibility');
            window.$bus.$emit('deleteGroup',group);
        },
        groupInsert:function(groupTag){
            let groupOfQuestionsToAdd = [];

            let newGroupTag = `${groupTag}${this.incrementGroup}`;

            //filter in this instance is byref
            var groupTemplates = this.cloneObject(this.pollQuestionGroupTemplates).filter(x => x.GroupTag == groupTag);

            for(let i=0; i < groupTemplates.length; i++){
                let template = groupTemplates[i];
                template.Code = this.createUniqueCode();
                template.SubGroup = newGroupTag;
                groupOfQuestionsToAdd.push(template);
            }

            let groupInsertButtonPos = this.editPoll.Questions.findIndex( x => x.GroupTag == groupTag && x.QuestionType == "GroupInsertButton");

            let insertButton = this.editPoll.Questions[groupInsertButtonPos];
            
            let groupDelete = new PollQuestion();
            groupDelete.QuestionType = "GroupDeleteButton"
            groupDelete.Response = insertButton.Response;
            groupDelete.GroupTag = insertButton.GroupTag;
            groupDelete.SubGroup = newGroupTag;
            groupOfQuestionsToAdd.push(groupDelete);
            this.incrementGroup += 1;
            
            // let pos = (this.editPoll.Questions.length < groupInsertButtonPos + 1) ? this.editPoll.Questions.length : groupInsertButtonPos + 1;
            this.editPoll.Questions.splice(groupInsertButtonPos, 0, ...groupOfQuestionsToAdd);
            window.$bus.$emit('updateVisibility');
        },
        responseChanged: function(qSender) {
            window.$bus.$emit('updateVisibility');
        },
        denyResponseEditCheck(questionCode){
            if(this.responseCheckCodeArray && this.responseCheckCodeArray.length == 0){
                return false;
            }
            
            return this.responseCheckCodeArray.includes(questionCode);
        },
        textPromptClass: function(question) {
            return {
                alert: question.QuestionType === 'TextPrompt' 
                    && question.LabelType != 'Normal'
                    && question.LabelType,
                
                'alert-primary': question.LabelType === 'Information',
                'alert-warning': question.LabelType === 'Warning',
                'alert-danger': question.LabelType === 'Alert'
            };
        },
        async submitPoll() {
            this.errors = [];
            this.isSuccess = true;

            this.poll.RespondantAttendeeCode = AppState.attendeeCode;

            if(this.poll.Id == 'details') {
                this.poll.Id = '0';
            }

            for(let question of this.editPoll.Questions){
                if(question.IsMandatory && !question.Response){
                    if(question.Conditions.length > 0) {
                        var isVisible = false;

                        for(var j = 0; j < question.Conditions.length; j++)
                        {
                            var condition = question.Conditions[j];
                            var questionToCheck = this.editPoll.Questions.find(x => x.Code == condition.ReliesOnQuestionCode);

                            if(questionToCheck && questionToCheck.Response == condition.RequiredAnswer) {
                                isVisible = true;        
                                break;
                            }
                        }

                        if(!isVisible) {
                            continue;
                        }
                    }

                    this.isSuccess = false;
                    this.errors.push(question.Name);
                }
            }

            if(this.isSuccess){
                this.poll.Questions = this.editPoll.Questions.concat(this.pollQuestionGroupTemplates);
                let pollSaveResult = await this.tryPost(`/api/poll/v2`, JSON.stringify(this.poll), 'application/json')

                if(pollSaveResult.Errors.length > 0){
                    this.isSuccess = false;
                    alert(pollSaveResult.Errors[0]);
                    this.postAnalytics(AppState.attendeeCode,'Error-PollAnswer-Submitted',this.code,'Poll',AppState.showCode);
                    return;
                }
                
                this.postAnalytics(AppState.attendeeCode,'PollAnswer-Submitted',this.code,'Poll',AppState.showCode);
            }
        },
        async build() {
            let poll = null;
            if(this.passedInPoll){
                poll = this.passedInPoll;
            }
            else{
                //todo should this a try catch surrounding?
                let pollResult = await this.tryGet(`/api/openpoll/${this.code}`);
                
                if(pollResult.Errors.length > 0) {
                    alert(pollResult.Errors[0]);
                    this.$router.go(-1);
                    return;
                }

                if(!pollResult.Result) {
                    // No error but there's no poll = poll complete.
                    this.editPoll = {
                        ConclusionTitle: "Poll Already Completed",
                        ConclusionMessage: "It looks like you already completed that poll.",
                        Questions: []
                    };

                    this.isSuccess = true;                    
                    this.submitting = false;
                    return;
                } else {
                    poll = pollResult.Result;
                }
            }

            this.poll = poll;
            this.editPoll = this.cloneObject(this.poll);
            this.editPoll.Questions = this.poll.Questions.filter(x => x.SubGroup || (!x.GroupTag || x.GroupTag == '') || x.QuestionType == 'GroupInsertButton');
            this.pollQuestionGroupTemplates = this.poll.Questions.filter(x => !x.SubGroup && x.GroupTag && x.GroupTag != '' && x.QuestionType != 'GroupInsertButton');

            // Since the value isn't provided over the wire always, we set
            // the default value to null.
            for(let i = 0; i < this.editPoll.Questions.length; i++) {
                let pollQuestion = this.editPoll.Questions[i];
                if(!pollQuestion.Response && pollQuestion.QuestionType != "GroupInsertButton") {
                    pollQuestion.Response = null;
                }
            }

            for(var i = 0; i < this.editPoll.Questions.length; i++) {
                let pollQuestion = this.editPoll.Questions[i];
                if(pollQuestion.WriteOnce == true && pollQuestion.Response != null){
                    this.responseCheckCodeArray.push(pollQuestion.Code);
                }
            }

            this.postAnalytics(AppState.attendeeCode,'PollAnswer',this.code,'Poll',AppState.showCode);

            this.submitting = false;
        },
    },

    data() {
        return {
            poll: null,
            errors: [],
            isSuccess: false,
            responseCheckCodeArray: [],
            incrementGroup: 0,
            editPoll: null,
            pollQuestionGroupTemplates: [],
        }
    },

    created: function() {
        this.submitting = true;
        this.build();
        
        
    },
}
</script>