<template>
    <div v-if="!submitting"
         class="lite-poll-editor">
        <div class="header-actions" >
            <div class="title"
                 title="Polls">
                    Polls
            </div>
            
            <button @click="emitExitRequest"
                class="header-buttons icomoon">
                                        
            </button>
        </div>

        <div class="poll-selector">
            <select v-model="selectedPoll">
                <option :value="null">
                    (Select Poll...)
                </option>
                <option v-for="poll in polls"
                        :key="poll.Code"
                        :value="poll">
                    {{poll.Title}}
                </option>
            </select>

            <button @click="addNewPoll">
                Add...
            </button>
        </div>
        <div v-if="selectedPoll"
             class="poll-editor">
            <input placeholder="Poll Title"
                   v-model="selectedPoll.Title">

            <textarea placeholder="Question Body (optional)"
                      v-model="selectedPoll.Body">

            </textarea>

            <div v-for="(item, index) in selectedPollAnswers"
                 :key="item.Code"
                 class="poll-answers">

                <input v-model="item.option"
                       v-if="!selectedPoll.IsOpen"
                       :ref="`option${item.index}`"
                       :placeholder="`Option ${item.index + 1}`"
                       @blur="onAnswerBlur">
                <label v-else>
                    {{item.option}}
                </label>

                <div class="answer-bar"
                     :style="`background: linear-gradient(90deg, var(--primary-color) ${(selectedPollResponses[index] / totalResponsesForSelectedPoll) * 100}%, #0000 ${(selectedPollResponses[index] / totalResponsesForSelectedPoll) * 100}%, #0000 100%)`"
                     v-if="totalResponsesForSelectedPoll > 0">
                    {{selectedPollResponses[index]}} / {{totalResponsesForSelectedPoll}} ({{((selectedPollResponses[index] / totalResponsesForSelectedPoll) * 100).toFixed(1) }} %)
                </div>
                <div class="answer-bar"
                     v-else>
                    0 / 0 (0%)
                </div>
            </div>
            <input @keydown="onNewAnswerStartTyping"
                   v-if="!selectedPoll.IsOpen"
                   placeholder="Add option...">


            <div class="button-group">
                <button @click="savePollChanges(selectedPoll)">
                    Save Changes
                </button>
                <button @click="openPoll(selectedPoll)"
                        v-if="!selectedPoll.IsOpen">
                    Open
                </button>
                <button @click="closePoll(selectedPoll)"
                        v-else>
                    Close
                </button>
            </div>
        </div>
    </div>
    <loading v-else></loading>
</template>
<script>
import { AppState } from './appstate';
import Common from './common'
import Token from './authentication/token'

export default {

    mixins: [
        Common,
        Token,
    ],
    
    props: [
        'roomCode'
    ],

    data() {
        return {
            polls: [],
            selectedPoll: null,
            selectedPollAnswers: [],
            selectedPollResponses: [],
        }
    },

    computed: {
        totalResponsesForSelectedPoll() {
            if(!this.selectedPoll) {
                return 0;
            }

            let total = 0;

            this.selectedPollResponses.forEach(v => total += v);

            return total;
        }
    },

    watch: {
        selectedPoll() {
            this.selectedPollAnswers = [];
            this.selectedPollResponses = [];

            if(!this.selectedPoll) {
                return;
            }

            this.selectedPoll.Options.forEach((answer, i) => {
                this.selectedPollAnswers.push({
                    index: i,
                    option: answer,
                    answers: 0
                });
            });

            this.loadPollResponses(this.selectedPoll);
        }
    },

    methods: {
        emitExitRequest(){
            this.$emit('closerequest');
        },
        onAnswerBlur() {

            let toDelete = [];

            this.selectedPollAnswers.forEach(item => {
                if(!item.option) {
                    toDelete.push(item);
                }
            });

            toDelete.forEach(item => {
                let index = this.selectedPollAnswers.indexOf(item);

                this.selectedPollAnswers.splice(index, 1);
            });

            this.selectedPollAnswers.forEach((item, i) => {
                item.index = i;
            });
        },

        onNewAnswerStartTyping(e) {
            if(e.isComposing || e.keyCode === 229) {
                return;
            }

            if(e.key == 'Shift' 
                || e.key == 'Control' 
                || e.key == 'Meta' 
                || e.key == 'Alt'
                || e.key == 'Backspace'
                || e.key == 'Delete'
                || e.key == 'Tab') {
                return;
            }

            let newIndex = this.selectedPollAnswers.length;

            this.selectedPollAnswers.push({
                index: newIndex,
                option: e.value,
                answers: 0,
            });

            let textRef = `option${newIndex}`;

            this.$nextTick(() => {
                let ref = this.$refs[textRef][0];
                ref.focus();
            });
        },

        addNewPoll() {
            let newPoll = {
                Title: "New Poll",
                Body: "",
                ShowCode: AppState.showCode,
                RoomCode: this.roomCode,
                Code: this.getAzureCompatibleTimeStamp(),
                Options: ['Option 1', 'Option 2'],
            }

            this.polls.push(newPoll);
            this.selectedPoll = newPoll;
        },

        async savePollChanges(poll) {

            poll.Options = this.selectedPollAnswers.map(x => x.option);
            this.submitting = true;
            try {
                
                let result = await this.tryPost(`/api/litepoll/save/${AppState.showCode}/${this.roomCode}/${poll.Code}`,
                    JSON.stringify(poll),
                    "application/json");


            } catch(ex) {

            }

            this.submitting = false;
        },

        async loadPollResponses(poll) {
            try {
                let responses = await this.tryGet(`/api/litepoll/answers/${AppState.showCode}/${this.roomCode}/${poll.Code}`);
                this.selectedPollResponses = responses.Result || [];
            } catch(ex) {

            }


        },

        async openPoll(poll) {
            this.submitting = true;
            try {
                
                let result = await this.tryPost(`/api/litepoll/launch/${AppState.showCode}/${this.roomCode}/${poll.Code}/open`,
                    JSON.stringify(poll),
                    "application/json");


            } catch(ex) {

            }

            this.submitting = false;

        },

        async closePoll(poll) {

            this.submitting = true;
            try {
                
                let result = await this.tryPost(`/api/litepoll/launch/${AppState.showCode}/${this.roomCode}/${poll.Code}/close`,
                    JSON.stringify(poll),
                    "application/json");


            } catch(ex) {

            }

            this.submitting = false;

        },

        async loadPolls() {
            this.submitting = true;
            try {
                let polls = await this.tryGet(`/api/litepoll/get/${AppState.showCode}/${this.roomCode}`);

                this.polls = polls.Result;

                if(this.selectedPoll) {
                    let refreshedPoll = this.polls.find(x => x.Code == this.selectedPoll.Code);

                    this.selectedPoll = refreshedPoll;
                }

            } catch(ex) {

            }

            this.submitting = false;
        },

        onPollUpdated(details) {
            this.loadPolls();
        },

        onPollStateChange(details) {
            this.loadPolls();
        },

        onPollAnswered(details) {
            if(!this.selectedPoll || this.selectedPoll.Code != details.Code) {
                return;
            }

            this.selectedPollResponses[details.Index] = this.selectedPollResponses[details.Index] + 1;
        }
    },

    created() {
        this.loadPolls();
        window.$bus.$on('lite-poll-updated', this.onPollUpdated);
        window.$bus.$on('lite-poll-state-change', this.onPollStateChange);
        window.$bus.$on('lite-poll-answered', this.onPollAnswered);
    },

    beforeUnmount() {
        window.$bus.$off('lite-poll-updated', this.onPollUpdated);
        window.$bus.$off('lite-poll-state-change', this.onPollStateChange);
        window.$bus.$off('lite-poll-answered', this.onPollAnswered);
    }
}
</script>