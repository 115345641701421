// Tractus.Data.PollQuestion
// Generated on 2019-11-12 12:53:01 PM by Elias

class PollQuestion {
	//public string Code
	Code = null;
	//public String Name
	Name = null;
	//public String Notes
	Notes = null;
	//public String QuestionType
	QuestionType = null;
	//public String LabelType
	LabelType = null;
	//public String TextType
	TextType = null;
	//public String QuestionMask
	QuestionMask = null;
	//public Int32 RankLimit
	RankLimit = 0;
	//public PollQuestionDisplayCondition[] Conditions
	Conditions = [];
	//public PollQuestionAnswer[] Answers
	Answers = [];
	//public String[] CorrectAnswer
	CorrectAnswer = [];
	//public String AddressedToName
	AddressedToName = null;
	//public String Response
	Response = null;
	//public String ResponseOverride
	ResponseOverride = null;
	//public Boolean IsMandatory
	IsMandatory = false;
	//public String MappedTo
	MappedTo = null;
}
export default PollQuestion
