<template>
    <div>
        <div class="detail-page-links">
            <div v-if="editor.newElementType"
                 class="new-element-drop-zone info-link info-link-item"
                 :class="{'target': editor.dropZoneIndex == 'left'}"
                 @dragover="onDragOver(null, $event, 'left')"
                 @drop="onDragDrop(null, $event)">
            </div>
            <contact-info-item v-for="(link, i) in contactInfoToDisplay"
                                :link="link"
                                :key="link._LocalCode"
                                :index="i"
                                :onlineAttendeeCodes="onlineAttendeeCodes"
                                :entityType="entityType"
                                :entityCode="entityCode"
                                :displayOnlineStatus="displayOnlineStatus"
                                :editMode="editMode"
                                :draggable="editMode"
                                :class="{'hidden-preview': link.IsHidden, 'dragging': link == editor.sourceElement, 'target': (link == editor.destinationElement || link == editor.selectedElement)}"
                                @openlink="onOpenLink" 
                                @dragend.native="onDragEnd"
                                @dragover.native="onDragOver(link, $event)"
                                @drop.native="onDragDrop(link, $event)"
                                @drag.native="onDrag(link, $event)"
                                @dragstart.native="onDragStart(link, $event)"
                                @click.native="setActiveEditingElement(link)"

            />

                <!-- BUG: If we drag, then leave the page, the last hovered item
                will still be "selected". DragLeave responds to all the subelements
                inside the item. Need a cleaner way to resolve this. -->
            <div v-if="editor.newElementType && contactInfoToDisplay.length > 0"
                class="new-element-drop-zone info-link info-link-item"
                :class="{'target': editor.dropZoneIndex == 'right'}"
                @dragover="onDragOver(null, $event, 'right')"
                @drop="onDragDrop(null, $event)">
            </div>
        </div>

        <div>
            <file-upload ref="uploader"
                        style="display: none;"
                        :assetType="`${this.entityType.toLowerCase()}_${this.entityCode.toLowerCase()}`"
                        @uploadSuccess="onFileUploaded"
                        @uploadFailed="emitFileUploaderEvent('failed')"
                        @uploadStarted="emitFileUploaderEvent('started')" />

        </div>
    </div>
</template>
<script>
import Common from "./common";
import Token from './authentication/token'
import { AppState } from './appstate';

export default {
    props: [
        "contactInfo", 
        'onlineAttendeeCodes', 
        "entityType", 
        "entityCode",
        "displayOnlineStatus",
        "editMode"
    ],
    
    mixins: [Common, Token],

    computed: {
        contactInfoToDisplay() {
            if(this.editMode) {
                return this.contactInfoList;
            }
            
            return this.contactInfoList.filter(x => x.IsHidden == false || x.IsHidden == undefined);
        }
    },

    watch: {
        contactInfo: {
            immediate: true,
            handler(e) {
                console.warn("contactInfo WATCH has fired", this.contactInfo, e);
                if(this.editMode) {
                    return;
                }

                this.contactInfoList = [...this.contactInfo];

                this.contactInfoList.forEach(x => {
                    if(!x.Size) {
                        x.Size = 'Full';
                    }

                    if(!x._LocalCode) {
                        x._LocalCode = this.uuidv4();
                    }
                });
            }
        }
    },

    data() {
        return {
            editor: {
                sourceElement: null,
                destinationElement: null,
                newElementType: null,
                scrollDirection: 0,
                scrollTimer: null,
                selectedElement: null,
                dropZoneIndex: null,
            },

            contactInfoList: [],
        }
    },

    created() {
        window.$bus.$on('contact-info-editor-closed', this.onContactInfoEditorClosed);
        window.$bus.$on('ContactInfoItemEdited', this.onContactInfoItemEdited);
        window.$bus.$on('upload-asset-contact-info', this.onUploadAssetContactInfo);
        window.$bus.$on('request-choose-file-contact-info', this.onRequestChooseFile);
        window.$bus.$on('delete-contact-info-element', this.onDeleteContactInfo);
    },

    beforeUnmount() {
        window.$bus.$off('ContactInfoItemEdited', this.onContactInfoItemEdited);
        window.$bus.$off('contact-info-editor-closed', this.onContactInfoEditorClosed);
        window.$bus.$off('upload-asset-contact-info', this.onUploadAssetContactInfo);
        window.$bus.$off('request-choose-file-contact-info', this.onRequestChooseFile);
        window.$bus.$off('delete-contact-info-element', this.onDeleteContactInfo);
    },

    methods: {
        onMouseMove(e) {
            console.log(`${e.clientY}, ${e.buttons}`);

        },
        onDeleteContactInfo(item) {
            let targetElement = this.contactInfoList.find(x => x._LocalCode == item._LocalCode);
            if(!targetElement) {
                return;
            }

            let index = this.contactInfoList.indexOf(targetElement);

            if(index == -1) {
                return;
            }

            this.contactInfoList.splice(index, 1);
            window.$bus.$emit('closeSidebar', 'contact-info');
        },

        onRequestChooseFile() {
            this.$refs.uploader.requestChooseFile();
        },
        
        async onUploadAssetContactInfo(link, file) {
            let targetElement = this.contactInfoList.find(x => x._LocalCode == link._LocalCode);

            if(!targetElement) {
                return;
            }

            let result = await this.$refs.uploader.uploadFile(file);

            if(result.status == 2) {
                window.$bus.$emit('asset-uploaded-contact-info', link, result.fileUrl);
            } else {
                this.$awn.alert("Could not upload asset. Please try again later.");
            }

        },

        onContactInfoEditorClosed() {
            this.editor.selectedElement = null;
        },

        onFileUploaded(file) {
            this.emitFileUploaderEvent('success');
            window.$bus.$emit('asset-uploaded-contact-info', this.editor.selectedElement, file);
        },

        emitFileUploaderEvent(eventName) {
            this.$emit('upload-event', eventName);
        },

        setActiveEditingElement(contactInfo) {
            if(!this.editMode) {
                return;
            }
            
            this.editor.selectedElement = contactInfo;
            window.$bus.$emit('set-sidebar-mode', 'contact-info', {
                EntityTypeCode: this.entityType.toUpperCase(),
                EntityCode: this.entityCode,
                ContactInfo: contactInfo
            });
        },

        onContactInfoItemEdited(entityTypeCode, entityCode, item) {
            console.log('onContactInfoItemEdited')
            let localItem = this.contactInfoList.find(x => x._LocalCode == item._LocalCode);

            if(!localItem) {
                return;
            }

            if(entityTypeCode != this.entityType.toUpperCase()
                || entityCode != this.entityCode) {
                return;
            }

            console.log(this.contactInfoList);

            Object.keys(item).forEach(key => {
                localItem[key] = item[key];
            });
        },

        async onPaste(blob) {
            if(!this.editMode) {
                return;
            }

            console.log(blob);

            const extension = 
                blob.type == 'image/png' 
                    ? '.png'
                    : '.jpg';

            let file = new File([blob], `paste_${new Date().getTime()}${extension}`);
            let result = await this.$refs.uploader.uploadFile(file);

            let targetElement = this.editor.selectedElement;

            let indexOfNewElement = 
                targetElement
                    ? (this.contactInfoList.indexOf(targetElement) + 1)
                    : this.contactInfoList.length;

            if(targetElement?.Type == "Image") {
                if(result.status == 2) {
                    targetElement.IconUrl = result.fileUrl;
                }
            } else {
                if(result.status == 2) {
                    // Success
                    let newElement = this.addItem('Image', indexOfNewElement , true);
                    newElement.IconUrl = result.fileUrl;
                    this.setActiveEditingElement(newElement);
                }

            }

        },

        async onDragEnd() {
            if(!this.editMode) {
                return;
            }

            this.onDropCleanup();
        },

        async onDragDrop(item, e) {
            if(!this.editMode) {
                return;
            }

            e.preventDefault();
            let sourceElement = this.editor.sourceElement;
            let targetElement = this.editor.destinationElement;
            let newElementType = this.editor.newElementType;
            let dropZoneIndex = this.editor.dropZoneIndex;
            
            if(!sourceElement) {

                //console.log(e.dataTransfer.files[0]);

                if(e.dataTransfer.files.length > 0) {
                    this.editor.selectedElement = null;
                    targetElement = item;

                    let indexOfTarget = this.contactInfoList.indexOf(targetElement);

                    for(let file of e.dataTransfer.files) {
                        console.log(file);
                        let result = await this.$refs.uploader.uploadFile(file);

                        if(file.type.startsWith('image/')) {

                            // TODO: Move enum somewhere global.
                            if(result.status == 2) {
                                // Success
                                let newElement = this.addItem('Image', indexOfTarget + 1, true);
                                newElement.Size = targetElement.Size;
                                newElement.IconUrl = result.fileUrl;

                                indexOfTarget = indexOfTarget + 1;
                            }
                        } else {
                            if(result.status == 2) {
                                // Success
                                let newElement = this.addItem('Downloadable', indexOfTarget + 1, true);
                                newElement.DisplayText = file.name;
                                newElement.Value = result.fileUrl;

                                indexOfTarget = indexOfTarget + 1;
                            }
                        }
                    }

                    this.editor.destinationElement = null;
                    this.onDropCleanup();

                    return;
                }

                if(!newElementType) {
                    this.onDropCleanup();
                    return;
                }

                if(!targetElement && !dropZoneIndex) {
                    this.onDropCleanup();
                    return;
                }

                let indexOfTarget = 
                    dropZoneIndex == 'right'
                        ? this.contactInfoList.length
                        : (dropZoneIndex == 'left'
                            ? 0
                            : this.contactInfoList.indexOf(targetElement) + 1);

                console.log(indexOfTarget);
                let newItem = this.addItem(newElementType, indexOfTarget);

                newItem.Size = targetElement
                    ? targetElement.Size
                    : 'Full';
                this.onDropCleanup();
                return;
            }

            let indexOfTarget = this.contactInfoList.indexOf(targetElement);
            let indexOfSource = this.contactInfoList.indexOf(sourceElement);

            if(indexOfTarget == indexOfSource) {
                this.onDropCleanup();
                return;
            }

            if(indexOfTarget < indexOfSource) {
                this.contactInfoList.splice(indexOfSource, 1);
                this.contactInfoList.splice(indexOfTarget, 0, sourceElement);
            }

            if(indexOfTarget > indexOfSource) {
                console.log(`Target: ${indexOfTarget}, Source: ${indexOfSource}`);

                this.contactInfoList.splice(indexOfSource, 1);
                this.contactInfoList.splice(indexOfTarget, 0, sourceElement);
            }

            this.onDropCleanup();
        },

        onDropCleanup() {
            this.editor.sourceElement = null;
            this.editor.destinationElement = null;
            this.editor.newElementType = null;

            this.editor.scrollDirection = 0;
            if(this.editor.scrollTimer) {
                clearInterval(this.editor.scrollTimer);
                this.editor.scrollTimer = null;
            }
        },

        onDragLeave(item) {
            if(!this.editMode) {
                return;
            }

            if(this.editor.destinationElement == item) {
                this.editor.destinationElement = null;
            }
        },

        onDragOver(item, e, dropZoneIndex) {
            if(!this.editMode) {
                return;
            }

            e.preventDefault();
            this.editor.destinationElement = item;
            this.editor.dropZoneIndex = dropZoneIndex;
        },

        onDragStart(item) {
            if(!this.editMode) {
                return;
            }

            this.editor.sourceElement = item;
        },

        onDrag(item, e) {
            if(!this.editMode) {
                return;
            }

            if(!this.editor.sourceElement && !this.editor.newElementType) {
                return;
            }

            this.$emit('drag-update', {
                mouseY: e.clientY
            });
        },

        updateScrollAmount(direction) {
            if(!this.editMode) {
                return;
            }

            this.editor.scrollDirection = direction;

            if(this.editor.scrollDirection != 0 && !this.editor.scrollTimer) {
                this.editor.scrollTimer = setInterval(this.onAutoScrollTimer, 20);
            }
        },

        onAutoScrollTimer() {
            if(!this.editMode) {
                if(this.editor.scrollTimer) {
                    clearInterval(this.editor.scrollTimer);
                    this.editor.scrollTimer = null;
                }

                return;
            }


            if(this.editor.scrollDirection == 0) {
                clearInterval(this.editor.scrollTimer);
                this.editor.scrollTimer = null;
                console.log('drag scroll request clear');
                return;
            }

            console.log('drag scroll request');
            this.$emit('drag-scroll-requested', this.editor.scrollDirection);
        },

        onDragStartNewElement(newElementType) {
            this.editor.newElementType = newElementType;
        },


        addItem(typeCode, index, doNotSetAsActive) {
            let newItem = {
                Type: typeCode,
                Value: typeCode == 'Website' ? 'https://' : '',
                DisplayText: `New ${typeCode}`,
                Details: '',
                _LocalCode: this.uuidv4(),
                Size: 'Full',
                IsHidden: false,
                IsLocked: false,
                Style: null,
                IconUrl: typeCode == 'Image' ? '/placeholder.png' : null
            };

            if(index == null || index == undefined) {
                this.contactInfoList.push(newItem);
            } else {
                this.contactInfoList.splice(index, 0, newItem);
            }

            if(!doNotSetAsActive) {
                this.setActiveEditingElement(newItem);
            }

            return newItem;
        },

        removeItem(item) {
            let index = this.contactInfoList.indexOf(item);

            this.contactInfoList.splice(index, 1);
        },

        onOpenLink(link) {
            if(this.editMode) {
                this.setActiveEditingElement(link);
                return;
            }

            if(!link.Value) {
                return;
            }

            let url = link.Value.replace("{{entityTypeCode}}", this.entityType)
                .replace("{{entityCode}}", this.entityCode);

            let iframeParams = {
                EntityTypeCode: this.entityType,
                EntityCode: this.entityCode,
                Title: link.DisplayText,
                Url: url
            };

            if(link.Type == "Downloadable"
                || (link.Type == 'Website' && link.Value && link.Value.startsWith('INTERNAL:::'))) {

                window.$bus.$emit('iframe-open-request', iframeParams);

            } else {
                window.$bus.$emit('link-open-request', iframeParams);
            }
        },
    },

};
</script>